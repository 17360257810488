import React from 'react';
import { Link } from '@amzn/awsui-components-react';
import Header from '@amzn/awsui-components-react/polaris/header';
import { InfoPanelDetailsHelpPanel } from '../admin-components/applications/admin-manage-application/InfoPanelDetails';

export const CONSTANTS = {
  HEADER_TITLE: 'DaS FinTech Team',
  HEADER_INFO_TITLE: 'DaS FinTech Team Info'
} as const;

interface TeamHeaderProps {
  onInfoClick: () => void;
}

interface TeamToolsProps {
  sidePanelDetails: any; // Replace 'any' with a more specific type if possible
}

export const TeamTools: React.FC<TeamToolsProps> = ({ sidePanelDetails }) => (
  <InfoPanelDetailsHelpPanel headerName={CONSTANTS.HEADER_INFO_TITLE} appDetailedDescription={''} sidePanelDetails={sidePanelDetails} />
);

export const TeamHeader: React.FC<TeamHeaderProps> = ({ onInfoClick }) => (
  <Header
    variant="h1"
    info={
      <Link variant="info" onFollow={onInfoClick}>
        Info
      </Link>
    }
  >
    {CONSTANTS.HEADER_TITLE}
  </Header>
);
