import TopNavigation, { TopNavigationProps } from '@amzn/awsui-components-react/polaris/top-navigation';
import { Mode, applyMode } from '@amzn/awsui-global-styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DFP_HEADER_LOGO from 'src/images/DFP_HEADER_LOGO.png';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContextProvider';
import { APP_TITLE, eLocalStorageKeys } from 'src/constants/AppConstants';
import { topNavI18nStrings } from 'src/i18n-strings/top-nav-bar-i18nStrings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import DFPModifyPermissions from './DFPModifyPermissions';
import { usePersistedState } from 'src/hooks/useLocalStorage';
// import { FaRegMoon, FaSun } from 'react-icons/fa';

export const DFPTopNavigation: React.FC = () => {
  const navigate = useNavigate();
  const userAuthenticationDetails = useAuth();
  const appContext = useAppContext();
  const [isDarkModeEnabled, setIsDarkModeEnabled] = usePersistedState(eLocalStorageKeys.IsDarkModeEnabled, false);
  const [showModal, setShowModal] = useState(false);

  const ADMIN_UTILITIES: TopNavigationProps.Utility[] = [
    {
      type: 'menu-dropdown',
      iconSvg: appContext.isAdminControlsEnabled ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />,
      description: 'Admin controls',
      onItemClick: (event) => onAdminControlsChanged(event.detail.id === 'show'),
      items: [
        {
          id: 'show',
          text: 'Show',
          iconSvg: <FontAwesomeIcon icon={faEye} />
        },
        {
          id: 'hide',
          text: 'Hide',
          iconSvg: <FontAwesomeIcon icon={faEyeSlash} />
        }
      ]
    }
  ];

  const NORMAL_USER_UTILITIES: TopNavigationProps.Utility[] = [
    {
      type: 'menu-dropdown',
      iconSvg: isDarkModeEnabled ? <FontAwesomeIcon icon={faMoon} /> : <FontAwesomeIcon icon={faSun} />,
      description: 'Mode',
      onItemClick: (event) => onChangeVisualMode(event.detail.id === 'dark'),
      items: [
        {
          id: 'dark',
          text: 'Dark',
          iconSvg: <FontAwesomeIcon icon={faMoon} />
        },
        {
          id: 'light',
          text: 'Light',
          iconSvg: <FontAwesomeIcon icon={faSun} />
        }
      ]
    },
    {
      type: userAuthenticationDetails.isDev ? 'menu-dropdown' : 'button',
      text: userAuthenticationDetails.DisplayName,
      description: userAuthenticationDetails.Email,
      iconName: 'user-profile',
      items: userAuthenticationDetails.isDev ? [{ id: 'custom-permissions', text: 'Customize Permissions' }] : [],
      onItemClick: () => {
        setShowModal(true);
      }
    }
  ];

  useEffect(() => {
    applyMode(isDarkModeEnabled ? Mode.Dark : Mode.Light);
  }, [isDarkModeEnabled]);

  const appTitleClicked = (e: any) => {
    e.preventDefault();
    navigate({ pathname: '/' });
  };

  const onChangeVisualMode = (useDarkMode: boolean) => {
    setIsDarkModeEnabled(useDarkMode);
  };

  const onAdminControlsChanged = (showAdminControls: boolean) => {
    appContext.setIsAdminControlsEnabled(showAdminControls);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const onConfirm = () => {
    setShowModal(false);
    window.location.reload();
  };
  return (
    <>
      <DFPModifyPermissions showModal={showModal} onCancel={onCancel} onConfirm={onConfirm} />
      <TopNavigation
        className="app-primary-top-navigation"
        i18nStrings={topNavI18nStrings}
        identity={{
          href: '#',
          title: APP_TITLE,
          onFollow: appTitleClicked,
          logo: {
            src: DFP_HEADER_LOGO,
            alt: 'DaS FinSuite Logo'
          }
        }}
        utilities={userAuthenticationDetails.isAdmin ? ADMIN_UTILITIES.concat(NORMAL_USER_UTILITIES) : NORMAL_USER_UTILITIES}
      />
    </>
  );
};
