import * as Yup from 'yup';
import { welcomeContentDetailsSchemaForApplications } from '../applications/admin-manage-application/StepsValidations';

export const MAX_CHARACTER_COUNT = {
  DETAILED_DESCRIPTION: 600
};

// Validation messages
export const validationMessages = {
  required: 'This field is required',
  url: 'Please enter a valid URL',
  maxLength: (max: number) => `Must be ${max} characters or less`,
  minLength: (min: number) => `Must be at least ${min} characters`,
  boolean: 'Must be a boolean value',
  array: 'Must be an array',
  number: 'Must be a number'
};

// Tutorial Content Details Schema
export const TutorialContentDetailsSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  position: Yup.number().nullable(),
  category: Yup.string().nullable(),
  displayTitle: Yup.string().nullable().required(validationMessages.required).max(100, validationMessages.maxLength(100)),
  link: Yup.string().nullable().required(validationMessages.required).url(validationMessages.url)
});

export const RichTextContentWidgetSchema = Yup.object().shape({
  isEnabled: Yup.boolean().required(validationMessages.required),
  title: Yup.string().required(validationMessages.required).max(100, validationMessages.maxLength(100)),
  isExpandedByDefault: Yup.boolean().required(validationMessages.required),
  contentType: Yup.string().oneOf(['rich-text-editor']).required(validationMessages.required),
  richTextContent: Yup.string().when('isEnabled', {
    is: true,
    then: Yup.string()
      .required(validationMessages.required)
      .test('not-empty-content', 'Content cannot be empty', (value) => {
        return value !== undefined && value.trim() !== '<p><br></p>';
      }),
    otherwise: Yup.string().nullable()
  })
});

// Table Content Widget Schema
export const TableContentWidgetSchema = Yup.object().shape({
  id: Yup.string().required(validationMessages.required),
  position: Yup.number().nullable(),
  displayName: Yup.string().required(validationMessages.required),
  link: Yup.string().required(validationMessages.required).url(validationMessages.url)
});

// Custom Side Panel Widgets Schema
export const CustomSidePanelWidgetsSchema = Yup.object().shape({
  id: Yup.string().required(validationMessages.required),
  isEnabled: Yup.boolean().required(validationMessages.required),
  title: Yup.string().required(validationMessages.required).max(100, validationMessages.maxLength(100)),
  isExpandedByDefault: Yup.boolean().required(validationMessages.required),
  contentType: Yup.string().oneOf(['rich-text-editor', 'table']).required(validationMessages.required),
  richTextContent: Yup.string().nullable(),
  tableContent: Yup.array()
    .of(TableContentWidgetSchema)
    .nullable()
    .when(['isEnabled', 'contentType'], {
      is: (isEnabled: boolean, contentType: string) => isEnabled && contentType === 'table',
      then: Yup.array().of(TableContentWidgetSchema).min(1, 'At least one item is required'),
      otherwise: Yup.array().of(TableContentWidgetSchema).nullable()
    })
});

// Home Page Details And Widgets Schema
export const HomePageDetailsAndWidgetsSchema = Yup.object().shape({
  applicationIntroductionVideoUrl: Yup.string().nullable().url(validationMessages.url),
  fullApplicationDescription: Yup.string()
    .required(validationMessages.required)
    .max(MAX_CHARACTER_COUNT.DETAILED_DESCRIPTION, validationMessages.maxLength(MAX_CHARACTER_COUNT.DETAILED_DESCRIPTION)),
  hasWelcomeContent: Yup.boolean().required(validationMessages.required),
  welcomeTitle: Yup.string().when('hasWelcomeContent', {
    is: true,
    then: Yup.string().required(validationMessages.required).max(100, validationMessages.maxLength(100))
  }),
  welcomeContentDetails: Yup.array(welcomeContentDetailsSchemaForApplications),
  hasAnnouncements: Yup.boolean().required(validationMessages.required),
  hasTutorials: Yup.boolean().required(validationMessages.required),
  tutorialContentDetails: Yup.array().of(TutorialContentDetailsSchema),
  hasFeedback: Yup.boolean().required(validationMessages.required)
});

// Side Panel Details Schema
export const SidePanelDetailsSchema = Yup.object().shape({
  keyContacts: RichTextContentWidgetSchema,
  officeHours: RichTextContentWidgetSchema,
  supportLinks: CustomSidePanelWidgetsSchema.nullable(),
  customSidePanelWidgets: Yup.array().of(CustomSidePanelWidgetsSchema)
});

export const infoPanelPageConfigurationSchema = Yup.object().shape({
  sidePanelDetails: SidePanelDetailsSchema
});

// Main HomePageWizard Schema
export const HomePageWizardSchema = Yup.object().shape({
  id: Yup.string().required(validationMessages.required),
  homePageDetailsAndWidgets: HomePageDetailsAndWidgetsSchema,
  infoPanelPageConfiguration: infoPanelPageConfigurationSchema
});
