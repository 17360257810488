import { AppLayout, Flashbar, FlashbarProps, Wizard } from '@amzn/awsui-components-react';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { LoadingStatus, SidePanelDetails } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import DisplayContentInCode from 'src/components/generic-components/DisplayContentInCode';
import { ErrorFallback } from 'src/components/generic-components/ErrorFallback';
import { appLayoutAriaLabels, wizardI18nStrings } from 'src/i18n-strings';
import { AdminSideNavigation } from '../AdminSideNavigation';
import { useTeamPageWizard } from './hooks/useTeamPageWizard';
import { TeamPageWizard } from './TeamManagementModel';
import {
  getTeamPageManagementBreadcrumbItems,
  getTeamWizardContentDefaultState,
  teamPageContentAPIToWizard,
  TeamPageWizardSteps
} from './TeamManagementUtils';
import { TeamPageWizardSchema } from './TeamPageWizardSchema';
import { ConfirmationModal } from 'src/components/generic-components/ConfirmationModal';
import { InfoPanelDetailsHelpPanel } from '../applications/admin-manage-application/InfoPanelDetails';

export const TeamPageManagement: React.FC = () => {
  const { Alias } = useAuth();
  const { contextLoadingStatus, teamPageContent } = useAppContext();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [infoPanelPageConfiguration, setInfoPanelPageConfiguration] = useState<SidePanelDetails>();

  const {
    teamPageFormRef,
    activeStepIndex,
    setActiveStepIndexAndCloseTools,
    stepError,
    showModalInfo,
    onNavigate,
    onWizardCancel,
    onConfirm,
    onCancel,
    onSubmit
  } = useTeamPageWizard();

  const [homepageWizardInitialValues, setHomepageWizardInitialValues] = useState<TeamPageWizard>(getTeamWizardContentDefaultState(Alias));

  useEffect(() => {
    if (contextLoadingStatus !== LoadingStatus.Completed) {
      return;
    }

    const initialValues = teamPageContentAPIToWizard(teamPageContent, Alias);
    setHomepageWizardInitialValues(initialValues);
  }, [contextLoadingStatus, teamPageContent]);

  const renderContent = (): JSX.Element => (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <div className="app-layout-body">
        <Formik<TeamPageWizard>
          innerRef={teamPageFormRef}
          enableReinitialize
          initialValues={homepageWizardInitialValues}
          validationSchema={TeamPageWizardSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={onSubmit}
        >
          {(formik) => {
            useEffect(() => {
              setInfoPanelPageConfiguration(formik.values.infoPanelPageConfiguration.sidePanelDetails);
            }, [formik]);

            return (
              <>
                <form onSubmit={formik.handleSubmit}>
                  <Wizard
                    steps={TeamPageWizardSteps.map(({ title, description, StepContent, isOptional }) => ({
                      title,
                      description,
                      isOptional,
                      content: (
                        <StepContent formik={formik} setActiveStepIndex={setActiveStepIndexAndCloseTools} applicationFormRef={teamPageFormRef} />
                      ),
                      errorText: stepError || undefined
                    }))}
                    activeStepIndex={activeStepIndex}
                    i18nStrings={wizardI18nStrings('Update')}
                    onNavigate={(event) => onNavigate(event, activeStepIndex)}
                    onCancel={onWizardCancel}
                    onSubmit={onSubmit}
                  />
                </form>
                <DisplayContentInCode details={formik}></DisplayContentInCode>
              </>
            );
          }}
        </Formik>
      </div>
    </ErrorBoundary>
  );

  return (
    <>
      <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />
      <AppLayout
        headerSelector="#h"
        ariaLabels={appLayoutAriaLabels}
        tools={
          <InfoPanelDetailsHelpPanel
            displayingInForm={true}
            headerName="Preview"
            sidePanelDetails={infoPanelPageConfiguration}
            appDetailedDescription={''}
            applicationIntroductionVideoUrl={''}
          />
        }
        toolsOpen={activeStepIndex === 2}
        navigation={<AdminSideNavigation />}
        stickyNotifications
        breadcrumbs={<DFPBreadcrumbs items={getTeamPageManagementBreadcrumbItems()} />}
        notifications={<Flashbar items={flashbarItems} />}
        contentType="default"
        content={renderContent()}
      />
    </>
  );
};
