import * as XLSX from 'xlsx';
import { logger } from 'src/logger';
import { getCurrentUserLocalTimeFormatted } from './DateTimeUtilities';

const EXCEL_EXTENSION = '.xlsx';

//Function to export the excel file
export const exportAsExcelFile = (json: any[], excelFileName: string, worksheetName: string) => {
  const downloadingFileName = excelFileName + '_' + getCurrentUserLocalTimeFormatted() + EXCEL_EXTENSION;
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
  XLSX.writeFileXLSX(workbook, downloadingFileName);
};

//Function to retrieve header from excel file
export const getHeaderRowFromExcel = (file: File): Promise<any[]> => {
  try {
    const _promise: Promise<any[]> = new Promise((resolve) => {
      const headers: string[] = [];
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const workSheetName: string = wb.SheetNames[0];
        const sheet: XLSX.WorkSheet = wb.Sheets[workSheetName];

        if (sheet['!ref']) {
          const range = XLSX.utils.decode_range(sheet['!ref']);
          let C = range.s.r;
          const R = range.s.r;

          /* walk every column in the range */
          for (C = range.s.c; C <= range.e.c; ++C) {
            const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];

            /* find the cell in the first row */
            let hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
            if (cell && cell.t) {
              hdr = XLSX.utils.format_cell(cell);
            }

            headers.push(hdr);
          }
          resolve(headers);
        } else {
          resolve([]);
        }
      };
      reader.readAsBinaryString(file);
    });

    return _promise;
  } catch (error: any) {
    logger.error(`Unable to read the header of the file uploaded. Error: ${error?.message}`, error);
    throw new Error();
  }
};

//Function to read the excel data
export const readExcelFile = (file: File): Promise<any[]> => {
  let jsonData: any[] = [];

  const _promise: Promise<any[]> = new Promise((res) => {
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const workSheetName: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[workSheetName];

        /* save data */
        jsonData = XLSX.utils.sheet_to_json(ws, { raw: false, header: 0, defval: '' });
        res(jsonData);
      };
      reader.readAsBinaryString(file);
    } catch (error: any) {
      logger.error(`Unable to read the data of the file uploaded. Error: ${error?.message}`, error);
      res([]);
    }
  });
  return _promise;
};
