import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { RiskRemediatorMain } from 'src/components/fintech-ops/risk-remediator/RiskRemediator';
import { useAuth } from './context/AuthContextProvider';
import { AdminHomePage } from './das-finsuite/admin-components/AdminHomePage';
import { AdminAnnouncements } from './das-finsuite/admin-components/admin-announcements/AdminAnnouncements';
import ManageAnnouncements from './das-finsuite/admin-components/admin-announcements/AdminManageAnnouncements';
import { Applications } from './das-finsuite/admin-components/applications/Applications';
import { AdminNewApp } from './das-finsuite/admin-components/applications/admin-manage-application/AdminNewApp';
import { Feedbacks } from './das-finsuite/admin-components/feedback/Feedbacks';
import AllApps from './das-finsuite/all-apps/AllApps';
import Announcements from './das-finsuite/announcements/Announcements';
import { AppSpecificHomePage } from './das-finsuite/app-specific/AppSpecificHomePage';
import Tutorials from './das-finsuite/tutorials/Tutorials';
import { DFPGlobalTopNavigation } from './dfp-home-page/DFPGlobalTopNavigationTopNavigation';
import { DFPHomePage } from './dfp-home-page/DFPHomePage';
import { CostMetricsMainPage } from './fintech-ops/cost-metrics/CostMetricsMainPage';
import { DogmaMain } from './fintech-ops/dogma/DogmaPage';
import { FinTechOpsPage } from './fintech-ops/fintech-ops-dashboard/FinTechOpsDashboard';
import { MappingMainPage } from './fintech-ops/ops-mappings/MappingMainPage';
import { PolicyEnginePage } from './fintech-ops/policy-engine/PolicyEnginePage';
import { SasPageMain } from './fintech-ops/sas/SasPage';
import { TicketsPageMain } from './fintech-ops/tickets/TicketsPage';
import { UserMetricsPage } from './fintech-ops/user-metrics/UserMetricsPage';
import { HomepageManagement } from './das-finsuite/admin-components/homepage-manage/HomePageManagement';
import { TeamPageManagement } from './das-finsuite/admin-components/teams-manage/TeamPageManagement';
import { DFPTeamsHomePageV2 } from './das-finsuite/teams/DFPTeamsHomePageV2';

export const DasFinSuiteAdminWrapper = () => {
  const userAuthenticationDetails = useAuth();
  if (userAuthenticationDetails.isAdmin) {
    return <Outlet />;
  }
  return <Navigate to="/" replace />;
};

export const FinTechOpsOEReadOnlyWrapper = () => {
  const userAuthenticationDetails = useAuth();
  if (!userAuthenticationDetails.isOEReadOnly) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

export const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <div id="h">
        {/* <DFPTopNavigation /> */}
        <DFPGlobalTopNavigation />
      </div>

      <Routes>
        {/* LandingPage is accessible to all types of users */}
        <Route path="/" element={<DFPHomePage />} />

        {/* Admin is accessible to admins only */}
        <Route path="admin" element={<DasFinSuiteAdminWrapper />}>
          <Route index element={<AdminHomePage />} />
          <Route path="/admin/applications" element={<Applications />} />
          <Route path="/admin/manage-applications" element={<AdminNewApp />} />
          <Route path="/admin/manage-applications/:id" element={<AdminNewApp />} />
          <Route path="/admin/announcements" element={<AdminAnnouncements />}>
            <Route path="/admin/announcements/:id" element={<ManageAnnouncements />} />
          </Route>
          <Route path="/admin/feedbacks" element={<Feedbacks />}></Route>
          <Route path="/admin/manage-homepage" element={<HomepageManagement />}></Route>
          <Route path="/admin/manage-team-page" element={<TeamPageManagement />}></Route>
        </Route>

        {/* DaS FinSuite Pages */}
        <Route path="/all-apps" element={<AllApps />} />
        <Route path="/all-apps/:id" element={<AppSpecificHomePage />} />
        <Route path="/teams" element={<DFPTeamsHomePageV2 />} />
        <Route path="/announcements" element={<Announcements />} />
        <Route path="/tutorials" element={<Tutorials />} />

        {/* FinTech Ops - accessible to OE read only */}
        <Route path="fintech-ops" element={<FinTechOpsOEReadOnlyWrapper />}>
          <Route index element={<FinTechOpsPage />} />
          <Route path="tickets" element={<TicketsPageMain />} />
          <Route path="user-metrics" element={<UserMetricsPage />} />
          <Route path="sas" element={<SasPageMain />} />
          <Route path="policyEngine" element={<PolicyEnginePage />} />
          <Route path="dogma" element={<DogmaMain />} />
          <Route path="risk-remediator" element={<RiskRemediatorMain />} />
          <Route path="ops-mapping" element={<MappingMainPage />} />
          <Route path="cost-metrics" element={<CostMetricsMainPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
