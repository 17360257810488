import { GetParameterCommand, GetParameterCommandOutput, SSMClient } from '@aws-sdk/client-ssm';
import { ENVIRONMENT_VARIABLES } from 'src/constants/EnvironmentConstants';
import { logger } from 'src/logger';
import { CognitoService } from 'src/services/aws/CognitoService';

export class SSMService {
  private static async getSSMClient() {
    const REGION = ENVIRONMENT_VARIABLES.env.Region;
    const ssmClientConfig = { region: REGION, credentials: await CognitoService.getAWSServiceCredentials() };
    return new SSMClient(ssmClientConfig);
  }

  public static async retrieveParameter(parameterName: string): Promise<string> {
    const ssmClient = await this.getSSMClient();
    const command = new GetParameterCommand({ Name: parameterName, WithDecryption: true });
    const response: GetParameterCommandOutput = await ssmClient.send(command);
    if (!response.Parameter?.Value) logger.error(`Unable to load parameter from AWS Systems Manager Parameter Store with key ${parameterName}`);
    return response.Parameter?.Value || '';
  }
}
