import { Box, Button, FlashbarProps, FormField, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import React, { useState } from 'react';
import { Feedback, FeedbackType } from 'src/components/das-finsuite/admin-components/feedback/FeedbacksModel';
import { useAuth } from 'src/components/context/AuthContextProvider';
import * as mutations from 'src/graphql/mutations';
import { logger } from 'src/logger';
import { getCurrentUTCTimeZoneInISO } from 'src/utilities/DateTimeUtilities';
import { v4 as uuidv4 } from 'uuid';
import { WidgetConfig } from '../interface';
import { eFinSuiteFeedbackMessages } from 'src/constants/AppConstants';

export const FeedbackWidget = (
  title: string,
  description: string,
  id: string,
  appName: string,
  feedbackType: FeedbackType,
  displayFlashMessage: (header: string, content: string, flashBarType: FlashbarProps.Type) => void
): WidgetConfig => {
  return {
    definition: { minRowSpan: 3, minColumnSpan: 1, defaultRowSpan: 4, defaultColumnSpan: 1 },
    data: {
      icon: 'list',
      title: title,
      description: description,
      header: <FeedbackHeader title={title} description={description} />,
      content: <FeedbackContainer id={id} appName={appName} feedbackType={feedbackType} displayFlashMessage={displayFlashMessage} />
    }
  };
};

interface FeedbackHeaderProps {
  title: string;
  description: string;
}
export const FeedbackHeader: React.FC<FeedbackHeaderProps> = ({ title, description }) => {
  return <Box variant="h2">{title}</Box>;
};

interface FeedbackContainerProps {
  id: string;
  appName: string;
  feedbackType: FeedbackType;
  displayFlashMessage: (header: string, content: string, flashBarType: FlashbarProps.Type) => void;
}
export const FeedbackContainer: React.FC<FeedbackContainerProps> = ({ id, appName, feedbackType, displayFlashMessage }) => {
  const userAuthDetails = useAuth();

  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = React.useState('');

  const handleRatingClick = (selectedRating: number) => {
    setRating(selectedRating);
  };

  const submitFeedback = () => {
    const feedbackInfo: Feedback = {
      id: uuidv4(),
      applicationName: {
        id: id,
        label: appName || '',
        labelType: 'ApplicationName'
      },
      feedback: feedback,
      feedbackType: feedbackType,
      rating: rating,
      itemMetadata: {
        isActive: true,
        createdBy: userAuthDetails.Alias,
        createdTime: getCurrentUTCTimeZoneInISO(),
        updatedBy: userAuthDetails.Alias,
        updatedTime: getCurrentUTCTimeZoneInISO()
      }
    };
    createFeedback(feedbackInfo)
      .then((response) => {
        logger.info(`Successfully submitted feedback`, { info: response });
        displayFlashMessage('', eFinSuiteFeedbackMessages.FEEDBACK_SUCCESSFULLY_ADDED, 'success');
        clearFeedback();
      })
      .catch((error: any) => {
        logger.error(`Unable to submit feedback.`, error);
        displayFlashMessage('', eFinSuiteFeedbackMessages.FEEDBACK_CREATION_FAILED, 'error');
      });
  };

  const clearFeedback = () => {
    setRating(0);
    setFeedback('');
  };

  const createFeedback = async (feedback: Feedback) => {
    const createResponse: any = await API.graphql({
      query: mutations.createFeedback,
      variables: { input: feedback }
    });
    return createResponse?.data?.createFeedback;
  };

  return (
    <SpaceBetween size="xxs" direction="vertical">
      <Box>
        <h5>{`How satisfied are you with your experience?`}</h5>
        <div className="star-rating">
          {Array.from({ length: 5 }, (_, index) => (
            <div className="star-wrapper-small" key={index}>
              <FontAwesomeIcon icon={index < rating ? faStar : faStarRegular} onClick={() => handleRatingClick(index + 1)} key={index} />
            </div>
          ))}
        </div>
      </Box>

      <FormField
        label={
          <h5>
            Elaborate on your rating or simply send a comment<i> - optional</i>
          </h5>
        }
      >
        <Textarea className="feedback-widget-textarea" onChange={({ detail }) => setFeedback(detail.value)} value={feedback} />
      </FormField>

      {/* <Button variant="normal">Submit</Button> */}
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="primary" disabled={rating === 0} onClick={submitFeedback}>
            Submit
          </Button>
        </SpaceBetween>
      </Box>
    </SpaceBetween>
  );
};
