import { AppLayout, AppLayoutProps, Flashbar, FlashbarProps, Wizard } from '@amzn/awsui-components-react';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { LoadingStatus, SidePanelDetails } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { ConfirmationModal } from 'src/components/generic-components/ConfirmationModal';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import DisplayContentInCode from 'src/components/generic-components/DisplayContentInCode';
import { appLayoutAriaLabels, wizardI18nStrings } from 'src/i18n-strings';
import { AdminSideNavigation } from '../AdminSideNavigation';
import { InfoPanelDetailsHelpPanel } from '../applications/admin-manage-application/InfoPanelDetails';
import {
  getHomePageManagementBreadcrumbItems,
  homepageContentAPIToWizard,
  HomepageWizardSteps,
  getTeamPageContentDefaultState
} from './HomePageManagementUtils';
import { HomePageWizardSchema } from './HomePageManagementSchema';
import { HomePageWizard } from './HomepageModel';
import { useHomePageWizard } from './hooks/useHomePageWizard';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'src/components/generic-components/ErrorFallback';
import { useAuth } from 'src/components/context/AuthContextProvider';

export const HomepageManagement: React.FC = () => {
  const { Alias } = useAuth();
  const { contextLoadingStatus, homePageContent } = useAppContext();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const {
    homePageFormRef,
    activeStepIndex,
    setActiveStepIndexAndCloseTools,
    stepError,
    showModalInfo,
    onNavigate,
    onWizardCancel,
    onConfirm,
    onCancel,
    onSubmit
  } = useHomePageWizard();

  const [homepageWizardInitialValues, setHomepageWizardInitialValues] = useState<HomePageWizard>(getTeamPageContentDefaultState(Alias));
  const [introductionVideoUrl, setIntroductionVideoUrl] = useState<string | null>(null);
  const [fullDescription, setFullDescription] = useState<string | null>(null);
  const [infoPanelPageConfiguration, setInfoPanelPageConfiguration] = useState<SidePanelDetails>();

  useEffect(() => {
    if (contextLoadingStatus !== LoadingStatus.Completed) {
      return;
    }

    const initialValues = homepageContentAPIToWizard(homePageContent);
    setHomepageWizardInitialValues(initialValues);
  }, [contextLoadingStatus, homePageContent]);

  const renderContent = (): JSX.Element => (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <div className="app-layout-body">
        <Formik<HomePageWizard>
          innerRef={homePageFormRef}
          enableReinitialize
          initialValues={homepageWizardInitialValues}
          validationSchema={HomePageWizardSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            useEffect(() => {
              setIntroductionVideoUrl(formik.values.homePageDetailsAndWidgets.applicationIntroductionVideoUrl);
              setFullDescription(formik.values.homePageDetailsAndWidgets.fullApplicationDescription);
              setInfoPanelPageConfiguration(formik.values.infoPanelPageConfiguration.sidePanelDetails);
            }, [formik]);

            return (
              <>
                <form onSubmit={formik.handleSubmit}>
                  <Wizard
                    steps={HomepageWizardSteps.map(({ title, description, StepContent, isOptional }) => ({
                      title,
                      description,
                      isOptional,
                      content: (
                        <StepContent formik={formik} setActiveStepIndex={setActiveStepIndexAndCloseTools} applicationFormRef={homePageFormRef} />
                      ),
                      errorText: stepError || undefined
                    }))}
                    activeStepIndex={activeStepIndex}
                    i18nStrings={wizardI18nStrings('Update')}
                    onNavigate={(event) => onNavigate(event, activeStepIndex)}
                    onCancel={onWizardCancel}
                    onSubmit={onSubmit}
                  />
                </form>
                <DisplayContentInCode details={{ errors: formik.errors, values: formik.values }} />
              </>
            );
          }}
        </Formik>
      </div>
    </ErrorBoundary>
  );

  const homepageAppLayoutProps: AppLayoutProps = {
    headerSelector: '#h',
    ariaLabels: appLayoutAriaLabels,
    navigation: <AdminSideNavigation />,
    stickyNotifications: true,
    breadcrumbs: <DFPBreadcrumbs items={getHomePageManagementBreadcrumbItems()} />,
    notifications: <Flashbar items={flashbarItems} />,
    contentType: 'default',
    content: renderContent()
  };

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />
        <AppLayout
          {...homepageAppLayoutProps}
          tools={
            <InfoPanelDetailsHelpPanel
              displayingInForm={true}
              headerName="Preview"
              sidePanelDetails={infoPanelPageConfiguration}
              appDetailedDescription={fullDescription || ''}
              applicationIntroductionVideoUrl={introductionVideoUrl}
            />
          }
          toolsOpen={activeStepIndex === 1}
        />
      </ErrorBoundary>
    </>
  );
};
