import { Button, Header, SpaceBetween, StatusIndicator, Table } from '@amzn/awsui-components-react';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import React, { useEffect } from 'react';
import { TableContentWidget } from 'src/components/context/AppContextModels';
import { ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import EmptyData from 'src/components/generic-components/EmptyData';
import { ApplicationWizard, InfoPanelPageConfigurations } from '../../AdminModels';
import { HomePageWizard } from '../../homepage-manage/HomepageModel';
import { APP_REPORT_ISSUES_ADDITIONAL_RESOURCES_DEFINITION, removeQuotesFromString } from './StepsUtilities';

type WizardType = ApplicationWizard | HomePageWizard;

type ConfigProperty = 'infoPanelPageConfiguration';

interface SupportLinksContentTableProps<T extends WizardType> {
  formik: FormikProps<T>;
  editReportIssue: () => void;
  selectedReportIssues: TableContentWidget[];
  setShowModalInfo: React.Dispatch<React.SetStateAction<ShowModalInfo>>;
  reportIssues: TableContentWidget[];
  setSelectedReportIssues: React.Dispatch<React.SetStateAction<TableContentWidget[]>>;
}

export const SupportLinksContentTable = <T extends WizardType>({
  formik,
  editReportIssue,
  selectedReportIssues,
  setShowModalInfo,
  reportIssues,
  setSelectedReportIssues
}: SupportLinksContentTableProps<T>): JSX.Element => {
  const configProperty: ConfigProperty = 'infoPanelPageConfiguration';
  const supportLinksEnabled = !!formik.values[configProperty]?.sidePanelDetails?.supportLinks;

  const touchedTableContent = (formik.touched[configProperty] as FormikTouched<InfoPanelPageConfigurations>)?.sidePanelDetails?.supportLinks
    ?.tableContent;

  const errorTableContent = (formik.errors[configProperty] as FormikErrors<InfoPanelPageConfigurations>)?.sidePanelDetails?.supportLinks
    ?.tableContent;

  return (
    <Table
      variant="embedded"
      header={
        <Header
          description={
            touchedTableContent && errorTableContent && <StatusIndicator type="error">{removeQuotesFromString(errorTableContent)}</StatusIndicator>
          }
          actions={
            <SpaceBetween size="m" direction="horizontal">
              <Button formAction="none" onClick={editReportIssue} disabled={selectedReportIssues.length !== 1}>
                Edit
              </Button>
              <Button
                formAction="none"
                disabled={selectedReportIssues.length === 0 || !supportLinksEnabled}
                onClick={() => {
                  setShowModalInfo({
                    showModal: true,
                    eventHeader: 'Delete report issue',
                    eventContentType: 'DELETE_REPORT_ISSUES'
                  });
                }}
              >
                {`Delete (${selectedReportIssues.length}) selected`}
              </Button>
            </SpaceBetween>
          }
        />
      }
      empty={<EmptyData />}
      columnDefinitions={APP_REPORT_ISSUES_ADDITIONAL_RESOURCES_DEFINITION}
      items={reportIssues}
      selectionType="multi"
      selectedItems={selectedReportIssues}
      onSelectionChange={({ detail }) => setSelectedReportIssues(detail.selectedItems)}
    />
  );
};
