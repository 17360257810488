import { Box, Link } from '@amzn/awsui-components-react';
import React from 'react';
import { ContactList } from 'src/components/das-finsuite/widgets/welcome-widget/ContactList';
import { sanitizeHtml } from 'src/utilities/SanitizeHtml';
import { WelcomeContentDetailsEntity } from '../../admin-components/AdminModels';

interface WelcomeWidgetContentProps {
  welcomeContentDetails: WelcomeContentDetailsEntity[];
}

export const WelcomeWidgetContent: React.FC<WelcomeWidgetContentProps> = ({ welcomeContentDetails }) => {
  if (welcomeContentDetails.length === 0) {
    return <Box textAlign="center">No content</Box>;
  }

  return (
    <div>
      {welcomeContentDetails.map((welcomeItem, index) => (
        <WelcomeContentItem key={index} welcomeItem={welcomeItem} isLast={index === welcomeContentDetails.length - 1} />
      ))}
    </div>
  );
};

const WelcomeContentItem: React.FC<{
  welcomeItem: WelcomeContentDetailsEntity;
  isLast: boolean;
}> = ({ welcomeItem, isLast }) => {
  return (
    <div className="welcome-content-item">
      {/* Row 1: Header and Contact List */}
      <div className="welcome-content-header">
        <div className="welcome-content-title">
          {welcomeItem.link ? (
            <Link variant="secondary" external href={welcomeItem.link}>
              {welcomeItem.displayText}
            </Link>
          ) : (
            <Box variant="awsui-key-label" color="text-status-info" fontSize="body-m" fontWeight="normal">
              {welcomeItem.displayText}
            </Box>
          )}
        </div>
        <div className="welcome-content-contact">{welcomeItem.pointOfContact && <ContactList userAlias={welcomeItem.pointOfContact} />}</div>
      </div>

      {/* Row 2: Description */}
      <Box fontSize="body-s" fontWeight="normal" padding={{ top: 'xxxs', bottom: 'xs' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(welcomeItem.description || '')
          }}
        />
      </Box>

      {/* Horizontal bar */}
      {!isLast && <div className="horizontal-bar"></div>}
    </div>
  );
};
