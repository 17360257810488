import { Box, ColumnLayout, Container, FormField, Header, Select, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import Input from '@amzn/awsui-components-react/polaris/input';
import { FormikProps } from 'formik';
import React from 'react';
import { useAppContext } from 'src/components/context/AppContextProvider';
import {
  labelInputToLabelDropdownOptions,
  labelInputToSelectDropdown,
  selectDropdownToLabel
} from 'src/components/generic-components/LabelDropdownUtility';
import { characterCountConstraintMessage } from 'src/utilities/CommonUtilities';
import { ApplicationWizard } from '../../AdminModels';
import { APP_NAME_MAX_LENGTH, FULL_DESCRIPTION_MAX_LENGTH, SHORT_DESCRIPTION_MAX_LENGTH } from './StepsValidations';

interface ApplicationDetailsProps {
  formik: FormikProps<ApplicationWizard>;
}

const ApplicationDetails: React.FC<ApplicationDetailsProps> = ({ formik }) => {
  const appContext = useAppContext();

  return (
    <>
      <Box margin={{ bottom: 'l' }}>
        <Container header={<Header variant="h2">Application overview</Header>}>
          <SpaceBetween size="m" direction="vertical">
            <ColumnLayout columns={2}>
              <FormField
                label="Application name"
                description="Enter the unique application name"
                errorText={formik.touched.applicationDetails?.applicationName && formik.errors.applicationDetails?.applicationName}
                constraintText={characterCountConstraintMessage(APP_NAME_MAX_LENGTH, formik.values.applicationDetails?.applicationName?.length || 0)}
                stretch={true}
              >
                <Input
                  placeholder="Enter application name"
                  onChange={({ detail }) => {
                    formik.setFieldValue('applicationDetails.applicationName', detail.value);
                  }}
                  onBlur={() => {
                    formik.setFieldTouched('applicationDetails.applicationName', true);
                  }}
                  value={formik.values.applicationDetails?.applicationName || ''}
                />
              </FormField>

              <FormField
                label="FinTech Team"
                description="Select the DaS FinTech team who owns this app"
                errorText={
                  formik.touched.applicationDetails?.applicationOwnerLabelInput && formik.errors.applicationDetails?.applicationOwnerLabelInput?.label
                }
              >
                <Select
                  placeholder="Choose team"
                  selectedOption={labelInputToSelectDropdown(formik.values.applicationDetails?.applicationOwnerLabelInput)}
                  onChange={({ detail }) => {
                    formik.setFieldValue(
                      'applicationDetails.applicationOwnerLabelInput',
                      selectDropdownToLabel(detail.selectedOption, 'ApplicationOwner')
                    );
                  }}
                  onBlur={() => {
                    formik.setFieldTouched('applicationDetails.applicationOwner', true);
                  }}
                  options={labelInputToLabelDropdownOptions(appContext.listApplicationDropdowns?.listApplicationOwners)}
                  selectedAriaLabel="Selected"
                />
              </FormField>
            </ColumnLayout>

            <ColumnLayout columns={2}>
              <FormField
                label={
                  <>
                    Application URL<i> - optional</i>
                  </>
                }
                description="Enter the URL for the app’s production environment"
                constraintText="Include http:// or https:// in the URL"
                errorText={formik.touched.applicationDetails?.applicationLink && formik.errors.applicationDetails?.applicationLink}
                stretch={true}
              >
                <Input
                  placeholder="Paste production URL"
                  onChange={({ detail }) => {
                    formik.setFieldValue('applicationDetails.applicationLink', detail.value);
                  }}
                  onBlur={() => {
                    formik.setFieldTouched('applicationDetails.applicationLink', true);
                  }}
                  value={formik.values.applicationDetails?.applicationLink || ''}
                />
              </FormField>

              <FormField
                label={
                  <>
                    Intro video URL<i> - optional</i>
                  </>
                }
                description="Link a video where users can get an intro to your app"
                errorText={
                  formik.touched.applicationDetails?.applicationIntroductionVideoUrl &&
                  formik.errors.applicationDetails?.applicationIntroductionVideoUrl
                }
              >
                <Input
                  placeholder="https://broadcast.amazon.com/videos/1186778"
                  onChange={({ detail }) => {
                    formik.setFieldValue('applicationDetails.applicationIntroductionVideoUrl', detail.value);
                  }}
                  onBlur={() => {
                    formik.setFieldTouched('applicationDetails.applicationIntroductionVideoUrl', true);
                  }}
                  value={formik.values.applicationDetails?.applicationIntroductionVideoUrl || ''}
                />
              </FormField>
            </ColumnLayout>

            <FormField
              label="Short application description"
              description="Short descriptions will be displayed in the navigation bar and “View all apps” page"
              errorText={
                formik.touched.applicationDetails?.shortApplicationDescription && formik.errors.applicationDetails?.shortApplicationDescription
              }
              constraintText={characterCountConstraintMessage(
                SHORT_DESCRIPTION_MAX_LENGTH,
                formik.values.applicationDetails?.shortApplicationDescription?.length || 0
              )}
              stretch={true}
            >
              <Textarea
                placeholder="Enter the short description"
                onChange={({ detail }) => {
                  formik.setFieldValue('applicationDetails.shortApplicationDescription', detail.value);
                }}
                onBlur={() => {
                  formik.setFieldTouched('applicationDetails.shortApplicationDescription', true);
                }}
                value={formik.values.applicationDetails?.shortApplicationDescription || ''}
              />
            </FormField>

            <FormField
              label="Full application description"
              description="Full descriptions will be displayed on the app’s landing page"
              errorText={
                formik.touched.applicationDetails?.fullApplicationDescription && formik.errors.applicationDetails?.fullApplicationDescription
              }
              constraintText={characterCountConstraintMessage(
                FULL_DESCRIPTION_MAX_LENGTH,
                formik.values.applicationDetails?.fullApplicationDescription?.length || 0
              )}
              stretch={true}
            >
              <Textarea
                placeholder="Enter the full description"
                onChange={({ detail }) => {
                  formik.setFieldValue('applicationDetails.fullApplicationDescription', detail.value);
                }}
                onBlur={() => {
                  formik.setFieldTouched('applicationDetails.fullApplicationDescription', true);
                }}
                value={formik.values.applicationDetails?.fullApplicationDescription || ''}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      </Box>

      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              description="Create new or update existing tags for your application by adding additional values to the Primary Key sections below."
            >
              Application categories
            </Header>
          }
        >
          <SpaceBetween size="m" direction="vertical">
            <FormField
              label="GL account"
              description="Select the category that best describes this app"
              // @ts-ignore
              errorText={formik.touched.applicationDetails?.glAccounts && formik.errors.applicationDetails?.glAccounts?.label}
            >
              <Select
                placeholder="Choose category"
                selectedOption={labelInputToSelectDropdown(formik.values.applicationDetails?.glAccounts)}
                onChange={({ detail }) => {
                  formik.setFieldValue('applicationDetails.glAccounts', selectDropdownToLabel(detail.selectedOption, 'GLAccount'));
                }}
                onBlur={() => {
                  formik.setFieldTouched('applicationDetails.glAccounts', true);
                }}
                options={labelInputToLabelDropdownOptions(appContext.listApplicationDropdowns?.listGLAccounts)}
                filteringType="auto"
                selectedAriaLabel="Selected"
              />
            </FormField>

            <FormField
              label="Product UI"
              description="Select the category that best describes this app"
              // @ts-ignore
              errorText={formik.touched.applicationDetails?.toolUI && formik.errors.applicationDetails?.toolUI?.label}
            >
              <Select
                placeholder="Choose product UI"
                selectedOption={labelInputToSelectDropdown(formik.values.applicationDetails?.toolUI)}
                onChange={({ detail }) => {
                  formik.setFieldValue('applicationDetails.toolUI', selectDropdownToLabel(detail.selectedOption, 'ToolUI'));
                }}
                onBlur={() => {
                  formik.setFieldTouched('applicationDetails.toolUI', true);
                }}
                options={labelInputToLabelDropdownOptions(appContext.listApplicationDropdowns?.listToolUI)}
                filteringType="auto"
                selectedAriaLabel="Selected"
              />
            </FormField>
          </SpaceBetween>
        </Container>
      </Box>
    </>
  );
};

export default ApplicationDetails;
