import { useContainerQuery } from '@amzn/awsui-component-toolkit';
import { useEffect, useRef, useState } from 'react';
import { StoredWidgetPlacement } from 'src/components/context/AppContextModels';
import { load, remove, save } from 'src/utilities/LocalStorage';

const AppSpecificDefaultLayout: ReadonlyArray<StoredWidgetPlacement> = [
  { id: 'welcomeWidget' },
  { id: 'announcementWidget' },
  { id: 'tutorialsWidget' },
  { id: 'feedbackWidget' }
];

export function useAppSpecificItemsLayout(storageKey: string) {
  const [width, ref] = useContainerQuery((entry) => entry.contentBoxWidth);
  const [layout, setLayout] = useState<ReadonlyArray<StoredWidgetPlacement> | null>(() => load(storageKey) ?? null);
  const itemsChanged = useRef(layout !== null);

  useEffect(() => {
    if (itemsChanged.current || !width) {
      return;
    }
    setLayout(AppSpecificDefaultLayout);
  }, [width]);

  function handleLayoutChange(layout: ReadonlyArray<StoredWidgetPlacement>) {
    itemsChanged.current = true;
    save(storageKey, layout);
    setLayout(layout);
  }

  function resetLayout() {
    itemsChanged.current = false;
    remove(storageKey);
    setLayout(AppSpecificDefaultLayout);
  }

  return [ref, layout ?? [], handleLayoutChange, resetLayout] as const;
}
