import { IconProps } from '@amzn/awsui-components-react';

export const AdminBaseBreadcrumbs = [
  {
    text: 'Admin console',
    href: '/admin'
  }
];

export interface CardConfiguration {
  Header: string;
  Content: string;
  Actions: { buttonText: string; icon?: IconProps.Name; path: string }[];
}

export const adminCards: CardConfiguration[] = [
  {
    Header: 'Applications',
    Content: 'Use the onboarding wizard and follow its guided steps to create and manage applications in DaS FinSuite.',
    Actions: [
      {
        buttonText: 'Manage apps',
        path: '/admin/applications'
      },
      {
        buttonText: 'New app',
        icon: 'add-plus',
        path: '/admin/manage-applications'
      }
    ]
  },
  {
    Header: 'Manage announcements',
    Content: 'Post general or product-specific announcements which users can see on the homepage and app-specific landing pages.',
    Actions: [{ buttonText: 'Go', path: '/admin/announcements' }]
  },
  {
    Header: 'Manage feedback',
    Content: 'View feedback submitted by users directly in DaS FinSuite.',
    Actions: [{ buttonText: 'Go', path: '/admin/feedbacks' }]
  },
  {
    Header: 'Manage Homepage',
    Content: 'Manage homepage content displayed in DaS FinSuite.',
    Actions: [{ buttonText: 'Go', path: '/admin/manage-homepage' }]
  },
  {
    Header: 'Manage teams',
    Content: 'Manage teams content displayed in DaS FinSuite.',
    Actions: [{ buttonText: 'Go', path: '/admin/manage-team-page' }]
  }
];
