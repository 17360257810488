import { useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { closeModal, homepageContentWizardToAPI, homepageNavigateBackToFrom } from '../HomePageManagementUtils';
import { FormikProps, FormikTouched } from 'formik';
import { HomePageWizard } from '../HomepageModel';
import { HomePageDetailsAndWidgetsSchema, infoPanelPageConfigurationSchema, SidePanelDetailsSchema } from '../HomePageManagementSchema';
import { eFinSuiteAdminMessages } from 'src/constants/AppConstants';
import { logger } from 'src/logger';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { updateHomePageContentMutation } from 'src/api/app-sync-services';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { FinSuiteAppMessages } from '../../AdminMessageConstants';

export const useHomePageWizard = () => {
  const userAuth = useAuth();
  const navigate = useNavigate();
  const { setHomePageContent } = useAppContext();

  const homePageFormRef = useRef<FormikProps<HomePageWizard>>(null);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });
  const [stepError, setStepError] = useState('');

  const setActiveStepIndexAndCloseTools = useCallback((index: number) => {
    setActiveStepIndex(index);
  }, []);

  const setFieldsTouched = useCallback((touchedFields: FormikTouched<HomePageWizard>) => {
    homePageFormRef.current?.setTouched(touchedFields, true);
  }, []);

  const validateStep = useCallback(async (schema: any, values: any) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const setHomePageWidgetTouched = () => {
    if (homePageFormRef.current) {
      const formValues = homePageFormRef.current.values;
      const touchedFields: FormikTouched<HomePageWizard> = {
        id: true,
        homePageDetailsAndWidgets: {
          applicationIntroductionVideoUrl: true,
          fullApplicationDescription: true,
          hasWelcomeContent: true,
          welcomeTitle: true,
          welcomeContentDetails: formValues.homePageDetailsAndWidgets.welcomeContentDetails.map(() => ({
            id: true,
            displayText: true,
            link: true,
            description: true,
            pointOfContact: true
          })),
          hasAnnouncements: true,
          hasTutorials: true,
          tutorialContentDetails: formValues.homePageDetailsAndWidgets.tutorialContentDetails.map(() => ({
            id: true,
            position: true,
            category: true,
            displayTitle: true,
            link: true
          })),
          hasFeedback: true
        }
      };

      homePageFormRef.current.setTouched(touchedFields, true);
    }
  };

  const onNavigate = useCallback(
    async (evt: any, activeStepIndex: number) => {
      const { requestedStepIndex, reason } = evt.detail;
      const currentValues = homePageFormRef.current?.values;

      if (!currentValues) return;

      let isValid = false;

      if (activeStepIndex === 0) {
        isValid = await validateStep(HomePageDetailsAndWidgetsSchema, currentValues.homePageDetailsAndWidgets);
        setHomePageWidgetTouched();
      } else if (reason === 'previous' && requestedStepIndex === 0) {
        isValid = await validateStep(infoPanelPageConfigurationSchema, currentValues.infoPanelPageConfiguration);
        setInfoPanelDetailsAsTouched();
      }

      if (isValid) {
        setActiveStepIndexAndCloseTools(requestedStepIndex);
        setStepError('');
      } else {
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
    },
    [activeStepIndex, setActiveStepIndexAndCloseTools, setFieldsTouched, validateStep]
  );

  const onWizardCancel = useCallback(() => {
    setShowModalInfo({
      showModal: true,
      eventHeader: 'Cancel',
      eventContentType: 'CANCEL_APPLICATION',
      messageInfo: { applicationName: 'Homepage content' }
    });
  }, []);

  const setInfoPanelDetailsAsTouched = () => {
    homePageFormRef.current?.setTouched({
      infoPanelPageConfiguration: {
        sidePanelDetails: {
          keyContacts: {
            isEnabled: true,
            title: true,
            isExpandedByDefault: true,
            contentType: true,
            richTextContent: true
          },
          officeHours: {
            isEnabled: true,
            title: true,
            isExpandedByDefault: true,
            contentType: true,
            richTextContent: true
          },
          supportLinks: {
            id: true,
            isEnabled: true,
            title: true,
            isExpandedByDefault: true,
            contentType: true,
            richTextContent: true,
            tableContent: true
          },
          customSidePanelWidgets: true
        }
      }
    });
  };

  const onCancel = useCallback(() => {
    closeModal(setShowModalInfo);
  }, []);

  const onConfirm = useCallback(
    (showModalInfo: ShowModalInfo) => {
      if (showModalInfo.eventContentType === 'CANCEL_APPLICATION') {
        homepageNavigateBackToFrom('cancel', '', '/admin', navigate);
      }
    },
    [navigate]
  );

  const onSubmit = useCallback(async () => {
    const currentValues = homePageFormRef.current?.values;
    if (!currentValues) return;

    const isValid = await validateStep(SidePanelDetailsSchema, currentValues.infoPanelPageConfiguration.sidePanelDetails);

    if (!isValid) {
      setInfoPanelDetailsAsTouched();
      setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      return;
    }

    try {
      await homePageFormRef.current?.validateForm();
      const homepageContent = homepageContentWizardToAPI(currentValues, userAuth.Alias);
      const response = await updateHomePageContentMutation(homepageContent);
      setHomePageContent(homepageContent);
      logger.info(`Successfully updated Homepage content.`, { info: response });
      homepageNavigateBackToFrom('success', FinSuiteAppMessages.SUCCESSFULLY_UPDATES_HOME_PAGE_CONTENT, '/admin', navigate);
    } catch (error: any) {
      setStepError(FinSuiteAppMessages.UNSUCCESSFULLY_ADDS_HOME_PAGE_CONTENT);
      logger.error(`Unable to submit Homepage Details update request.`, error);
    }
  }, [setFieldsTouched, validateStep, userAuth.Alias, setHomePageContent, navigate]);

  return {
    activeStepIndex,
    setActiveStepIndexAndCloseTools,
    stepError,
    onNavigate,
    onWizardCancel,
    homePageFormRef,
    showModalInfo,
    onCancel,
    onConfirm,
    onSubmit
  };
};
