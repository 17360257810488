import { QuillConfig } from './types';

export const DEFAULT_QUILL_MODULES = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image']
    ],
    handlers: {
      image: function (this: { quill: any }) {
        const curQuill = this && this.quill;
        if (curQuill) {
          const value = prompt('Enter image URL');
          if (value) {
            const range = curQuill.getSelection();
            range && curQuill.insertEmbed(range.index, 'image', value);
          }
        }
      }
    }
  }
};

export const DEFAULT_QUILL_FORMATS = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'clean'
];

export const SIMPLE_TEXT_CONFIG: QuillConfig = {
  modules: {
    toolbar: {
      container: [['bold', 'italic'], [{ list: 'bullet' }], ['link']]
    }
  },
  formats: ['bold', 'italic', 'list', 'bullet', 'link']
};
