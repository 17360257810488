import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import { AppNavigationState, SidePanelDetails } from 'src/components/context/AppContextModels';
import { ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { createItemMetadata, updateItemMetadata } from 'src/utilities/ItemMetadata';
import { v4 as uuidv4 } from 'uuid';
import HelpPanelConfiguration from '../applications/admin-manage-application/Step4';
import { HomepageEntity, HomePageWizard } from './HomepageModel';
import HomePageContentStep1 from './steps/HomePageStep1';
import { AdminBaseBreadcrumbs } from '../AdminHomePageConfig';

export const DEFAULT_SIDE_PANEL_DETAILS: SidePanelDetails = {
  keyContacts: {
    isEnabled: false,
    title: 'Key Contacts',
    isExpandedByDefault: false,
    contentType: 'rich-text-editor',
    richTextContent: ''
  },
  officeHours: {
    isEnabled: false,
    title: 'Office Hours',
    isExpandedByDefault: false,
    contentType: 'rich-text-editor',
    richTextContent: ''
  },
  supportLinks: {
    id: uuidv4(),
    isEnabled: false,
    title: 'Support Links',
    isExpandedByDefault: false,
    contentType: 'table',
    richTextContent: null,
    tableContent: []
  },
  customSidePanelWidgets: []
};

export const getHomePageContentDefaultState = (alias: string): HomepageEntity => ({
  id: uuidv4(),
  applicationIntroductionVideoUrl: '',
  fullApplicationDescription: '',
  hasWelcomeContent: true,
  welcomeTitle: 'Welcome',
  welcomeContentDetails: [],
  hasAnnouncements: false,
  hasTutorials: false,
  tutorialContentDetails: [],
  hasFeedback: false,
  sidePanelDetails: DEFAULT_SIDE_PANEL_DETAILS,
  itemMetadata: createItemMetadata(alias)
});

export const getTeamPageContentDefaultState = (alias: string): HomePageWizard => ({
  id: uuidv4(),
  homePageDetailsAndWidgets: {
    applicationIntroductionVideoUrl: '',
    fullApplicationDescription: '',
    hasWelcomeContent: false,
    welcomeTitle: 'Welcome',
    welcomeContentDetails: [],
    hasAnnouncements: false,
    hasTutorials: false,
    tutorialContentDetails: [],
    hasFeedback: false
  },
  infoPanelPageConfiguration: {
    sidePanelDetails: DEFAULT_SIDE_PANEL_DETAILS
  },
  itemMetadata: createItemMetadata(alias)
});

export const getHomePageManagementBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => [
  ...AdminBaseBreadcrumbs,
  {
    text: 'Homepage',
    href: '/admin/manage-homepage'
  }
];

export const HomepageWizardSteps: any[] = [
  {
    title: 'Homepage details & widgets',
    description: '',
    stateKey: 'homepageDetails',
    StepContent: HomePageContentStep1,
    isOptional: false
  },
  {
    title: 'Side panel details',
    description: '',
    stateKey: 'helpPanelConfiguration',
    StepContent: HelpPanelConfiguration,
    isOptional: false
  }
];

export const closeModal = (setShowModalInfo: React.Dispatch<React.SetStateAction<ShowModalInfo>>) => {
  setShowModalInfo({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });
};

export const homepageNavigateBackToFrom = (message: string, content: string, navigatePath: string, navigate: ReturnType<typeof useNavigate>) => {
  const navigationStateMessage: AppNavigationState =
    message === 'success'
      ? {
          message: {
            type: 'success',
            content: content,
            header: ''
          }
        }
      : {};

  navigate(navigatePath, { state: navigationStateMessage });
};

export const homepageContentAPIToWizard = (homepageEntity: HomepageEntity): HomePageWizard => {
  return {
    id: homepageEntity.id,
    homePageDetailsAndWidgets: {
      applicationIntroductionVideoUrl: homepageEntity.applicationIntroductionVideoUrl,
      fullApplicationDescription: homepageEntity.fullApplicationDescription,
      hasWelcomeContent: homepageEntity.hasWelcomeContent,
      welcomeTitle: homepageEntity.welcomeTitle,
      welcomeContentDetails: homepageEntity.welcomeContentDetails,
      hasAnnouncements: homepageEntity.hasAnnouncements,
      hasTutorials: homepageEntity.hasTutorials,
      tutorialContentDetails: homepageEntity.tutorialContentDetails,
      hasFeedback: homepageEntity.hasFeedback
    },
    infoPanelPageConfiguration: {
      sidePanelDetails: {
        keyContacts: homepageEntity.sidePanelDetails.keyContacts,
        officeHours: homepageEntity.sidePanelDetails.officeHours,
        supportLinks: {
          ...homepageEntity.sidePanelDetails.supportLinks,
          id: homepageEntity.sidePanelDetails.supportLinks.id || uuidv4()
        },
        customSidePanelWidgets: homepageEntity.sidePanelDetails.customSidePanelWidgets
      }
    },
    itemMetadata: homepageEntity.itemMetadata
  };
};

export const homepageContentWizardToAPI = (homepageWizard: HomePageWizard, alias: string): HomepageEntity => {
  const up = updateItemMetadata(alias, homepageWizard.itemMetadata);
  return {
    id: homepageWizard.id,
    applicationIntroductionVideoUrl: homepageWizard.homePageDetailsAndWidgets.applicationIntroductionVideoUrl,
    fullApplicationDescription: homepageWizard.homePageDetailsAndWidgets.fullApplicationDescription,
    hasWelcomeContent: homepageWizard.homePageDetailsAndWidgets.hasWelcomeContent,
    welcomeTitle: homepageWizard.homePageDetailsAndWidgets.welcomeTitle,
    welcomeContentDetails: homepageWizard.homePageDetailsAndWidgets.welcomeContentDetails,
    hasAnnouncements: homepageWizard.homePageDetailsAndWidgets.hasAnnouncements,
    hasTutorials: homepageWizard.homePageDetailsAndWidgets.hasTutorials,
    tutorialContentDetails: homepageWizard.homePageDetailsAndWidgets.tutorialContentDetails,
    hasFeedback: homepageWizard.homePageDetailsAndWidgets.hasFeedback,
    sidePanelDetails: homepageWizard.infoPanelPageConfiguration.sidePanelDetails,
    itemMetadata: up
  };
};
