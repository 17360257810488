import { Box, Calendar, CollectionPreferencesProps, DateInput, FormField, PropertyFilterProps, TableProps } from '@amzn/awsui-components-react';
import { AnnouncementFlatTableEntity } from 'src/components/context/AppContextModels';
import DFPPopover from 'src/components/generic-components/Popover';
import { dateTimeComparatorForTable, getReadableDateFromDateTime } from 'src/utilities/DateTimeUtilities';
import { AppNameWithLink } from '../all-apps/AllApps';
import React from 'react';
import { DEFAULT_LARGE_PAGE_SIZE } from 'src/components/generic-components/TableCommons';

export const DEFAULT_VISIBLE_CONTENT = ['announcementDate', 'title', 'applicationName', 'announcementCategory'];

export const DEFAULT_ANNOUNCEMENT_VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Properties',
    options: [
      { id: 'announcementDate', label: 'Announcement Date', editable: false },
      { id: 'title', label: 'Title', editable: false },
      { id: 'applicationName', label: 'Application', editable: false },
      { id: 'announcementCategory', label: 'Category', editable: false },
      { id: 'createdBy', label: 'Created By', editable: true },
      { id: 'createdTime', label: 'Created At', editable: true },
      { id: 'updatedBy', label: 'Update By', editable: true },
      { id: 'updatedTime', label: 'Edit Date', editable: true }
    ]
  }
];

export const ANNOUNCEMENTS_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: 'announcementDate',
    propertyLabel: 'Announcement Date',
    groupValuesLabel: 'Announcement Date values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'title',
    propertyLabel: 'Title',
    groupValuesLabel: 'Title values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'applicationName',
    propertyLabel: 'Application',
    groupValuesLabel: 'Application values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'announcementCategory',
    propertyLabel: 'Category',
    groupValuesLabel: 'Category values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'createdBy',
    propertyLabel: 'Created By',
    groupValuesLabel: 'Created By values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'createdTime',
    propertyLabel: 'Created At',
    groupValuesLabel: 'Created At values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'updatedBy',
    propertyLabel: 'Updated By',
    groupValuesLabel: 'Updated By values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'updatedTime',
    propertyLabel: 'Edit Date',
    groupValuesLabel: 'Edit Date values',
    operators: [':', '!:', '=', '!=']
  }
];

export const ANNOUNCEMENTS_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: DEFAULT_LARGE_PAGE_SIZE,
  visibleContent: DEFAULT_VISIBLE_CONTENT,
  wrapLines: true,
  stripedRows: false
};

export const ANNOUNCEMENT_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<AnnouncementFlatTableEntity>[] = [
  {
    id: 'announcementDate',
    header: 'Announcement Date',
    width: 120,
    cell: (item) => getReadableDateFromDateTime(item.announcementDate || item.createdTime),
    sortingComparator: (item1, item2) => {
      return dateTimeComparatorForTable(item1.announcementDate, item2.announcementDate);
    }
  },
  {
    id: 'title',
    header: 'Title',
    width: 400,
    cell: (item) => (
      <Box display="inline" fontSize="heading-s" fontWeight="normal" float="left" color="text-status-info">
        {/* {item.title} */}
        <DFPPopover
          title={item.title}
          link={item.announcementLink}
          linkText={'Link for full announcement'}
          popoverBody={item.announcement}
        ></DFPPopover>
      </Box>
    ),
    sortingField: 'title'
  },
  {
    id: 'applicationName',
    header: 'Application',
    width: 240,
    cell: (item) => <AppNameWithLink appName={item.applicationName} appId={item.applicationId} />,
    sortingField: 'applicationName'
  },
  {
    id: 'announcementCategory',
    header: 'Category',
    width: 200,
    cell: (item) => item.announcementCategory,
    sortingField: 'announcementCategory'
  },
  {
    id: 'createdBy',
    header: 'Created By',
    width: 180,
    cell: (item) => item.createdBy,
    sortingField: 'createdBy'
  },
  {
    id: 'createdTime',
    header: 'Created At',
    width: 80,
    cell: (item) => getReadableDateFromDateTime(item.createdTime),
    sortingComparator: (item1, item2) => {
      return dateTimeComparatorForTable(item1.createdTime, item2.createdTime);
    }
  },
  {
    id: 'updatedBy',
    header: 'Updated By',
    width: 160,
    cell: (item) => item.updatedBy,
    sortingField: 'updatedBy'
  },
  {
    id: 'updatedTime',
    header: 'Edit Date',
    width: 250,
    cell: (item) => getReadableDateFromDateTime(item.updatedTime),
    sortingComparator: (item1, item2) => {
      return dateTimeComparatorForTable(item1.updatedTime, item2.updatedTime);
    }
  }
];
