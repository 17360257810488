import { Box, Button, Header, SpaceBetween, StatusIndicator, Table } from '@amzn/awsui-components-react';
import { FormikProps } from 'formik';
import React from 'react';
import { ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { ApplicationWizard, TutorialContentDetailsEntity } from '../../AdminModels';
import { APP_TUTORIAL_COLUMN_DEFINITIONS, removeQuotesFromString } from './StepsUtilities';
import { MAX_TUTORIAL_LINKS } from './StepsValidations';
import { HomePageWizard } from '../../homepage-manage/HomepageModel';

type WizardType = ApplicationWizard | HomePageWizard;

type ConfigProperty<T> = T extends ApplicationWizard ? 'homePageConfigurations' : T extends HomePageWizard ? 'homePageDetailsAndWidgets' : never;

interface TutorialsContentTableProps<T extends WizardType> {
  formik: FormikProps<T>;
  selectedTutorialContentDetails: TutorialContentDetailsEntity[];
  editTutorialContent: () => void;
  setShowModalInfo: React.Dispatch<React.SetStateAction<ShowModalInfo>>;
  tutorialContentDetails: TutorialContentDetailsEntity[];
  setSelectedTutorialContentDetails: React.Dispatch<React.SetStateAction<TutorialContentDetailsEntity[]>>;
}

function getConfigProperty<T extends WizardType>(values: T): ConfigProperty<T> {
  return ('homePageConfigurations' in values ? 'homePageConfigurations' : 'homePageDetailsAndWidgets') as ConfigProperty<T>;
}

export function TutorialsContentTable<T extends WizardType>({
  formik,
  selectedTutorialContentDetails,
  editTutorialContent,
  setShowModalInfo,
  tutorialContentDetails,
  setSelectedTutorialContentDetails
}: TutorialsContentTableProps<T>): JSX.Element {
  const configProperty = getConfigProperty(formik.values);

  const touchedTutorialContentDetails = (formik.touched[configProperty] as any)?.tutorialContentDetails;
  const errorTutorialContentDetails = (formik.errors[configProperty] as any)?.tutorialContentDetails;

  return (
    <Table
      variant="embedded"
      header={
        <Header
          description={
            touchedTutorialContentDetails &&
            errorTutorialContentDetails && (
              <StatusIndicator type="error">{removeQuotesFromString(String(errorTutorialContentDetails))}</StatusIndicator>
            )
          }
          actions={
            <SpaceBetween size="m" direction="horizontal">
              <Button formAction="none" disabled={selectedTutorialContentDetails.length !== 1} onClick={editTutorialContent}>
                Edit
              </Button>
              <Button
                formAction="none"
                disabled={selectedTutorialContentDetails.length === 0}
                onClick={() => {
                  setShowModalInfo({
                    showModal: true,
                    eventHeader: 'Delete tutorial links',
                    eventContentType: 'DELETE_TUTORIAL_LINK'
                  });
                }}
              >
                {`Delete (${selectedTutorialContentDetails.length}) selected`}
              </Button>
            </SpaceBetween>
          }
        />
      }
      footer={<Box textAlign="left">{`You can add up to ${MAX_TUTORIAL_LINKS - tutorialContentDetails.length} more links.`}</Box>}
      empty={
        <Box textAlign="center" color="inherit">
          <Box padding={{ bottom: 's' }} variant="p" color="inherit">
            No data to display.
          </Box>
        </Box>
      }
      columnDefinitions={APP_TUTORIAL_COLUMN_DEFINITIONS}
      items={tutorialContentDetails}
      selectionType="multi"
      selectedItems={selectedTutorialContentDetails}
      onSelectionChange={({ detail }) => setSelectedTutorialContentDetails(detail.selectedItems)}
    />
  );
}
