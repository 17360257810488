import React, { useState } from 'react';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import BoardItem from '@amzn/awsui-board-components/board-item';
import { RemoveDialog } from './remove-dialog';
import { boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';
import { WidgetDataType } from 'src/components/das-finsuite/widgets/interface';

interface BaseWidgetProps {
  config: WidgetDataType;
  onRemove: () => void;
  removeConfirmationText?: string;
  actions?: ReadonlyArray<{ id: string; placeOfOrigin: string; text: string; onClick: () => void }>;
}

export function ConfigurableWidget({ config, onRemove, actions = [], removeConfirmationText }: BaseWidgetProps) {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const handleRemove = () => {
    if (removeConfirmationText) {
      setShowRemoveDialog(true);
    } else {
      onRemove();
    }
  };

  const handleActionClick = (actionId: string) => {
    const actionMatch = actions.find((action) => action.text === actionId);
    actionMatch?.onClick();
  };

  const handleDialogDismiss = () => {
    setShowRemoveDialog(false);
  };

  const handleDialogConfirm = () => {
    setShowRemoveDialog(false);
    onRemove();
  };

  const dropdownItems = [...actions.map((action) => ({ id: action.text, text: action.text })), { id: 'remove', text: 'Remove' }];

  return (
    <>
      <BoardItem
        header={<div>{config.header}</div>}
        disableContentPaddings={config.disableContentPaddings}
        i18nStrings={boardItemI18nStrings}
        settings={
          <ButtonDropdown
            items={dropdownItems}
            ariaLabel="Widget settings"
            variant="icon"
            onItemClick={(event) => {
              event.detail.id === 'remove' ? handleRemove() : handleActionClick(event.detail.id);
            }}
          />
        }
        footer={config.footer && <div>{config.footer}</div>}
      >
        <div>{config.content}</div>
      </BoardItem>
      {showRemoveDialog && <RemoveDialog title={removeConfirmationText!} onDismiss={handleDialogDismiss} onConfirm={handleDialogConfirm} />}
    </>
  );
}
