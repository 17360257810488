import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Feedback } from './FeedbacksModel';
import { AppLayout, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import { appLayoutAriaLabels } from 'src/i18n-strings';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { getBreadcrumbItems } from './FeedbackConfig';
import { AdminSideNavigation } from '../AdminSideNavigation';
import FeedbackTable from './FeedbackTable';
import { API, graphqlOperation } from 'aws-amplify';
import { listFeedback } from 'src/graphql/queries';
import { logger } from 'src/logger';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { Application } from 'src/components/context/AppContextModels';
import { getCurrentTime, getTimeDifference } from 'src/utilities/DateTimeUtilities';
import { recordApiRequest } from 'src/services/aws/CloudWatchRumService';

export type FeedbacksContextType = {
  feedbacks: Feedback[];
  setFeedbacks: (announcements: Feedback[]) => void;
  refreshFeedbacks: () => void;
  displayFlashNotification: (content: string, flashBarType: FlashbarProps.Type) => void;
  feedbacksStatus: boolean;
  setFeedbacksStatus: (feedbacksStatus: boolean) => void;
};

export const FeedbacksContext = createContext<FeedbacksContextType>({
  feedbacks: [],
  setFeedbacks: () => {},
  refreshFeedbacks: () => {},
  displayFlashNotification: () => {},
  feedbacksStatus: false,
  setFeedbacksStatus: () => {}
});
export const useFeedbacks = () => useContext(FeedbacksContext);

export const Feedbacks: React.FC<any> = () => {
  const appLayout = useRef<any>();
  const [feedbacksStatus, setFeedbacksStatus] = useState(false);
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const { listOfApplications } = useAppContext();

  useEffect(() => {
    fetchAllFeedbacks();
  }, []);

  const fetchAllFeedbacks = async () => {
    setFeedbacksStatus(true);
    let feedbacksList: Feedback[] = [];
    try {
      const start = getCurrentTime();
      const listOfFeedbacksAPIResponse: any = await API.graphql(graphqlOperation(listFeedback));
      const elapsed = getTimeDifference(start);
      logger.info(`Fetched Feedback in ${elapsed} ms`);
      recordApiRequest('listFeedback', elapsed);
      feedbacksList = listOfFeedbacksAPIResponse.data.listFeedback;
    } catch (error: any) {
      logger.error(`Unable to fetch the Announcement list`, error);
      feedbacksList = [];
    }
    const listOfActiveApplicationIds = listOfApplications
      .filter((application: Application) => application.itemMetadata.isActive)
      .map((app) => app.id);

    const _nonAppFeedbacksList = feedbacksList
      .filter((feedback) => feedback?.feedbackType !== 'Application')
      .filter((feedback) => feedback.itemMetadata.isActive);

    const _appFeedbacks = feedbacksList
      .filter((feedback) => feedback?.feedbackType === 'Application')
      .filter((feedback) => feedback.itemMetadata.isActive)
      .filter((activeFeedbacks) => listOfActiveApplicationIds.includes(activeFeedbacks?.applicationName?.id || ''));

    setFeedbacks(_nonAppFeedbacksList.concat(_appFeedbacks).sort((a, b) => b.itemMetadata.createdTime.localeCompare(a.itemMetadata.createdTime)));
    setFeedbacksStatus(false);
  };

  const refreshFeedbacks = () => {
    fetchAllFeedbacks();
  };

  const displayFlashNotification = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  return (
    <>
      <AppLayout
        ref={appLayout}
        headerSelector="#h"
        ariaLabels={appLayoutAriaLabels}
        breadcrumbs={<DFPBreadcrumbs items={getBreadcrumbItems()} />}
        toolsHide={true}
        navigation={<AdminSideNavigation />}
        stickyNotifications
        notifications={<Flashbar items={flashbarItems} />}
        contentType="table"
        content={
          <div className="app-layout-body">
            <FeedbacksContext.Provider
              value={{
                feedbacks,
                setFeedbacks,
                feedbacksStatus,
                setFeedbacksStatus,
                refreshFeedbacks,
                displayFlashNotification
              }}
            >
              <FeedbackTable />
            </FeedbacksContext.Provider>
          </div>
        }
      />
    </>
  );
};
