import { Button, FormField, Input } from '@amzn/awsui-components-react';
import React, { forwardRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { TableContentWidget } from 'src/components/context/AppContextModels';
import { ErrorFallback } from 'src/components/generic-components/ErrorFallback';
import { v4 as uuidv4 } from 'uuid';
import { reportIssuesAndAdditionalDetailsSchema } from './StepsValidations';

const ACTION_TYPES = {
  ADD: 'add',
  EDIT: 'edit'
} as const;

interface SupportLinksContentFormProps {
  hasEntities: boolean;
  addNewEntity: (entity: TableContentWidget) => void;
  updateEntity: (entity: TableContentWidget) => void;
}

export interface SupportLinksContentFormMethods {
  editSelectedRow: (entity: TableContentWidget) => void;
}

// TODO: 'position' field is deprecated and will be removed once backend and AppSync schema are updated
const initialEntity: TableContentWidget = {
  id: uuidv4(),
  position: null, // To be removed in future
  displayName: '',
  link: ''
};

const initialErrors = {
  id: '',
  position: '',
  displayName: '',
  link: ''
};

export const SupportLinksContentForm = forwardRef<SupportLinksContentFormMethods, SupportLinksContentFormProps>(
  ({ hasEntities, addNewEntity, updateEntity }, ref) => {
    const [actionType, setActionType] = useState<(typeof ACTION_TYPES)[keyof typeof ACTION_TYPES]>(ACTION_TYPES.ADD);
    const [isEntityValid, setIsEntityValid] = useState(false);
    const [newEntity, setNewEntity] = useState<TableContentWidget>(initialEntity);
    const [newEntityErrors, setNewEntityErrors] = useState(initialErrors);

    const resetForm = () => {
      setNewEntity({ ...initialEntity, id: uuidv4() });
      setNewEntityErrors(initialErrors);
      setIsEntityValid(false);
    };

    const handleValidation = async (field: string, value: string) => {
      try {
        await reportIssuesAndAdditionalDetailsSchema.validateAt(field, { [field]: value });
        setNewEntityErrors((prev) => ({ ...prev, [field]: '' }));
      } catch (error: any) {
        setNewEntityErrors((prev) => ({ ...prev, [field]: error.message }));
      }
      await isValidNewEntity();
    };

    const editSelectedRow = (selectedRow: TableContentWidget) => {
      if (selectedRow) {
        setActionType('edit');
        setNewEntity({
          ...selectedRow,
          position: null
        });
      } else {
        setActionType('add');
        resetForm();
      }
    };

    React.useImperativeHandle(ref, () => ({
      editSelectedRow
    }));

    const addNewRow = () => {
      const newRow: TableContentWidget = {
        ...newEntity,
        id: uuidv4()
      };
      resetForm();
      addNewEntity(newRow);
    };

    const isValidNewEntity = async () => {
      try {
        await reportIssuesAndAdditionalDetailsSchema.validate(newEntity, { abortEarly: false });
        setIsEntityValid(true);
      } catch (error: any) {
        setIsEntityValid(false);
      }
    };

    const handleInputChange = (field: keyof TableContentWidget, value: string) => {
      setNewEntity((prev) => ({ ...prev, [field]: value }));
    };

    const cancelEdit = () => {
      resetForm();
      setActionType('add');
    };

    const updateChanges = () => {
      updateEntity(newEntity);
      resetForm();
      setActionType('add');
    };

    return (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <div className="inline-table-horizontal-form">
          <FormField label="Display name" errorText={newEntityErrors?.displayName} stretch={true}>
            <Input
              placeholder="Enter display name"
              disabled={!hasEntities}
              onChange={({ detail }) => handleInputChange('displayName', detail.value)}
              value={newEntity.displayName}
              onBlur={() => handleValidation('displayName', newEntity.displayName)}
            />
          </FormField>

          <FormField className="flex-grow-field" label="Link" errorText={newEntityErrors?.link} stretch={true}>
            <Input
              placeholder="Paste URL"
              disabled={!hasEntities}
              onChange={({ detail }) => handleInputChange('link', detail.value)}
              value={newEntity.link}
              onBlur={() => handleValidation('link', newEntity.link)}
            />
          </FormField>

          {actionType === 'add' ? (
            <Button className="add-new-button" formAction="none" disabled={!isEntityValid} onClick={addNewRow}>
              Add
            </Button>
          ) : (
            <>
              <Button className="add-new-button" formAction="none" disabled={!isEntityValid} onClick={cancelEdit}>
                Cancel
              </Button>
              <Button variant="normal" className="add-new-button" formAction="none" disabled={!isEntityValid} onClick={updateChanges}>
                Save
              </Button>
            </>
          )}
        </div>
      </ErrorBoundary>
    );
  }
);
