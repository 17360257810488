import { Button, Header, SpaceBetween, Table } from '@amzn/awsui-components-react';
import React from 'react';
import EmptyData from 'src/components/generic-components/EmptyData';
import { WelcomeContentDetailsEntity } from '../../AdminModels';
import { APP_WELCOME_COLUMN_DEFINITIONS } from './StepsUtilities';

interface WelcomeContentTableProps {
  tableDescriptionForErrors?: string | boolean | React.ReactNode;
  selectedWelcomeContentDetails: WelcomeContentDetailsEntity[];
  editWelcomeContent: () => void;
  deleteSelected: () => void;
  welcomeContentDetails: WelcomeContentDetailsEntity[];
  setSelectedWelcomeContentDetails: React.Dispatch<React.SetStateAction<WelcomeContentDetailsEntity[]>>;
}

export const WelcomeContentTable: React.FC<WelcomeContentTableProps> = ({
  tableDescriptionForErrors,
  selectedWelcomeContentDetails,
  editWelcomeContent,
  deleteSelected,
  welcomeContentDetails,
  setSelectedWelcomeContentDetails
}) => {
  return (
    <Table
      className="welcome-content-modal-table-container"
      variant="container"
      header={
        <Header
          description={tableDescriptionForErrors}
          actions={
            <SpaceBetween size="m" direction="horizontal">
              <Button formAction="none" disabled={selectedWelcomeContentDetails.length !== 1} onClick={editWelcomeContent}>
                Edit
              </Button>
              <Button formAction="none" disabled={selectedWelcomeContentDetails.length === 0} onClick={deleteSelected}>
                {`Delete (${selectedWelcomeContentDetails.length}) selected`}
              </Button>
            </SpaceBetween>
          }
        >
          Displayed Links
        </Header>
      }
      empty={<EmptyData />}
      columnDefinitions={APP_WELCOME_COLUMN_DEFINITIONS}
      items={welcomeContentDetails}
      selectionType="multi"
      selectedItems={selectedWelcomeContentDetails}
      onSelectionChange={({ detail }) => setSelectedWelcomeContentDetails(detail.selectedItems)}
    />
  );
};
