import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logger } from 'src/logger';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContextProvider';
import { UserActionsEventTypes } from '../das-finsuite/das-finsuite-constants';

const FinSuiteNavigationItems: SideNavigationProps.Item[] = [
  {
    type: 'link',
    text: 'All Apps',
    href: '/all-apps/#list'
  },
  {
    type: 'link',
    text: 'DaS FinTech Team',
    href: '/teams'
  },
  {
    type: 'link',
    text: 'Announcements',
    href: '/announcements'
  },
  {
    type: 'link',
    text: 'Tutorials',
    href: '/tutorials'
  }
];

// Operational Excellence navigation items
const OperationalExcellenceSideNavItems: SideNavigationProps.Item[] = [
  {
    type: 'link',
    text: 'Tickets',
    href: '/fintech-ops/tickets'
  },
  {
    type: 'link',
    text: 'User Metrics',
    href: '/fintech-ops/user-metrics'
  },
  {
    type: 'link',
    text: 'SAS',
    href: '/fintech-ops/sas'
  },
  {
    type: 'link',
    text: 'Policy Engine',
    href: '/fintech-ops/policyEngine'
  },
  {
    type: 'link',
    text: 'Dogma',
    href: '/fintech-ops/dogma'
  },
  {
    type: 'link',
    text: 'Risk Remediator',
    href: '/fintech-ops/risk-remediator'
  },
  {
    type: 'link',
    text: 'Ops Mapping',
    href: '/fintech-ops/ops-mapping'
  },
  {
    type: 'link',
    text: 'Cost Metrics',
    href: '/fintech-ops/cost-metrics'
  }
];

// Base OE navigation configuration
const OperationalExcellenceNavigationGroup: SideNavigationProps.Item = {
  type: 'expandable-link-group',
  defaultExpanded: true,
  text: 'FinTech Ops',
  href: '/fintech-ops',
  items: OperationalExcellenceSideNavItems
};

const SupportLinksSectionGroup: SideNavigationProps.Item[] = [
  {
    type: 'divider'
  }
];

export const DFPHomePageSideNavigation: React.FC = () => {
  const userAuthenticationDetails = useAuth();
  const { homePageContent } = useAppContext();
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const [sideNavigationItems, setSideNavigationItems] = useState<SideNavigationProps.Item[]>(FinSuiteNavigationItems);
  const [activeRef, setActiveRef] = useState<string>('');

  useEffect(() => {
    setActiveRef(pathname + hash);
  }, [pathname, hash]);

  useEffect(() => {
    const updatedItems = [...FinSuiteNavigationItems];

    if (userAuthenticationDetails.isOEReadOnly && OperationalExcellenceNavigationGroup) {
      updatedItems.push(OperationalExcellenceNavigationGroup);
    }

    if (
      homePageContent?.sidePanelDetails?.supportLinks?.isEnabled &&
      homePageContent?.sidePanelDetails?.supportLinks?.tableContent &&
      homePageContent?.sidePanelDetails?.supportLinks?.tableContent?.length > 0
    ) {
      updatedItems.push(...SupportLinksSectionGroup);
      updatedItems.push(
        ...homePageContent.sidePanelDetails.supportLinks.tableContent
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .map(
            (link) =>
              ({
                type: 'link',
                text: link.displayName,
                href: link.link,
                external: true
              } as SideNavigationProps.Item)
          )
      );
    }

    setSideNavigationItems(updatedItems);
  }, [userAuthenticationDetails, homePageContent?.sidePanelDetails]);

  const handleNavigation = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    if (!event.detail.external) {
      logger.info('DaS FinSuite -> User Action: FinSuite Home page side nav', {
        event_type: UserActionsEventTypes.HOME_PAGE_SIDE_NAV_CLICK,
        value: event.detail.text
      });
      event.preventDefault();
      navigate(event.detail.href, {
        state: {
          from: '/',
          redirectFromHomePage: true,
          fromAppName: '',
          fromAppId: ''
        }
      });
    }
  };

  return (
    <SideNavigation
      header={{
        href: '/',
        text: 'DaS FinSuite'
      }}
      items={sideNavigationItems}
      activeHref={activeRef}
      onFollow={handleNavigation}
    />
  );
};
