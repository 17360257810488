export const FinSuiteAppMessages = {
  SUCCESSFULLY_DELETES_APP: 'Successfully deleted the application.',
  UNSUCCESSFULLY_DELETES_APP: 'Unable to delete application.',

  SUCCESSFULLY_ADDS_NEW_APP: (appName: string) => `Your new ${appName} page submitted successfully.`,
  SUCCESSFULLY_UPDATES_NEW_APP: (appName: string) => `Your updates to the ${appName} page saved successfully.`,
  UNSUCCESSFULLY_ADDS_NEW_APP: 'An error occurred while submitting your new application. Try again or submit a ticket.',
  UNSUCCESSFULLY_UPDATES_NEW_APP: 'An error occurred while submitting your changes to application. Try again or submit a ticket.',

  UNSUCCESSFULLY_ADDS_HOME_PAGE_CONTENT: 'An error occurred while submitting Homepage content. Try again or submit a ticket.',
  SUCCESSFULLY_UPDATES_HOME_PAGE_CONTENT: 'Home page content updated successfully',

  UNSUCCESSFULLY_TEAM_PAGE_CONTENT: 'An error occurred while submitting Team page content. Try again or submit a ticket.',
  SUCCESSFULLY_UPDATES_TEAM_PAGE_CONTENT: 'Team page content updated successfully'
};
