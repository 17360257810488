import { Board, BoardProps } from '@amzn/awsui-board-components';
import { Box, ButtonDropdown, ButtonDropdownProps, ContentLayout, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AnnouncementEntity, Application, AppRedirectInfo, StoredWidgetPlacement } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { eFinSuiteMessages, eLocalStorageKeys } from 'src/constants/AppConstants';
import { boardI18nStrings } from 'src/i18n-strings/board-I18nStrings';
import { logger } from 'src/logger';
import { InfoPanelPageConfigurations } from '../admin-components/AdminModels';
import { InfoPanelDetailsHelpPanel } from '../admin-components/applications/admin-manage-application/InfoPanelDetails';
import { getAllAppsBreadcrumbItems } from '../all-apps/AllApps';
import { exportLayout } from '../configurable-dashboard/Utils/ConfigurableDashboardUtils';
import { ResetButton } from '../configurable-dashboard/components/ResetButton';
import { ConfigurableWidget } from '../configurable-dashboard/components/configurable-widget';
import { UserActionsEventTypes } from '../das-finsuite-constants';
import { AnnouncementWidget } from '../widgets/announcements-widget/AnnouncementWidget';
import { FeedbackWidget } from '../widgets/feedback-widget/FeedbackWidget';
import { WidgetConfig, WidgetDataType } from '../widgets/interface';
import { TutorialsWidget } from '../widgets/tutorials-widget/TutorialsWidget';
import { WelcomeWidget } from '../widgets/welcome-widget/WelcomeWidget';
import { getCustomizedWelcomeWidget } from './AppSpecificHomePageUtils';
import { AppSpecificSideNavigation } from './AppSpecificSideNavigation';
import { useAppSpecificItemsLayout } from './UseAppSpecificItemsLayout';

const getAppSpecificBreadcrumbItems = (appName: string | null, appId: string) => {
  return [
    ...getAllAppsBreadcrumbItems(),
    {
      text: appName,
      href: '/all-apps/:' + appId
    }
  ];
};

export const AppSpecificHomePage = () => {
  const userAuthDetails = useAuth();
  const appContext = useAppContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();

  const [appSpecific, setAppSpecific] = useState<Application>();
  const [appSpecificNavigationState, setAppSpecificNavigationState] = useState<AppRedirectInfo>();
  const [applicationAnnouncements, setApplicationAnnouncements] = useState<AnnouncementEntity[]>([]);
  const [allWidgetsOfAppSpecific, setAllWidgetsOfAppSpecific] = useState<Record<string, WidgetConfig>>({});

  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [toolsOpen, setToolsOpen] = useState(true);
  const [infoPanelPageConfiguration, setInfoPanelPageConfiguration] = useState<InfoPanelPageConfigurations>();

  useEffect(() => {
    findAndLoadAppDetailsFromIdParam();
  }, [pathname, appContext.listOfApplications]);

  const findAndLoadAppDetailsFromIdParam = async () => {
    if (!appContext.listOfApplications.length) return;

    const appFound = appContext.listOfApplications
      .filter((application: Application) => application.itemMetadata.isActive)
      .find((app: Application) => app.id === id);

    if (!appFound) {
      navigate('/all-apps/#list');
      return;
    }

    const _applicationAnnouncements: AnnouncementEntity[] = appContext.announcements
      .filter((item) => item.itemMetadata.isActive && item.applicationName.id === id)
      .slice(0, 5);

    setAppSpecific(appFound);
    setApplicationAnnouncements(_applicationAnnouncements);
    setInfoPanelPageConfiguration({
      sidePanelDetails: appFound.sidePanelDetails
    });
    setAppSpecificNavigationState({
      from: `/all-apps/${appFound.id}`,
      redirectFromHomePage: false,
      fromAppName: appFound.applicationName || '',
      fromAppId: appFound.id || ''
    });

    configureAppSpecificWidgets(appFound);
  };
  // Dropdown options for App Non-Prod links
  const listOfLinksAndNonProdLinks = (): ReadonlyArray<ButtonDropdownProps.ItemOrGroup> => {
    const goToAppDropdowns: any = [];
    if (appSpecific?.applicationLink) {
      goToAppDropdowns.push({
        id: 'applicationLink',
        text: 'App Link',
        href: appSpecific?.applicationLink,
        target: '_blank',
        external: true
      });
    }
    if (appSpecific?.nonProductionEnvironments) {
      const otherLinks: any[] = [];
      appSpecific?.nonProductionEnvironments?.map((nonProd, index) => {
        if (userAuthDetails.isAdmin && nonProd.accessLevel[0].label === 'Admin')
          otherLinks.push({
            id: index,
            text: nonProd.displayText,
            description: nonProd.accessLevel,
            href: nonProd?.link,
            target: '_blank',
            external: true
          });
        else if (nonProd.accessLevel[0].label === 'Public')
          otherLinks.push({
            id: index,
            text: nonProd.displayText,
            description: nonProd.accessLevel,
            href: nonProd?.link,
            target: '_blank',
            external: true
          });
      });

      if (otherLinks.length !== 0) {
        goToAppDropdowns.push({
          id: 'otherLinks',
          text: 'Other Links',
          items: otherLinks
        });
      }
    }
    return goToAppDropdowns;
  };

  const displayFlashMessage = (header: string, content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        header: header,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  const getTopEightPositionedTutorials = () => {
    const tutorials = appSpecific?.tutorialContentDetails
      ?.filter((tutorial) => tutorial.position !== null)
      .sort((a, b) => (a.position || 0) - (b.position || 0))
      .slice(0, 8);
    return tutorials;
  };

  const [ref, layout, setLayout, resetLayout] = useAppSpecificItemsLayout(eLocalStorageKeys.APP_SPECIFIC_PAGE_WIDGETS_LAYOUT + id);
  const welcomeWidget: WidgetConfig = WelcomeWidget({
    welcomeTitle: appSpecific?.welcomeTitle || 'Welcome',
    description: '',
    welcomeContentDetails: appSpecific?.welcomeContentDetails || []
  });
  const announcementWidget = AnnouncementWidget('Announcement', '', applicationAnnouncements, appSpecificNavigationState);
  const tutorialsWidget = TutorialsWidget('Tutorials', '', getTopEightPositionedTutorials() || [], appSpecificNavigationState);
  const feedbackWidget = FeedbackWidget(
    'Feedback',
    '',
    appSpecific?.id || '',
    appSpecific?.applicationName || '',
    'Application',
    displayFlashMessage
  );

  useEffect(() => {
    if (appSpecific) configureAppSpecificWidgets(appSpecific);
  }, [appSpecific]);

  const configureAppSpecificWidgets = (appDetails: Application) => {
    if (appDetails) {
      let applicationWidgets: Record<string, WidgetConfig> = {};

      if (appDetails?.hasWelcomeContent) {
        const modifiedWelcomeWidget: WidgetConfig = getCustomizedWelcomeWidget(welcomeWidget, appDetails.id);

        applicationWidgets = {
          ...applicationWidgets,
          welcomeWidget: modifiedWelcomeWidget
        };
      }

      if (appDetails?.hasAnnouncements) {
        applicationWidgets = {
          ...applicationWidgets,
          announcementWidget
        };
      }

      if (appDetails?.hasTutorials) {
        applicationWidgets = {
          ...applicationWidgets,
          tutorialsWidget
        };
      }

      if (appDetails?.hasFeedback) {
        applicationWidgets = {
          ...applicationWidgets,
          feedbackWidget
        };
      }
      setAllWidgetsOfAppSpecific(applicationWidgets);
    }
  };

  const getAppSpecificBoardWidgets = (layout: ReadonlyArray<StoredWidgetPlacement>) => {
    const getAppSpecificBoardWidget = layout
      .filter((position) => position.id in allWidgetsOfAppSpecific)
      .map((position) => {
        const widget = allWidgetsOfAppSpecific[position.id];
        return {
          ...position,
          ...widget,
          columnSpan: position.columnSpan ?? widget.definition?.defaultColumnSpan ?? 1,
          rowSpan: position.rowSpan ?? widget.definition?.defaultRowSpan ?? 2
        };
      });
    return getAppSpecificBoardWidget;
  };

  const getWidgetActions = (item: BoardProps.Item<WidgetDataType>) => {
    const editableWidgets = ['welcomeWidget', 'announcementWidget', 'tutorialsWidget', 'feedbackWidget'];
    const isEditableWidget = editableWidgets.includes(item.id);
    const canEditWidget = isEditableWidget && userAuthDetails.isAdmin;

    if (!canEditWidget) {
      return [];
    }

    return [
      {
        id: item.id,
        placeOfOrigin: 'app_specific_home_page',
        text: 'Edit in console',
        onClick: () => navigate('/admin/manage-applications/' + appSpecific?.id)
      }
    ];
  };

  return (
    <>
      <AppLayout
        contentType="dashboard"
        headerSelector="#h"
        navigation={<AppSpecificSideNavigation appSpecific={appSpecific} />}
        tools={
          <InfoPanelDetailsHelpPanel
            headerName="Help / Support"
            sidePanelDetails={infoPanelPageConfiguration?.sidePanelDetails}
            appDetailedDescription={appSpecific?.fullApplicationDescription || ''}
            applicationIntroductionVideoUrl={appSpecific?.applicationIntroductionVideoUrl}
          />
        }
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => {
          setToolsOpen(detail.open);
          logger.info(`DaS FinSuite -> User Action: App Specific -> Help Panel change`, {
            event_type: UserActionsEventTypes.APP_SPECIFIC_HELP_PANEL_CHANGE,
            value: detail.open ? 'Open' : 'Close'
          });
        }}
        breadcrumbs={<DFPBreadcrumbs items={getAppSpecificBreadcrumbItems(appSpecific?.applicationName || '', appSpecific?.id || '')} />}
        notifications={<Flashbar items={flashbarItems} />}
        stickyNotifications
        content={
          <div className="app-layout-body">
            <ContentLayout
              disableOverlap
              header={
                <Header
                  actions={
                    <SpaceBetween size="xs" direction="horizontal" alignItems="center">
                      {appSpecific?.hasNonProductionEnvironments && (
                        <ButtonDropdown variant="primary" expandableGroups items={listOfLinksAndNonProdLinks()}>
                          Go to app
                        </ButtonDropdown>
                      )}
                      {!appSpecific?.hasNonProductionEnvironments && (
                        <Button
                          ariaLabel="Report a bug (opens new tab)"
                          formAction="none"
                          href={appSpecific?.applicationLink || undefined}
                          disabled={appSpecific?.applicationLink === ''}
                          iconAlign="right"
                          iconName="external"
                          target="_blank"
                          variant="primary"
                        >
                          Go to app
                        </Button>
                      )}
                      {appContext.isAdminControlsEnabled && (
                        <Button
                          variant="normal"
                          onClick={() => {
                            navigate('/admin/manage-applications/' + appSpecific?.id);
                          }}
                        >
                          {'Edit in admin console'}
                        </Button>
                      )}
                      <ResetButton onReset={resetLayout}>Reset to default layout</ResetButton>
                    </SpaceBetween>
                  }
                >
                  <Box variant="h1">{appSpecific?.applicationName}</Box>
                </Header>
              }
            >
              <Box margin={{ top: 'm' }}>
                <div ref={ref}>
                  <Board
                    empty={
                      <SpaceBetween direction="vertical" size="xs" alignItems="center">
                        <Box fontSize="body-m" fontWeight="normal" textAlign="center" variant="awsui-key-label">
                          {`${eFinSuiteMessages.NO_INFORMATION_WIDGETS}`}
                        </Box>
                        <>{Object.entries(allWidgetsOfAppSpecific).length > 0 && <Button onClick={resetLayout}>Reset to default layout</Button>}</>
                      </SpaceBetween>
                    }
                    i18nStrings={boardI18nStrings}
                    items={getAppSpecificBoardWidgets(layout)}
                    onItemsChange={({ detail: { items } }) => {
                      logger.info(`DaS FinSuite -> User Action: App Specific -> ${appSpecific?.applicationName} Board resize`, {
                        event_type: UserActionsEventTypes.APP_SPECIFIC_BOARD_RESIZE,
                        value: appSpecific?.applicationName
                      });
                      setLayout(exportLayout(items));
                    }}
                    renderItem={(item, actions) => {
                      const Wrapper = item.data.provider ?? React.Fragment;
                      return (
                        <Wrapper>
                          <ConfigurableWidget config={item.data} onRemove={actions.removeItem} actions={getWidgetActions(item)} />
                        </Wrapper>
                      );
                    }}
                  />
                </div>
              </Box>
            </ContentLayout>
          </div>
        }
      />
    </>
  );
};
