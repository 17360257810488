import { AppLayout } from '@amzn/awsui-components-react';
import Wizard from '@amzn/awsui-components-react/polaris/wizard';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingStatus, SidePanelDetails } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { ConfirmationModal } from 'src/components/generic-components/ConfirmationModal';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import DisplayContentInCode from 'src/components/generic-components/DisplayContentInCode';
import { appLayoutAriaLabels, wizardI18nStrings } from 'src/i18n-strings';
import { ApplicationWizard } from '../../AdminModels';
import { AdminSideNavigation } from '../../AdminSideNavigation';
import { AdminNewAppWizardSteps, getNewAppBreadcrumbItems } from './AdminNewAppConfig';
import { InfoPanelDetailsHelpPanel } from './InfoPanelDetails';
import { APIToWizard, INITIAL_STEP_INFO, useWizard } from './StepsUtilities';
import { ApplicationFormSchema } from './StepsValidations';

export const AdminNewApp: React.FC = () => {
  const { id: applicationId } = useParams();
  const { pathname, state } = useLocation();
  const appContext = useAppContext();
  const existingApplicationNames = appContext?.listOfApplications?.map((application) => application.applicationName || '');
  const navigate = useNavigate();

  useEffect(() => {
    setFromUrl(state?.from ? state?.from : '');
  }, [pathname, state]);

  const [infoPanelPageConfiguration, setInfoPanelPageConfiguration] = useState<SidePanelDetails>();
  const [applicationIntroductionVideoUrl, setApplicationIntroductionVideoUrl] = useState<string | null>(null);
  const [appFullDescription, setAppFullDescription] = useState<string | null>(null);

  const {
    activeStepIndex,
    setActiveStepIndexAndCloseTools,
    stepError,
    action,
    setAction,
    applicationFormRef,
    onNavigate,
    onWizardCancel,
    onSubmit,
    showModalInfo,
    onCancel,
    onConfirm,
    fromUrl,
    setFromUrl
  } = useWizard();

  const [applicationWizardInitialValues, setApplicationWizardInitialValues] = useState<ApplicationWizard>(INITIAL_STEP_INFO);

  useEffect(() => {
    if (appContext.contextLoadingStatus === LoadingStatus.Completed) {
      if (applicationId) {
        setAction('Update');
        handleEditApplication(applicationId);
      } else {
        setAction('Create');
        setApplicationWizardInitialValues(INITIAL_STEP_INFO);
      }
    }
  }, [applicationId, appContext]);

  const handleEditApplication = async (applicationId: string) => {
    const selectedApplication = appContext.listOfApplications.find((application) => application.id === applicationId);
    selectedApplication
      ? setApplicationWizardInitialValues(APIToWizard(selectedApplication, appContext.teamPageContent))
      : navigate('/admin/manage-applications');
  };

  return (
    <>
      <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />
      <AppLayout
        className="admin-console-app-wizard"
        headerSelector="#h"
        ariaLabels={appLayoutAriaLabels}
        navigation={<AdminSideNavigation />}
        breadcrumbs={
          <DFPBreadcrumbs
            items={getNewAppBreadcrumbItems(
              applicationWizardInitialValues.applicationDetails.applicationName === ''
                ? 'New Application'
                : applicationWizardInitialValues.applicationDetails.applicationName || ''
            )}
          />
        }
        contentType="wizard"
        // only show the tools panel when the active step is the Info Panel
        tools={
          <InfoPanelDetailsHelpPanel
            displayingInForm={true}
            headerName="Preview"
            sidePanelDetails={infoPanelPageConfiguration}
            appDetailedDescription={appFullDescription || ''}
            applicationIntroductionVideoUrl={applicationIntroductionVideoUrl}
          />
        }
        toolsOpen={activeStepIndex === 3}
        content={
          <div className="app-layout-body">
            <Formik<ApplicationWizard>
              innerRef={applicationFormRef}
              enableReinitialize
              initialValues={applicationWizardInitialValues}
              validationSchema={ApplicationFormSchema(
                existingApplicationNames,
                applicationWizardInitialValues.applicationDetails.applicationName !== ''
              )}
              onSubmit={onSubmit}
            >
              {(formik) => {
                useEffect(() => {
                  setInfoPanelPageConfiguration(formik.values.infoPanelPageConfiguration.sidePanelDetails);
                  setAppFullDescription(formik.values.applicationDetails.fullApplicationDescription);
                  setApplicationIntroductionVideoUrl(formik.values.applicationDetails.applicationIntroductionVideoUrl || null);
                }),
                  [formik];
                return (
                  <>
                    <form onSubmit={formik.handleSubmit}>
                      <Wizard
                        steps={AdminNewAppWizardSteps.map(({ title, description, stateKey, StepContent, isOptional }) => ({
                          title,
                          description,
                          isOptional: isOptional,
                          content: (
                            <StepContent
                              setActiveStepIndex={setActiveStepIndexAndCloseTools}
                              applicationFormRef={applicationFormRef}
                              formik={formik}
                            />
                          ),
                          errorText: stepError !== '' ? stepError : undefined
                        }))}
                        activeStepIndex={activeStepIndex}
                        i18nStrings={wizardI18nStrings(action)}
                        onNavigate={(event) =>
                          onNavigate(event, activeStepIndex, applicationWizardInitialValues.applicationDetails.applicationName !== '')
                        }
                        onCancel={onWizardCancel}
                        onSubmit={onSubmit}
                      />
                    </form>

                    {/* Just for debugging purposes in dev environment  */}
                    <DisplayContentInCode details={formik}></DisplayContentInCode>
                  </>
                );
              }}
            </Formik>
          </div>
        }
      />
    </>
  );
};
