import { useEffect, useState } from 'react';
import { logger } from 'src/logger';

/**
 * A custom React hook that persists state in localStorage.
 *
 * @template T The type of the state value to be persisted
 * @param {string} localStorageKey The key under which to store the value in localStorage
 * @param {T} defaultValue The default value to use if no value exists in localStorage
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]} A tuple containing the current value and a setter function
 *
 * @example
 * const [count, setCount] = usePersistedState('myCounter', 0);
 * // count will persist across page reloads
 * setCount(count + 1);
 */
export function usePersistedState<T>(localStorageKey: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    try {
      const localStorageItem = localStorage.getItem(localStorageKey);
      return localStorageItem !== null ? JSON.parse(localStorageItem) : defaultValue;
    } catch (err: any) {
      logger.error(`Error parsing local storage item "${localStorageKey}":`, err);
      return defaultValue;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(value));
    } catch (err: any) {
      logger.error(`Error setting local storage item "${localStorageKey}":`, err);
    }
  }, [value, localStorageKey]);

  return [value, setValue];
}
