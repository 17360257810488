import { FormField } from '@amzn/awsui-components-react';
import { DeltaStatic, Sources } from 'quill';
import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import { DEFAULT_QUILL_FORMATS, DEFAULT_QUILL_MODULES } from './quill-config';
import { CustomQuillEditorProps } from './types';

const CustomQuillEditor: React.FC<CustomQuillEditorProps> = ({
  name,
  value,
  onChange,
  onBlur,
  label,
  errorText,
  isOptional = false,
  constraintText,
  className,
  info,
  quillConfig
}) => {
  const reactQuillRef = useRef<ReactQuill>(null);

  const formattedLabel = isOptional ? (
    <>
      {label}
      <i> - optional</i>
    </>
  ) : (
    label
  );

  const handleChange = (content: string, delta: DeltaStatic, source: Sources, editor: ReactQuill.UnprivilegedEditor) => {
    const EMPTY_QUILL_TEXT = '<p><br></p>';
    if (content === EMPTY_QUILL_TEXT) {
      onChange(name, null);
    }
    onChange(name, editor.getHTML());
  };

  return (
    <FormField className={className} label={formattedLabel} errorText={errorText} stretch={true} constraintText={constraintText} info={info}>
      <ReactQuill
        ref={reactQuillRef}
        theme="snow"
        value={value || ''}
        onChange={handleChange}
        onBlur={onBlur}
        modules={{
          ...(quillConfig?.modules || DEFAULT_QUILL_MODULES),
          clipboard: {
            matchVisual: false // Prevents Quill from adding extra newlines
          }
        }}
        formats={quillConfig?.formats || DEFAULT_QUILL_FORMATS}
      />
    </FormField>
  );
};

export default CustomQuillEditor;
