import { BoardProps } from '@amzn/awsui-board-components';
import { StoredWidgetPlacement } from 'src/components/context/AppContextModels';
import { WidgetDataType } from '../../widgets/interface';

export function exportLayout(items: ReadonlyArray<BoardProps.Item<WidgetDataType>>): ReadonlyArray<StoredWidgetPlacement> {
  return items.map((item) => ({
    id: item.id,
    columnSpan: item.columnSpan,
    columnOffset: item.columnOffset,
    rowSpan: item.rowSpan
  }));
}
