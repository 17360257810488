/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listLabels = /* GraphQL */ `query ListLabels {
  listLabels {
    id
    labelType
    label
    
  }
}
` as GeneratedQuery<APITypes.ListLabelsQueryVariables, APITypes.ListLabelsQuery>;
export const listApplications = /* GraphQL */ `query ListApplications {
  listApplications {
    id
    applicationName
    applicationLink
    applicationOwnerId
    applicationIntroductionVideoUrl
    shortApplicationDescription
    fullApplicationDescription
    glAccounts {
      id
      labelType
      label
      
    }
    toolUI {
      id
      labelType
      label
      
    }
    hasNonProductionEnvironments
    nonProductionEnvironments {
      id
      accessLevel {
        id
        labelType
        label
        
      }
      displayText
      link
      
    }
    timeGranularity {
      id
      labelType
      label
      
    }
    keyDimension {
      id
      labelType
      label
      
    }
    process {
      id
      labelType
      label
      
    }
    scenario {
      id
      labelType
      label
      
    }
    lastEnhanced {
      id
      labelType
      label
      
    }
    hasWelcomeContent
    welcomeTitle
    welcomeContentDetails {
      id
      displayText
      link
      description
      pointOfContact
      
    }
    hasAnnouncements
    hasTutorials
    tutorialContentDetails {
      id
      category
      position
      displayTitle
      link
      
    }
    hasFeedback
    sidePanelDetails {
      keyContacts {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      officeHours {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      supportLinks {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      customSidePanelWidgets {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      
    }
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListApplicationsQueryVariables, APITypes.ListApplicationsQuery>;
export const listFeedback = /* GraphQL */ `query ListFeedback {
  listFeedback {
    id
    applicationName {
      id
      labelType
      label
      
    }
    rating
    feedback
    feedbackType
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListFeedbackQueryVariables, APITypes.ListFeedbackQuery>;
export const listAnnouncements = /* GraphQL */ `query ListAnnouncements {
  listAnnouncements {
    id
    title
    applicationName {
      id
      labelType
      label
      
    }
    announcementCategory {
      id
      labelType
      label
      
    }
    announcement
    announcementDate
    announcementLink
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListAnnouncementsQueryVariables, APITypes.ListAnnouncementsQuery>;
export const listApplicationFavorites = /* GraphQL */ `query ListApplicationFavorites($userAlias: String) {
  listApplicationFavorites(userAlias: $userAlias) {
    id
    userAlias
    applicationId
    
  }
}
` as GeneratedQuery<APITypes.ListApplicationFavoritesQueryVariables, APITypes.ListApplicationFavoritesQuery>;
export const listGeneralContent = /* GraphQL */ `query ListGeneralContent {
  listGeneralContent {
    id
    name
    content
    
  }
}
` as GeneratedQuery<APITypes.ListGeneralContentQueryVariables, APITypes.ListGeneralContentQuery>;
export const listPolicyEngineDetail = /* GraphQL */ `query ListPolicyEngineDetail {
  listPolicyEngineDetail {
    policy_engine_details {
      metadata {
        last_updated_at
        
      }
      data {
        last_updated_at
        create_date
        policy_class
        severity
        violator_id
        violation_name
        violator_name
        violator_type
        policy_type
        resolution_type
        violation_entity_type
        violation_pretty_name
        status
        violator_owner_name
        violator_manager_name
        manager_name_hierarchy_concat
        team_name
        violator_manager_login
        violator_owner
        resolved_date
        manager_login_hierarchy_concat
        
      }
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListPolicyEngineDetailQueryVariables, APITypes.ListPolicyEngineDetailQuery>;
export const listTicketMetricDetail = /* GraphQL */ `query ListTicketMetricDetail {
  listTicketMetricDetail {
    ticket_details {
      metadata {
        last_updated_at
        
      }
      data {
        manager_name_hierarchy_concat
        assigned_to_individual_name
        status
        application_name
        assigned_to_group
        impact
        team_name
        short_description
        create_date
        resolved_date
        assigned_date
        sim_issue_alias
        case_id
        resolution_type
        assignee_login
        last_modified_by
        modified_date
        manager_login_hierarchy_concat
        total_time_spent
        
      }
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListTicketMetricDetailQueryVariables, APITypes.ListTicketMetricDetailQuery>;
export const listSASDetail = /* GraphQL */ `query ListSASDetail {
  listSASDetail {
    sas_details {
      metadata {
        last_updated_at
        
      }
      data {
        assignee_name
        assignee_login
        team_name
        supervisor_login_name_hierarchy
        supervisor_name_hierarchy
        campaign_name
        resource_type
        resource_name
        is_blocked_campaign
        has_recommendation
        snapshot_month
        risk_status_at_eom
        oldest_risk_age
        oldest_recommendation_age
        
      }
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListSASDetailQueryVariables, APITypes.ListSASDetailQuery>;
export const listUserMetric = /* GraphQL */ `query ListUserMetric {
  listUserMetric {
    user_metrics_details {
      metadata {
        last_updated_at
        
      }
      data {
        log_month
        application
        application_type
        team_name
        supervisor_name_hierarchy
        supervisor_login_name_hierarchy
        measure_value
        distinct_users
        last_updated_at
        
      }
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListUserMetricQueryVariables, APITypes.ListUserMetricQuery>;
export const listDogmaDetail = /* GraphQL */ `query ListDogmaDetail {
  listDogmaDetail {
    dogma_details {
      metadata {
        last_updated_at
        
      }
      data {
        created_date
        manager_login_hierarchy_concat
        manager_name_hierarchy_concat
        resolved_date
        risk_best_practices
        risk_entity_type
        risk_entity_value
        risk_owner_login
        risk_owner_name
        risk_pipeline_name
        risk_rule_id
        risk_severity
        status
        team_name
        
      }
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListDogmaDetailQueryVariables, APITypes.ListDogmaDetailQuery>;
export const listMappingConfig = /* GraphQL */ `query ListMappingConfig {
  listMappingConfig {
    mapping_config {
      data {
        mapping_key
        mapping_name
        target_table
        
      }
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListMappingConfigQueryVariables, APITypes.ListMappingConfigQuery>;
export const listMappingDetails = /* GraphQL */ `query ListMappingDetails($table_name: String) {
  listMappingDetails(table_name: $table_name) {
    mapping_details {
      data
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListMappingDetailsQueryVariables, APITypes.ListMappingDetailsQuery>;
export const listMappingColDetails = /* GraphQL */ `query ListMappingColDetails {
  listMappingColDetails {
    col_def {
      data
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListMappingColDetailsQueryVariables, APITypes.ListMappingColDetailsQuery>;
export const listMappingValidationConfig = /* GraphQL */ `query ListMappingValidationConfig {
  listMappingValidationConfig {
    mapping_validation_config {
      data
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListMappingValidationConfigQueryVariables, APITypes.ListMappingValidationConfigQuery>;
export const listHomePageContent = /* GraphQL */ `query ListHomePageContent {
  listHomePageContent {
    id
    applicationIntroductionVideoUrl
    fullApplicationDescription
    hasWelcomeContent
    welcomeTitle
    welcomeContentDetails {
      id
      displayText
      link
      description
      pointOfContact
      
    }
    hasAnnouncements
    hasTutorials
    tutorialContentDetails {
      id
      category
      position
      displayTitle
      link
      
    }
    hasFeedback
    sidePanelDetails {
      keyContacts {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      officeHours {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      supportLinks {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      customSidePanelWidgets {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      
    }
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListHomePageContentQueryVariables, APITypes.ListHomePageContentQuery>;
export const listTeamPageContent = /* GraphQL */ `query ListTeamPageContent {
  listTeamPageContent {
    id
    enableTeamInfoTabs
    teams {
      id
      teamName
      teamIntroductionVideoUrl
      teamOverView
      teamWelcomeDetails {
        id
        displayText
        link
        description
        pointOfContact
        
      }
      
    }
    sidePanelDetails {
      keyContacts {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      officeHours {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      supportLinks {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      customSidePanelWidgets {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      
    }
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListTeamPageContentQueryVariables, APITypes.ListTeamPageContentQuery>;
