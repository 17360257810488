import { Box, Button, Checkbox, Container, FormField, Header, Multiselect, SpaceBetween, StatusIndicator, Table } from '@amzn/awsui-components-react';
import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { ConfirmationModal, ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import EmptyData from 'src/components/generic-components/EmptyData';
import {
  labelInputToLabelDropdownOptions,
  labelInputToMultiSelectDropdown,
  multiSelectDropdownToLabel
} from 'src/components/generic-components/LabelDropdownUtility';
import { ApplicationWizard, NonProductionEnvironmentsEntity } from '../../AdminModels';
import NonProductionEnvironmentForm, { NonProductionEnvironmentFormMethods } from './NonProductionEnvironment';
import { APP_ENV_COLUMN_DEFINITIONS, removeQuotesFromString } from './StepsUtilities';

interface LinksAndTagsProps {
  formik: FormikProps<ApplicationWizard>;
}

const LinksAndTags: React.FC<LinksAndTagsProps> = ({ formik }) => {
  const appContext = useAppContext();

  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  const childRef = useRef<NonProductionEnvironmentFormMethods>(null);
  const [nonProductionEnvironments, setNonProductionEnvironments] = useState<NonProductionEnvironmentsEntity[]>(
    formik.values.linksAndTags?.nonProductionEnvironments ? formik.values.linksAndTags?.nonProductionEnvironments : []
  );
  const [selectedNonProductionEnvironments, setSelectedNonProductionEnvironments] = useState<NonProductionEnvironmentsEntity[]>([]);

  const removeSelectedNonProductionEnvironment = () => {
    const finalRow = nonProductionEnvironments.filter((obj) => !selectedNonProductionEnvironments.includes(obj));
    setNonProductionEnvironments(finalRow);
    formik.setFieldValue('linksAndTags.nonProductionEnvironments', finalRow);
    setSelectedNonProductionEnvironments([]);
  };

  const onConfirm = (showModalInfo: ShowModalInfo) => {
    switch (showModalInfo.eventContentType) {
      case 'DELETE_NON_PROD':
        removeSelectedNonProductionEnvironment();
        closeModal();
        break;
      default:
        break;
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: ''
    });
  };

  const clearNewNonProdDetails = () => {
    setSelectedNonProductionEnvironments([]);
  };

  const addNewNonProductionEnvironment = (nonProductionEnvironmentsEntity: NonProductionEnvironmentsEntity) => {
    const finalRows = nonProductionEnvironments.concat([nonProductionEnvironmentsEntity]);
    setNonProductionEnvironments(finalRows);
    formik.setFieldValue('linksAndTags.nonProductionEnvironments', finalRows);
  };

  const updateNonProductionEnvironment = (updatedEnvironment: NonProductionEnvironmentsEntity) => {
    const finalRows = nonProductionEnvironments.map((obj) => {
      if (obj.id === updatedEnvironment.id) {
        return updatedEnvironment;
      }
      return obj;
    });
    setNonProductionEnvironments(finalRows);
    formik.setFieldValue('linksAndTags.nonProductionEnvironments', finalRows);
  };

  const editSelectedNonProdEnvironment = () => {
    if (childRef.current) {
      childRef.current.editSelectedNonProd(selectedNonProductionEnvironments[0]);
      setSelectedNonProductionEnvironments([]);
    }
  };

  return (
    <>
      <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />
      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              description="Include non-Prod environment links to be accessed either by general users or admins only"
              actions={
                <>
                  <Checkbox
                    onChange={({ detail }) => {
                      formik.setFieldValue('linksAndTags.hasNonProductionEnvironments', detail.checked);
                      clearNewNonProdDetails();
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('linksAndTags.hasNonProductionEnvironments', true);
                    }}
                    checked={formik.values.linksAndTags?.hasNonProductionEnvironments || false}
                  >
                    Enable
                  </Checkbox>
                </>
              }
            >
              Non-production environments
            </Header>
          }
        >
          {formik.values.linksAndTags?.hasNonProductionEnvironments && (
            <SpaceBetween size="s" direction="vertical">
              <NonProductionEnvironmentForm
                ref={childRef}
                hasNonProductionEnvironments={formik.values.linksAndTags.hasNonProductionEnvironments || false}
                addNewNonProductionEnvironment={addNewNonProductionEnvironment}
                updateNonProductionEnvironment={updateNonProductionEnvironment}
              />
              <Table
                variant="embedded"
                header={
                  <Header
                    description={
                      formik.touched.linksAndTags?.nonProductionEnvironments &&
                      formik.errors.linksAndTags?.nonProductionEnvironments && (
                        <StatusIndicator type="error">
                          {formik.touched.linksAndTags?.nonProductionEnvironments &&
                            removeQuotesFromString(JSON.stringify(formik.errors.linksAndTags?.nonProductionEnvironments))}
                        </StatusIndicator>
                      )
                    }
                    actions={
                      <>
                        <SpaceBetween size="m" direction="horizontal">
                          <Button
                            formAction="none"
                            disabled={selectedNonProductionEnvironments?.length !== 1 || !formik.values.linksAndTags?.hasNonProductionEnvironments}
                            onClick={() => {
                              editSelectedNonProdEnvironment();
                            }}
                          >
                            {`Edit`}
                          </Button>
                          <Button
                            formAction="none"
                            disabled={selectedNonProductionEnvironments?.length === 0 || !formik.values.linksAndTags?.hasNonProductionEnvironments}
                            onClick={() => {
                              setShowModalInfo({
                                showModal: true,
                                eventHeader: 'Delete non-production environments',
                                eventContentType: 'DELETE_NON_PROD'
                              });
                            }}
                          >
                            {`Delete (${selectedNonProductionEnvironments?.length}) selected`}
                          </Button>
                        </SpaceBetween>
                      </>
                    }
                  ></Header>
                }
                empty={<EmptyData />}
                columnDefinitions={APP_ENV_COLUMN_DEFINITIONS}
                items={nonProductionEnvironments}
                selectionType={formik.values.linksAndTags?.hasNonProductionEnvironments ? 'multi' : undefined}
                selectedItems={selectedNonProductionEnvironments}
                onSelectionChange={({ detail }) => setSelectedNonProductionEnvironments(detail.selectedItems)}
              />
            </SpaceBetween>
          )}
        </Container>
      </Box>

      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              description="Add or update existing tags by making relevant selections to each section below (tags are optional and help refine user search)"
            >
              Application tags
            </Header>
          }
        >
          <SpaceBetween size="m" direction="vertical">
            <FormField
              label={
                <>
                  Lowest Time Grain<i> - optional</i>
                </>
              }
              description=""
              // @ts-ignore
              errorText={formik.touched.linksAndTags?.timeGranularity && formik.errors.linksAndTags?.timeGranularity?.label}
            >
              <Multiselect
                placeholder="Choose options"
                selectedOptions={labelInputToMultiSelectDropdown(formik.values.linksAndTags?.timeGranularity)}
                onChange={({ detail }) => {
                  formik.setFieldValue('linksAndTags.timeGranularity', multiSelectDropdownToLabel(detail, 'TimeGranularity'));
                }}
                onBlur={() => {
                  formik.setFieldTouched('linksAndTags.timeGranularity', true);
                }}
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={labelInputToLabelDropdownOptions(appContext.listApplicationDropdowns?.listTimeGranularity)}
                filteringType="auto"
                selectedAriaLabel="Selected"
              />
            </FormField>

            <FormField
              label={
                <>
                  Key Dimension<i> - optional</i>
                </>
              }
              description=""
              // @ts-ignore
              errorText={formik.touched.linksAndTags?.keyDimension && formik.errors.linksAndTags?.keyDimension?.label}
            >
              <Multiselect
                placeholder="Choose options"
                selectedOptions={labelInputToMultiSelectDropdown(formik.values.linksAndTags?.keyDimension)}
                onChange={({ detail }) => {
                  formik.setFieldValue('linksAndTags.keyDimension', multiSelectDropdownToLabel(detail, 'KeyDimension'));
                }}
                onBlur={() => {
                  formik.setFieldTouched('linksAndTags.keyDimension', true);
                }}
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={labelInputToLabelDropdownOptions(appContext.listApplicationDropdowns?.listKeyDimension)}
                filteringType="auto"
                selectedAriaLabel="Selected"
              />
            </FormField>

            <FormField
              label={
                <>
                  Last Enhanced<i> - optional</i>
                </>
              }
              description=""
              // @ts-ignore
              errorText={formik.touched.linksAndTags?.lastEnhanced && formik.errors.linksAndTags?.lastEnhanced?.label}
            >
              <Multiselect
                placeholder="Choose options"
                selectedOptions={labelInputToMultiSelectDropdown(formik.values.linksAndTags?.lastEnhanced)}
                onChange={({ detail }) => {
                  formik.setFieldValue('linksAndTags.lastEnhanced', multiSelectDropdownToLabel(detail, 'LastEnhanced'));
                }}
                onBlur={() => {
                  formik.setFieldTouched('linksAndTags.lastEnhanced', true);
                }}
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={labelInputToLabelDropdownOptions(appContext.listApplicationDropdowns?.listLastEnhanced)}
                filteringType="auto"
                selectedAriaLabel="Selected"
              />
            </FormField>

            <FormField
              label={
                <>
                  Finance Cycle<i> - optional</i>
                </>
              }
              description=""
              // @ts-ignore
              errorText={formik.touched.linksAndTags?.process && formik.errors.linksAndTags?.process?.label}
            >
              <Multiselect
                placeholder="Choose options"
                selectedOptions={labelInputToMultiSelectDropdown(formik.values.linksAndTags?.process)}
                onChange={({ detail }) => {
                  formik.setFieldValue('linksAndTags.process', multiSelectDropdownToLabel(detail, 'Process'));
                }}
                onBlur={() => {
                  formik.setFieldTouched('linksAndTags.process', true);
                }}
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={labelInputToLabelDropdownOptions(appContext.listApplicationDropdowns?.listProcess)}
                filteringType="auto"
                selectedAriaLabel="Selected"
              />
            </FormField>

            <FormField
              label={
                <>
                  Scenario<i> - optional</i>
                </>
              }
              description=""
              // @ts-ignore
              errorText={formik.touched.linksAndTags?.scenario && formik.errors.linksAndTags?.scenario?.label}
            >
              <Multiselect
                placeholder="Choose options"
                selectedOptions={labelInputToMultiSelectDropdown(formik.values.linksAndTags?.scenario)}
                onChange={({ detail }) => {
                  formik.setFieldValue('linksAndTags.scenario', multiSelectDropdownToLabel(detail, 'Scenario'));
                }}
                onBlur={() => {
                  formik.setFieldTouched('linksAndTags.scenario', true);
                }}
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={labelInputToLabelDropdownOptions(appContext.listApplicationDropdowns?.listScenario)}
                filteringType="auto"
                selectedAriaLabel="Selected"
              />
            </FormField>
          </SpaceBetween>
        </Container>
      </Box>
    </>
  );
};
export default LinksAndTags;
