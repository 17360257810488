import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  AppLayout,
  BreadcrumbGroupProps,
  Button,
  Flashbar,
  FlashbarProps,
  Header,
  Pagination,
  PropertyFilter,
  PropertyFilterProps,
  SpaceBetween,
  Table,
  TableProps
} from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Application, ApplicationFlatTableEntity, AppNavigationState } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { getFilteringProperties, getUniqueApplicationFilteringOptionsForAllApps } from 'src/components/context/AppContextUtils';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { ConfirmationModal, ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import {
  BLANK_SEARCH_AND,
  Preferences,
  TableEmptyState,
  TableNoMatchState,
  getMatchesCountText
} from 'src/components/generic-components/TableCommons';
import { eFinSuiteAdminMessages, eUserPreferenceKeys } from 'src/constants/AppConstants';
import * as mutations from 'src/graphql/mutations';
import { useColumnWidths } from 'src/hooks/useColumnWidths';
import { usePersistedState } from 'src/hooks/useLocalStorage';
import { appLayoutAriaLabels, paginationAriaLabels, propertyFilterI18nStrings } from 'src/i18n-strings';
import { logger } from 'src/logger';
import { getCurrentUTCTimeZoneInISO } from 'src/utilities/DateTimeUtilities';
import { AdminSideNavigation } from '../AdminSideNavigation';
import {
  APPLICATIONS_DEFAULT_PREFERENCES,
  APPLICATION_COLUMN_DEFINITIONS,
  DEFAULT_VISIBLE_CONTENT_OPTIONS,
  serverSideErrorsStore
} from './ApplicationConfig';
import { SHORT_DESCRIPTION_ERROR_MESSAGE, shortDescriptionRegexPattern } from './admin-manage-application/StepsValidations';
import { fetchAllApplications } from 'src/api/app-sync-services';
import { FinSuiteAppMessages } from '../AdminMessageConstants';
import { AdminBaseBreadcrumbs } from '../AdminHomePageConfig';

const getBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => {
  return [
    ...AdminBaseBreadcrumbs,
    {
      text: 'All applications',
      href: '/admin/applications'
    }
  ];
};

export const Applications: React.FC = () => {
  const appContext = useAppContext();
  const userAuthData = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationStateMessage = location.state as AppNavigationState;

  // Add useEffect to handle navigation state message and clear state after displaying
  useEffect(() => {
    if (navigationStateMessage?.message) {
      displayApplicationFlashMessage(
        navigationStateMessage.message.header,
        navigationStateMessage.message.content,
        navigationStateMessage.message.type
      );
      // Clear the state after displaying the message
      window.history.replaceState({}, document.title);
    }
  }, [navigationStateMessage]);

  const appLayout = useRef<any>();
  const [flashMessages, setFlashMessages] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  const [filteringProperties, setFilteringProperties] = useState<PropertyFilterProps.FilteringProperty[]>(getFilteringProperties(true));
  const [filteringOptions, setFilteringOptions] = useState<PropertyFilterProps.FilteringOption[]>([]);

  const [appColDef, saveWidths] = useColumnWidths(eUserPreferenceKeys.ADMIN_APPLICATIONS_TABLE_WIDTHS, APPLICATION_COLUMN_DEFINITIONS);

  const [preferences, setPreferences] = usePersistedState(eUserPreferenceKeys.ADMIN_APPLICATIONS_TABLE_PREFERENCES, APPLICATIONS_DEFAULT_PREFERENCES);
  const [query, setQuery] = useState(BLANK_SEARCH_AND);

  const [selectedApplicationIds, setSelectedApplicationIds] = useState<string[]>([]);

  useEffect(() => {
    actions.setPropertyFiltering(query);
  }, [query]);

  useEffect(() => {
    if (appContext.parsedApplications.length > 0) {
      const uniqueObjects = getUniqueApplicationFilteringOptionsForAllApps(appContext.parsedApplications);
      setFilteringOptions(uniqueObjects);
    }
  }, [appContext.parsedApplications]);

  const displayApplicationFlashMessage = (header: string, content: string, flashBarType: FlashbarProps.Type) => {
    const newFlashMessage = {
      type: flashBarType,
      header: header,
      content: content,
      dismissible: true,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setFlashMessages([])
    };
    setFlashMessages([newFlashMessage]);
  };

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
    appContext?.parsedApplications,
    {
      propertyFiltering: {
        filteringProperties,
        empty: <TableEmptyState resourceName="Applications" />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'and' });
            }}
          />
        )
      },
      pagination: {
        pageSize: preferences.pageSize
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: 'applicationOwner',
            sortingComparator: (a: ApplicationFlatTableEntity, b: ApplicationFlatTableEntity) => {
              return a.applicationOwner.localeCompare(b.applicationOwner);
            }
          },
          isDescending: false
        }
      }
    }
  );

  const selectionChanged = (selectedItems: ApplicationFlatTableEntity[]) => {
    const filteredIds = appContext.listOfApplications
      .filter((application) => selectedItems.map((selectedApplication) => selectedApplication.id).includes(application.id))
      .map((item) => item.id);
    setSelectedApplicationIds(filteredIds);
  };

  const deleteApplication = async (applicationId: string[]) => {
    try {
      const applicationsToDeletePermanently = appContext.listOfApplications.filter((app) => applicationId.includes(app.id));
      const deleteApplicationResponse: any = await API.graphql({
        query: mutations.deleteApplication,
        variables: { input: applicationsToDeletePermanently }
      });
      logger.info(`Successfully deleted the application.`, { info: deleteApplicationResponse?.data?.deleteApplication });
      return deleteApplicationResponse?.data?.deleteApplication;
    } catch (error: any) {
      logger.error(`Unable to delete application.`, error);
      return null;
    }
  };

  const deleteSelectedApplications = () => {
    setShowModalInfo({
      showModal: true,
      eventHeader: 'Delete application',
      eventContentType: 'DELETE_APPLICATION',
      messageInfo: {
        applicationName: appContext.listOfApplications.find((app) => app.id === selectedApplicationIds[0])?.applicationName
      }
    });
  };

  const onConfirm = async (showModalInfo: ShowModalInfo) => {
    if (showModalInfo.eventContentType === 'DELETE_APPLICATION') {
      if (selectedApplicationIds) {
        const response = await deleteApplication(selectedApplicationIds);
        if (response) {
          const fetchAllApplicationsResponse = await fetchAllApplications(appContext.teamPageContent);
          appContext.setListOfApplications(fetchAllApplicationsResponse.allApplications);
          appContext.setParsedApplications(fetchAllApplicationsResponse.applicationFlatTableEntity);
          displayApplicationFlashMessage('', FinSuiteAppMessages.SUCCESSFULLY_DELETES_APP, 'success');
        } else {
          displayApplicationFlashMessage('', FinSuiteAppMessages.UNSUCCESSFULLY_DELETES_APP, 'error');
        }
        closeModal();
      }
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: ''
    });
  };

  const updateApplication = async (inputForCreateApplication: Application) => {
    try {
      const updateApplicationResponse: any = await API.graphql({
        query: mutations.updateApplication,
        variables: { input: inputForCreateApplication }
      });
      logger.info(`Successfully updated the application.`, { info: updateApplicationResponse?.data?.updateApplication });
      return updateApplicationResponse?.data?.updateApplication;
    } catch (error: any) {
      logger.error(`Unable to update application.`, error);
      return null;
    }
  };

  const handleTableInlineEdit = async (
    currentItem: ApplicationFlatTableEntity,
    column: TableProps.ColumnDefinition<ApplicationFlatTableEntity>,
    value: any
  ) => {
    if (column.id === 'shortApplicationDescription' && !shortDescriptionRegexPattern.test(value)) {
      serverSideErrorsStore.set(currentItem, SHORT_DESCRIPTION_ERROR_MESSAGE);
      throw new Error('Inline error');
    }

    if (column.id === 'status' || column.id === 'shortApplicationDescription') {
      const selectedApplication = appContext.listOfApplications.find((application) => application.id === currentItem.id);
      if (selectedApplication) {
        const response = await updateApplication({
          ...selectedApplication,
          shortApplicationDescription: column.id === 'shortApplicationDescription' ? value : selectedApplication.shortApplicationDescription,
          itemMetadata: {
            ...selectedApplication.itemMetadata,
            isActive: column.id === 'status' ? value === 'Active' : selectedApplication.itemMetadata.isActive,
            updatedBy: userAuthData.Alias,
            updatedTime: getCurrentUTCTimeZoneInISO()
          }
        });

        let displayMessage = '';
        let displayMessageHeader = '';
        if (response) {
          if (column.id === 'status') {
            displayMessageHeader =
              value === 'Active'
                ? `${selectedApplication.applicationName} ${eFinSuiteAdminMessages.APP_STATUS_ACTIVE_HEADER}`
                : `${selectedApplication.applicationName} ${eFinSuiteAdminMessages.APP_STATUS_INACTIVE_HEADER}`;
            displayMessage =
              value === 'Active' ? eFinSuiteAdminMessages.APP_STATUS_ACTIVE_MESSAGE : eFinSuiteAdminMessages.APP_STATUS_INACTIVE_MESSAGE;
          } else {
            displayMessage = FinSuiteAppMessages.SUCCESSFULLY_UPDATES_NEW_APP(selectedApplication.applicationName!);
          }
          displayApplicationFlashMessage(displayMessageHeader, displayMessage, 'success');
          const fetchAllApplicationsResponse = await fetchAllApplications(appContext.teamPageContent);
          appContext.setListOfApplications(fetchAllApplicationsResponse.allApplications);
          appContext.setParsedApplications(fetchAllApplicationsResponse.applicationFlatTableEntity);
        } else {
          displayApplicationFlashMessage('', FinSuiteAppMessages.UNSUCCESSFULLY_UPDATES_NEW_APP, 'error');
        }
      }
    }
  };

  const disableEditButton = () => {
    if (selectedApplicationIds.length === 0) {
      return true;
    } else {
      // since it is a single selection, we can only edit the first selected application
      const selectedApplication = appContext.listOfApplications.find((application) => application.id === selectedApplicationIds[0]);
      return !selectedApplication?.itemMetadata.isActive;
    }
  };

  const adminApplicationTableHeader = () => {
    return (
      <Header
        actions={
          <SpaceBetween size="m" direction="horizontal">
            <Button
              variant="normal"
              onClick={() => {
                navigate('/admin/manage-applications/' + selectedApplicationIds[0]);
              }}
              iconName="edit"
              disabled={disableEditButton()}
            >
              {'Edit'}
            </Button>
            <Button variant="normal" iconName="remove" disabled={selectedApplicationIds.length === 0} onClick={() => deleteSelectedApplications()}>
              {'Delete'}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                navigate('/admin/manage-applications');
              }}
              iconName="add-plus"
            >
              {'New Application'}
            </Button>
          </SpaceBetween>
        }
      >
        {`Applications`}
      </Header>
    );
  };

  return (
    <>
      <AppLayout
        ref={appLayout}
        headerSelector="#h"
        ariaLabels={appLayoutAriaLabels}
        breadcrumbs={<DFPBreadcrumbs items={getBreadcrumbItems()} />}
        toolsHide={true}
        navigation={<AdminSideNavigation />}
        stickyNotifications
        notifications={<Flashbar items={flashMessages} />}
        contentType="table"
        content={
          <div className="app-layout-body">
            <>
              <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />
              <Table
                variant="full-page"
                stickyHeader={true}
                items={items}
                selectionType="single"
                selectedItems={appContext?.parsedApplications.filter((item) => selectedApplicationIds.includes(item.id))}
                onSelectionChange={({ detail }) => selectionChanged(detail.selectedItems)}
                columnDefinitions={appColDef as TableProps.ColumnDefinition<ApplicationFlatTableEntity>[]}
                onColumnWidthsChange={saveWidths as any}
                visibleColumns={preferences.visibleContent}
                resizableColumns={false}
                wrapLines={preferences.wrapLines}
                stripedRows={preferences.stripedRows}
                contentDensity={preferences.contentDensity}
                submitEdit={handleTableInlineEdit}
                header={adminApplicationTableHeader()}
                filter={
                  <PropertyFilter
                    i18nStrings={propertyFilterI18nStrings('Applications')}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    expandToViewport={true}
                    {...propertyFilterProps}
                    query={query}
                    onChange={(event) => {
                      setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH_AND : event.detail);
                    }}
                    filteringOptions={filteringOptions}
                    filteringProperties={filteringProperties}
                  />
                }
                {...collectionProps}
                pagination={<Pagination {...paginationProps} ariaLabels={paginationAriaLabels(paginationProps.pagesCount)} />}
                preferences={
                  <Preferences
                    preferences={preferences}
                    setPreferences={setPreferences}
                    visibleContentOptions={DEFAULT_VISIBLE_CONTENT_OPTIONS}
                    disablePageSizeOptions={false}
                    disableResizableColumnsOptions={true}
                  />
                }
              />
            </>
          </div>
        }
      />
    </>
  );
};
