import { Button, IconProps, SelectProps, TableProps } from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Application, AppNavigationState, UserAuthContext } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { eFinSuiteAdminMessages } from 'src/constants/AppConstants';
import * as mutations from 'src/graphql/mutations';
import { logger } from 'src/logger';
import { getCurrentUTCTimeZoneInISO } from 'src/utilities/DateTimeUtilities';
import { sanitizeHtml } from 'src/utilities/SanitizeHtml';
import {
  ApplicationWizard,
  NonProductionEnvironmentsEntity,
  ReportAndAdditionalIssuesEntity,
  TutorialContentDetailsEntity,
  WelcomeContentDetailsEntity
} from '../../AdminModels';
import { applicationDetailsSchema, homepageConfigurationSchema, linksAndTagsSchema } from './StepsValidations';
import { fetchAllApplications } from 'src/api/app-sync-services';
import { TeamPageResponse } from '../../teams-manage/TeamManagementModel';
import { FinSuiteAppMessages } from '../../AdminMessageConstants';
import { infoPanelPageConfigurationSchema } from '../../homepage-manage/HomePageManagementSchema';

export const INITIAL_STEP_INFO: ApplicationWizard = {
  id: null,
  applicationDetails: {
    applicationName: '',
    applicationLink: '',
    applicationIntroductionVideoUrl: '',
    applicationOwnerLabelInput: {
      id: '',
      label: '',
      labelType: 'ApplicationOwner'
    },
    shortApplicationDescription: '',
    fullApplicationDescription: '',
    glAccounts: {
      label: '',
      id: '',
      labelType: 'GLAccount'
    },
    toolUI: {
      label: '',
      id: '',
      labelType: 'ToolUI'
    }
  },
  linksAndTags: {
    hasNonProductionEnvironments: false,
    nonProductionEnvironments: [],
    timeGranularity: [],
    keyDimension: [],
    process: [],
    scenario: [],
    lastEnhanced: []
  },
  homePageConfigurations: {
    hasWelcomeContent: false,
    welcomeTitle: '',
    welcomeContentDetails: [],
    hasAnnouncements: false,
    hasTutorials: false,
    tutorialContentDetails: [],
    hasFeedback: false
  },
  infoPanelPageConfiguration: {
    sidePanelDetails: {
      keyContacts: {
        isEnabled: false,
        title: '',
        isExpandedByDefault: false,
        contentType: 'rich-text-editor',
        richTextContent: ''
      },
      officeHours: {
        isEnabled: false,
        title: '',
        isExpandedByDefault: false,
        contentType: 'rich-text-editor',
        richTextContent: ''
      },
      supportLinks: {
        id: crypto.randomUUID(),
        isEnabled: false,
        title: 'Support Links',
        isExpandedByDefault: false,
        contentType: 'table',
        richTextContent: null,
        tableContent: []
      },
      customSidePanelWidgets: []
    }
  },
  itemMetadata: {
    isActive: true,
    createdBy: '',
    createdTime: '',
    updatedBy: '',
    updatedTime: ''
  }
};

export const removeQuotesFromString = (str: string) => {
  try {
    if (typeof str === 'string') {
      if (str.startsWith('"') && str.endsWith('"')) {
        return str.slice(1, -1);
      }
      return str;
    }
  } catch (error) {
    console.error('Error occurred while removing quotes:', error);
  }

  return str;
};

export const useWizard = () => {
  const navigate = useNavigate();
  const userAuthDetails = useAuth();
  const appContext = useAppContext();
  const formRef = useRef<FormikProps<ApplicationWizard>>(null);

  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const [stepError, setStepError] = useState('');
  const [action, setAction] = useState<'Create' | 'Update'>('Create');

  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  const [fromUrl, setFromUrl] = React.useState('');

  const setActiveStepIndexAndCloseTools = (index: any) => {
    setActiveStepIndex(index);
  };

  const onNavigate = async (evt: any, activeStepIndex: number, shouldSkipAppNameUniquenessValidation: boolean) => {
    const requestedStepIndex = evt.detail.requestedStepIndex;
    const directionReason = evt.detail.reason;

    const existingApplicationNames = appContext?.listOfApplications?.map((application) => application.applicationName || '');
    const appDetailsAreValid = await applicationDetailsSchema(existingApplicationNames, shouldSkipAppNameUniquenessValidation).isValid(
      formRef.current?.values?.applicationDetails
    );
    const linksAndTagsAreValid = await linksAndTagsSchema.isValid(formRef.current?.values?.linksAndTags);
    const configHomepageAreValid = await homepageConfigurationSchema.isValid(formRef.current?.values?.homePageConfigurations);
    const infoPanelDetailsAreValid = await infoPanelPageConfigurationSchema.isValid(formRef.current?.values?.infoPanelPageConfiguration);

    if (activeStepIndex === 0) {
      if (appDetailsAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        setApplicationDetailsAsTouched();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }

    if (activeStepIndex === 1) {
      if (linksAndTagsAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        setLinksAndTagsAsTouched();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }

    if (activeStepIndex === 2) {
      if (configHomepageAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        setConfigureHomePageAsTouched();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }

    if (directionReason === 'previous' && requestedStepIndex === 2) {
      if (infoPanelDetailsAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        setInfoPanelDetailsAsTouched();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: ''
    });
  };

  const onWizardCancel = () => {
    setShowModalInfo({
      showModal: true,
      eventHeader: 'Cancel',
      eventContentType: 'CANCEL_APPLICATION',
      messageInfo: {
        applicationName: formRef.current?.values.applicationDetails.applicationName
      }
    });
  };

  const navigateBackToFrom = (message: string) => {
    const applicationName = formRef.current?.values.applicationDetails.applicationName || '';
    const navigationStateMessage: AppNavigationState =
      message === 'success'
        ? {
            message: {
              type: 'success',
              content: FinSuiteAppMessages.SUCCESSFULLY_UPDATES_NEW_APP(applicationName),
              header: ''
            }
          }
        : {};

    const navigatePath = fromUrl || '/admin/applications';
    navigate(navigatePath, { state: navigationStateMessage });
  };

  const onConfirm = async (showModalInfo: ShowModalInfo) => {
    showModalInfo.eventContentType === 'CANCEL_APPLICATION' && navigateBackToFrom('cancel');
  };

  const onSubmit = async () => {
    // Last Step validation happens while Submit
    const infoPanelDetailsPageAreValid = await infoPanelPageConfigurationSchema.isValid(formRef.current?.values?.infoPanelPageConfiguration);
    if (!infoPanelDetailsPageAreValid) {
      setInfoPanelDetailsAsTouched();
      setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      return;
    }

    formRef.current
      ?.validateForm()
      .then(async () => {
        if (formRef.current?.isValid) {
          setStepError('');
          const application = wizardToAPI(formRef.current?.values, userAuthDetails, action);
          const mutation = action === 'Create' ? createApplication(application) : updateApplication(application);
          mutation
            .then(async (response) => {
              logger.info(`Successfully ${action}d Application.`, { info: response });
              const fetchAllApplicationsResponse = await fetchAllApplications(appContext?.teamPageContent);
              appContext.setListOfApplications(fetchAllApplicationsResponse.allApplications);
              appContext.setParsedApplications(fetchAllApplicationsResponse.applicationFlatTableEntity);

              const applicationFlashMessage =
                action === 'Create'
                  ? FinSuiteAppMessages.SUCCESSFULLY_ADDS_NEW_APP(application.applicationName!)
                  : FinSuiteAppMessages.SUCCESSFULLY_UPDATES_NEW_APP(application.applicationName!);
              appContext.displayApplicationFlashMessage(applicationFlashMessage, 'success');

              navigateBackToFrom('success');
            })
            .catch((error: any) => {
              const applicationFlashMessage =
                action === 'Create' ? FinSuiteAppMessages.UNSUCCESSFULLY_ADDS_NEW_APP : FinSuiteAppMessages.UNSUCCESSFULLY_UPDATES_NEW_APP;
              setStepError(applicationFlashMessage);
              logger.error(`Unable to submit ${action} Application request.`, error);
            });
        } else {
          setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
          setApplicationDetailsAsTouched();
          setLinksAndTagsAsTouched();
          setConfigureHomePageAsTouched();
        }
      })
      .catch((error: any) => {
        const errorMessage =
          action === 'Create' ? FinSuiteAppMessages.UNSUCCESSFULLY_ADDS_NEW_APP : FinSuiteAppMessages.UNSUCCESSFULLY_UPDATES_NEW_APP;
        setStepError(errorMessage);
        logger.error(`Unable to submit ${action} Application request.`, error);
      });
  };

  const setApplicationDetailsAsTouched = () => {
    formRef.current?.setTouched({
      applicationDetails: {
        applicationName: true,
        applicationLink: true,
        applicationOwnerLabelInput: {
          id: true,
          label: true,
          labelType: true
        },
        shortApplicationDescription: true,
        fullApplicationDescription: true,
        glAccounts: { label: true },
        toolUI: { label: true }
      }
    });
  };

  const setLinksAndTagsAsTouched = () => {
    formRef.current?.setTouched({
      linksAndTags: {
        hasNonProductionEnvironments: true,
        nonProductionEnvironments: true,
        keyDimension: [{ label: true }],
        process: [{ label: true }],
        scenario: [{ label: true }],
        lastEnhanced: [{ label: true }],
        timeGranularity: [{ label: true }]
      }
    });
  };

  const setConfigureHomePageAsTouched = () => {
    formRef.current?.setTouched({
      homePageConfigurations: {
        hasWelcomeContent: true,
        welcomeTitle: true,
        welcomeContentDetails: true,
        hasAnnouncements: true,
        hasTutorials: true,
        tutorialContentDetails: true,
        hasFeedback: true
      }
    });
  };

  const setInfoPanelDetailsAsTouched = () => {
    formRef.current?.setTouched({
      infoPanelPageConfiguration: {
        sidePanelDetails: {
          keyContacts: {
            isEnabled: true,
            title: true,
            isExpandedByDefault: true,
            contentType: true,
            richTextContent: true
          },
          officeHours: {
            isEnabled: true,
            title: true,
            isExpandedByDefault: true,
            contentType: true,
            richTextContent: true
          },
          customSidePanelWidgets: true
        }
      }
    });
  };
  return {
    activeStepIndex,
    setActiveStepIndexAndCloseTools,
    stepError,
    setStepError,
    action,
    setAction,
    applicationFormRef: formRef,
    onNavigate,
    onWizardCancel,
    onSubmit,
    showModalInfo,
    onCancel,
    onConfirm,
    fromUrl,
    setFromUrl
  };
};

const createApplication = async (inputForCreateApplication: Application) => {
  const createApplicationResponse: any = await API.graphql({
    query: mutations.createApplication,
    variables: { input: inputForCreateApplication }
  });
  return createApplicationResponse?.data?.createApplication;
};

const updateApplication = async (inputForCreateApplication: Application) => {
  const createApplicationResponse: any = await API.graphql({
    query: mutations.updateApplication,
    variables: { input: inputForCreateApplication }
  });
  return createApplicationResponse?.data?.updateApplication;
};

export const wizardToAPI = (applicationWizard: ApplicationWizard, userDetails: UserAuthContext, action: 'Create' | 'Update'): Application => {
  return {
    id: applicationWizard.id || '',
    applicationName: applicationWizard.applicationDetails.applicationName,

    applicationOwnerId: applicationWizard.applicationDetails.applicationOwnerLabelInput.id,
    applicationLink: applicationWizard.applicationDetails.applicationLink,
    applicationIntroductionVideoUrl: applicationWizard.applicationDetails.applicationIntroductionVideoUrl,
    shortApplicationDescription: applicationWizard.applicationDetails.shortApplicationDescription,
    fullApplicationDescription: applicationWizard.applicationDetails.fullApplicationDescription,
    glAccounts: [applicationWizard.applicationDetails.glAccounts],
    toolUI: [applicationWizard.applicationDetails.toolUI],
    hasNonProductionEnvironments: applicationWizard.linksAndTags.hasNonProductionEnvironments,
    nonProductionEnvironments: applicationWizard.linksAndTags.nonProductionEnvironments?.map((nonProdEnv) => {
      return {
        ...nonProdEnv,
        accessLevel: [nonProdEnv.accessLevel]
      };
    }),
    timeGranularity: applicationWizard.linksAndTags.timeGranularity,
    keyDimension: applicationWizard.linksAndTags.keyDimension,
    process: applicationWizard.linksAndTags.process,
    scenario: applicationWizard.linksAndTags.scenario,
    lastEnhanced: applicationWizard.linksAndTags.lastEnhanced,
    hasWelcomeContent: applicationWizard.homePageConfigurations.hasWelcomeContent,
    welcomeTitle: applicationWizard.homePageConfigurations.welcomeTitle,
    welcomeContentDetails: applicationWizard.homePageConfigurations.welcomeContentDetails,
    hasAnnouncements: applicationWizard.homePageConfigurations.hasAnnouncements,
    hasTutorials: applicationWizard.homePageConfigurations.hasTutorials,
    tutorialContentDetails: applicationWizard.homePageConfigurations.tutorialContentDetails,
    hasFeedback: applicationWizard.homePageConfigurations.hasFeedback,
    sidePanelDetails: {
      ...applicationWizard.infoPanelPageConfiguration.sidePanelDetails,
      customSidePanelWidgets: applicationWizard.infoPanelPageConfiguration.sidePanelDetails.customSidePanelWidgets
        ? applicationWizard.infoPanelPageConfiguration.sidePanelDetails.customSidePanelWidgets
        : []
    },
    itemMetadata: {
      isActive: applicationWizard.itemMetadata.isActive,
      createdBy: action === 'Create' ? userDetails.Alias : applicationWizard.itemMetadata.createdBy,
      createdTime: action === 'Create' ? getCurrentUTCTimeZoneInISO() : applicationWizard.itemMetadata.createdTime,
      updatedBy: userDetails.Alias,
      updatedTime: getCurrentUTCTimeZoneInISO()
    }
  };
};

export const APIToWizard = (application: Application, teamContent: TeamPageResponse): ApplicationWizard => {
  const teamName = teamContent?.teams?.find((team) => team.id === application.applicationOwnerId)?.teamName || '';

  return {
    id: application.id,
    applicationDetails: {
      applicationName: application.applicationName || '',
      applicationOwnerLabelInput: {
        id: application.applicationOwnerId,
        label: teamName,
        labelType: 'ApplicationOwner'
      },
      applicationLink: application.applicationLink || '',
      applicationIntroductionVideoUrl: application.applicationIntroductionVideoUrl || '',
      shortApplicationDescription: application.shortApplicationDescription || '',
      fullApplicationDescription: application.fullApplicationDescription || '',
      glAccounts: application.glAccounts[0],
      toolUI: application.toolUI[0]
    },
    linksAndTags: {
      hasNonProductionEnvironments: application.hasNonProductionEnvironments,
      nonProductionEnvironments: application.nonProductionEnvironments?.map((nonProdEnv) => {
        return {
          ...nonProdEnv,
          accessLevel: nonProdEnv.accessLevel[0]
        };
      }),
      timeGranularity: application.timeGranularity || [],
      keyDimension: application.keyDimension || [],
      process: application.process || [],
      scenario: application.scenario || [],
      lastEnhanced: application.lastEnhanced || []
    },
    homePageConfigurations: {
      hasWelcomeContent: application.hasWelcomeContent || false,
      welcomeTitle: application.welcomeTitle || '',
      welcomeContentDetails: application.welcomeContentDetails || [],
      hasAnnouncements: application.hasAnnouncements || false,
      hasTutorials: application.hasTutorials || false,
      tutorialContentDetails: application.tutorialContentDetails || [],
      hasFeedback: application.hasFeedback || false
    },
    infoPanelPageConfiguration: {
      sidePanelDetails: {
        ...application.sidePanelDetails,
        customSidePanelWidgets: application.sidePanelDetails.customSidePanelWidgets || []
      }
    },
    itemMetadata: {
      isActive: application.itemMetadata.isActive || false,
      createdBy: application.itemMetadata.createdBy || '',
      createdTime: application.itemMetadata.createdTime,
      updatedBy: application.itemMetadata.updatedBy || '',
      updatedTime: application.itemMetadata.updatedTime
    }
  };
};

// Table definitions
// Non-production environments
export const APP_ENV_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<NonProductionEnvironmentsEntity>[] = [
  {
    id: 'accessLevel',
    header: 'Access level',
    width: 150,
    minWidth: 150,
    cell: (item) => (
      <Button formAction="none" iconName={getAccessLevelIcon(item.accessLevel.label)} variant="link">
        {item.accessLevel.label}
      </Button>
    )
  },
  {
    id: 'displayText',
    header: 'Display name',
    width: 250,
    cell: (item) => item.displayText
  },
  {
    id: 'link',
    header: 'Link',
    cell: (item) => item.link
  }
];

// Welcome content table
export const APP_WELCOME_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<WelcomeContentDetailsEntity>[] = [
  {
    id: 'displayText',
    header: 'Display name',
    cell: (item) => item.displayText,
    minWidth: 150
  },
  {
    id: 'link',
    header: 'Link',
    cell: (item) => item.link,
    minWidth: 150,
    maxWidth: 200
  },
  {
    id: 'pointOfContact',
    header: 'Point Of Contact',
    cell: (item) => item.pointOfContact || '',
    maxWidth: 170
  },
  {
    id: 'description',
    header: 'Description',
    cell: (item) => (
      <div className="description-cell">
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(item.description || '')
          }}
        />
      </div>
    ),
    width: 300
  }
];

// Tutorial content table
export const APP_TUTORIAL_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<TutorialContentDetailsEntity>[] = [
  {
    id: 'position',
    header: 'Position',
    cell: (item) => item.position,
    width: 80
  },
  {
    id: 'category',
    header: 'Category',
    cell: (item) => item.category
  },
  {
    id: 'displayTitle',
    header: 'Display title',
    cell: (item) => item.displayTitle
  },
  {
    id: 'link',
    header: 'Link',
    cell: (item) => item.link
  }
];

export const APP_REPORT_ISSUES_ADDITIONAL_RESOURCES_DEFINITION: TableProps.ColumnDefinition<ReportAndAdditionalIssuesEntity>[] = [
  {
    id: 'displayName',
    header: 'Display Name',
    cell: (item) => item.displayName
  },
  {
    id: 'link',
    header: 'Link',
    cell: (item) => item.link
  }
];

export const getAccessLevelIcon = (accessLevel: string | undefined | null): IconProps.Name | undefined => {
  let accessLevelIconName: IconProps.Name | undefined = undefined;
  switch (accessLevel) {
    case 'Public':
      accessLevelIconName = 'unlocked';
      break;
    case 'Admin':
      accessLevelIconName = 'lock-private';
      break;
    default:
      accessLevelIconName = undefined;
      break;
  }
  return accessLevelIconName;
};
