import { Box, Checkbox, Container, Flashbar, FormField, Header, Input, SpaceBetween, StatusIndicator, Tabs } from '@amzn/awsui-components-react';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { ConfirmationModal, ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { characterCountConstraintMessage } from 'src/utilities/CommonUtilities';
import { ApplicationWizard, TutorialContentDetailsEntity, WelcomeContentDetailsEntity } from '../../AdminModels';
import { removeQuotesFromString } from './StepsUtilities';
import { MAX_TUTORIAL_LINKS, WELCOME_TITLE_MAX_LENGTH } from './StepsValidations';
import TutorialContentForm, { TutorialContentFormMethods } from './TutorialContentForm';
import { TutorialsContentTable } from './TutorialsContentTable';
import WelcomeLinksContentForm, { WelcomeLinksContentFormMethods } from './WelcomeLinksContentForm';
import { WelcomeContentTable } from './WelcomeWidgetTable';

interface HomepageConfigurationProps {
  formik: FormikProps<ApplicationWizard>;
}

const HomepageConfiguration: React.FC<HomepageConfigurationProps> = ({ formik }) => {
  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  const welcomeContentFormRef = useRef<WelcomeLinksContentFormMethods>(null);
  const [welcomeContentDetails, setWelcomeContentDetails] = useState<WelcomeContentDetailsEntity[]>(
    formik.values?.homePageConfigurations?.welcomeContentDetails || []
  );
  const [selectedWelcomeContentDetails, setSelectedWelcomeContentDetails] = useState<WelcomeContentDetailsEntity[]>([]);

  const addNewWelcomeContent = (nonProductionEnvironmentsEntity: WelcomeContentDetailsEntity) => {
    const newRow: WelcomeContentDetailsEntity[] = [nonProductionEnvironmentsEntity];
    const finalRows = welcomeContentDetails.concat(newRow);
    setWelcomeContentDetails(finalRows);
    formik.setFieldValue('homePageConfigurations.welcomeContentDetails', finalRows);
  };

  const removeSelectedWelcomeContent = () => {
    const finalRow = welcomeContentDetails.filter((obj) => !selectedWelcomeContentDetails.includes(obj));
    setWelcomeContentDetails(finalRow);
    formik.setFieldValue('homePageConfigurations.welcomeContentDetails', finalRow);
    setSelectedWelcomeContentDetails([]);
  };

  const updatedWelcomeContent = (welcomeContent: WelcomeContentDetailsEntity) => {
    const updatedRow = welcomeContentDetails.map((obj) => {
      if (obj.id === welcomeContent.id) {
        return welcomeContent;
      }
      return obj;
    });
    setWelcomeContentDetails(updatedRow);
    formik.setFieldValue('homePageConfigurations.welcomeContentDetails', updatedRow);
  };

  const clearNewWelcomeDetails = () => {
    setSelectedWelcomeContentDetails([]);
  };

  const editWelcomeContent = () => {
    if (welcomeContentFormRef.current) {
      welcomeContentFormRef.current.editSelectedRow(selectedWelcomeContentDetails[0]);
      setSelectedWelcomeContentDetails([]);
    }
  };

  // Announcements
  const [announcementItems, setAnnouncementItems] = React.useState<any>([
    {
      type: 'info',
      dismissible: false,
      dismissLabel: 'Dismiss message',
      content: <>{`Announcements widget has been enabled`}</>,
      id: 'message_1'
    }
  ]);

  // Tutorials
  const tutorialContentFormRef = useRef<TutorialContentFormMethods>(null);
  const [tutorialContentActiveTabId, setTutorialContentActiveTabId] = useState('first');

  const [tutorialContentDetails, setTutorialContentDetails] = useState<TutorialContentDetailsEntity[]>(
    formik.values?.homePageConfigurations?.tutorialContentDetails ? formik.values?.homePageConfigurations?.tutorialContentDetails : []
  );
  const [selectedTutorialContentDetails, setSelectedTutorialContentDetails] = useState<any[]>([]);

  const addNewTutorialContent = (addNewTutorialContent: TutorialContentDetailsEntity) => {
    if (tutorialContentDetails.length < MAX_TUTORIAL_LINKS) {
      const newRow: TutorialContentDetailsEntity[] = [addNewTutorialContent];
      const finalRows = tutorialContentDetails.concat(newRow);
      setTutorialContentDetails(finalRows);
      formik.setFieldValue('homePageConfigurations.tutorialContentDetails', finalRows);
    }
  };

  const removeSelectedTutorialContent = () => {
    const finalRow = tutorialContentDetails.filter((obj) => !selectedTutorialContentDetails.includes(obj));
    setTutorialContentDetails(finalRow);
    formik.setFieldValue('homePageConfigurations.tutorialContentDetails', finalRow);
    setSelectedTutorialContentDetails([]);
  };

  const clearNewTutorialDetails = () => {
    setSelectedTutorialContentDetails([]);
  };

  const editTutorialContent = () => {
    if (tutorialContentFormRef.current) {
      tutorialContentFormRef.current.editTutorialContentChanged(selectedTutorialContentDetails[0]);
      setSelectedTutorialContentDetails([]);
    }
  };

  const updateTutorialContent = (updatedTutorialContent: TutorialContentDetailsEntity) => {
    const finalRow = tutorialContentDetails.map((obj) => (obj.id === updatedTutorialContent.id ? updatedTutorialContent : obj));
    setTutorialContentDetails(finalRow);
    formik.setFieldValue('homePageConfigurations.tutorialContentDetails', finalRow);
  };

  // Feedback
  const [feedbackItems, setFeedbackItems] = React.useState<any>([
    {
      type: 'info',
      dismissible: false,
      dismissLabel: 'Dismiss message',
      content: <>{`Feedback widget has been enabled`}</>,
      id: 'message_1'
    }
  ]);

  const onConfirm = (showModalInfo: ShowModalInfo) => {
    switch (showModalInfo.eventContentType) {
      case 'DELETE_WELCOME_LINKS':
        removeSelectedWelcomeContent();
        closeModal();
        break;
      case 'DELETE_TUTORIAL_LINK':
        removeSelectedTutorialContent();
        closeModal();
        break;
      default:
        break;
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: ''
    });
  };

  const tableDescriptionForErrors = formik.touched.homePageConfigurations?.welcomeContentDetails &&
    formik.errors.homePageConfigurations?.welcomeContentDetails && (
      <StatusIndicator type="error">
        {formik.touched.homePageConfigurations?.welcomeContentDetails &&
          removeQuotesFromString(JSON.stringify(formik.errors.homePageConfigurations?.welcomeContentDetails))}
      </StatusIndicator>
    );

  return (
    <>
      <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />

      {/* Welcome widget  */}
      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              description="Provide users with key quick links (e.g., documentation, wikis) to help introduce your app"
              actions={
                <>
                  <Checkbox
                    onChange={({ detail }) => {
                      formik.setFieldValue('homePageConfigurations.hasWelcomeContent', detail.checked);
                      clearNewWelcomeDetails();
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('homePageConfigurations.hasWelcomeContent', true);
                    }}
                    checked={formik.values?.homePageConfigurations?.hasWelcomeContent || false}
                  >
                    Enable
                  </Checkbox>
                </>
              }
            >
              Welcome
            </Header>
          }
        >
          {(formik.values?.homePageConfigurations?.hasWelcomeContent || false) && (
            <>
              {/* Welcome content  */}
              <SpaceBetween size="s" direction="vertical">
                <Box padding={{ bottom: 'm' }}>
                  <FormField
                    constraintText={characterCountConstraintMessage(
                      WELCOME_TITLE_MAX_LENGTH,
                      formik.values.homePageConfigurations?.welcomeTitle?.length || 0
                    )}
                    errorText={formik.touched.homePageConfigurations?.welcomeTitle && formik.errors.homePageConfigurations?.welcomeTitle}
                    stretch={true}
                    label="Widget Title"
                  >
                    <Input
                      placeholder="Enter custom title"
                      onChange={({ detail }) => {
                        formik.setFieldValue('homePageConfigurations.welcomeTitle', detail.value);
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('homePageConfigurations.welcomeTitle', true);
                      }}
                      value={formik.values?.homePageConfigurations?.welcomeTitle || ''}
                    />
                  </FormField>
                </Box>

                <WelcomeLinksContentForm
                  ref={welcomeContentFormRef}
                  showWelcomeContentModal={false}
                  hasWelcomeContent={formik.values.homePageConfigurations.hasWelcomeContent || false}
                  addNewWelcomeContent={addNewWelcomeContent}
                  updateRow={updatedWelcomeContent}
                />

                <WelcomeContentTable
                  tableDescriptionForErrors={tableDescriptionForErrors}
                  editWelcomeContent={editWelcomeContent}
                  selectedWelcomeContentDetails={selectedWelcomeContentDetails}
                  deleteSelected={removeSelectedWelcomeContent}
                  welcomeContentDetails={welcomeContentDetails || []}
                  setSelectedWelcomeContentDetails={setSelectedWelcomeContentDetails}
                />
              </SpaceBetween>
            </>
          )}
        </Container>
      </Box>

      {/* Announcements  */}
      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              description="Display to users all announcements that apply to this app"
              actions={
                <>
                  <Checkbox
                    onChange={({ detail }) => {
                      formik.setFieldValue('homePageConfigurations.hasAnnouncements', detail.checked);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('homePageConfigurations.hasAnnouncements', true);
                    }}
                    checked={formik.values?.homePageConfigurations?.hasAnnouncements || false}
                  >
                    Enable
                  </Checkbox>
                </>
              }
            >
              Announcements
            </Header>
          }
        >
          {formik.values?.homePageConfigurations?.hasAnnouncements && <Flashbar items={announcementItems} />}
        </Container>
      </Box>

      {/* Tutorials  */}
      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              description={`Show tutorials or training videos for this app (8 links are showcased on the widget itself and the app’s tutorial page will display all links)`}
              actions={
                <>
                  <Checkbox
                    onChange={({ detail }) => {
                      formik.setFieldValue('homePageConfigurations.hasTutorials', detail.checked);
                      clearNewTutorialDetails();
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('homePageConfigurations.hasTutorials', true);
                    }}
                    checked={formik.values?.homePageConfigurations?.hasTutorials || false}
                  >
                    Enable
                  </Checkbox>
                </>
              }
            >
              Tutorials
            </Header>
          }
        >
          {formik.values?.homePageConfigurations?.hasTutorials && (
            <>
              {/* Tutorials content  */}
              <Tabs
                onChange={({ detail }) => setTutorialContentActiveTabId(detail.activeTabId)}
                activeTabId={tutorialContentActiveTabId}
                tabs={[
                  {
                    label: 'Content',
                    id: 'first',
                    content: (
                      <>
                        <SpaceBetween size="m" direction="vertical">
                          <TutorialContentForm
                            ref={tutorialContentFormRef}
                            hasTutorialContent={formik.values.homePageConfigurations.hasTutorials || false}
                            addNewTutorialContent={addNewTutorialContent}
                            updateTutorialContent={updateTutorialContent}
                            tutorialContentDetails={formik.values.homePageConfigurations.tutorialContentDetails || []}
                          />

                          <TutorialsContentTable
                            formik={formik}
                            selectedTutorialContentDetails={selectedTutorialContentDetails}
                            editTutorialContent={editTutorialContent}
                            setShowModalInfo={setShowModalInfo}
                            tutorialContentDetails={tutorialContentDetails}
                            setSelectedTutorialContentDetails={setSelectedTutorialContentDetails}
                          />
                        </SpaceBetween>
                      </>
                    )
                  },
                  {
                    label: 'Preview',
                    id: 'second',
                    content: (
                      <>
                        <div>Second tab content area</div>
                      </>
                    )
                  }
                ]}
              />
            </>
          )}
        </Container>
      </Box>

      {/* Feedback  */}
      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              className="limited-width-header"
              description="Collects experience ratings and feedback for this app"
              actions={
                <>
                  <Checkbox
                    onChange={({ detail }) => {
                      formik.setFieldValue('homePageConfigurations.hasFeedback', detail.checked);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('homePageConfigurations.hasFeedback', true);
                    }}
                    checked={formik.values?.homePageConfigurations?.hasFeedback || false}
                  >
                    Enable
                  </Checkbox>
                </>
              }
            >
              Feedback
            </Header>
          }
        >
          {formik.values?.homePageConfigurations?.hasFeedback && <Flashbar items={feedbackItems} />}
        </Container>
      </Box>
    </>
  );
};

export default HomepageConfiguration;
