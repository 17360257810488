import { ItemMetadata } from 'src/components/context/AppContextModels';
import { getCurrentUTCTimeZoneInISO } from './DateTimeUtilities';

export const createItemMetadata = (userAlias: string): ItemMetadata => {
  const currentTime = getCurrentUTCTimeZoneInISO();
  return {
    isActive: true,
    createdBy: userAlias,
    createdTime: currentTime,
    updatedBy: userAlias,
    updatedTime: currentTime
  };
};

export const updateItemMetadata = (userAlias: string, itemMetadata: ItemMetadata): ItemMetadata => {
  return {
    isActive: itemMetadata.isActive,
    createdBy: itemMetadata.createdBy,
    createdTime: itemMetadata.createdTime,
    updatedBy: userAlias,
    updatedTime: getCurrentUTCTimeZoneInISO()
  };
};
