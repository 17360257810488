/* eslint-disable prettier/prettier */
export const APP_TITLE = 'DaS FinSuite';

export const DEFAULT_LOCALE_EN_US = 'en-US';
export const TRANSLATIONS_DIR_NAMESPACE = 'translations';
export const TRANSLATION_ROUTE = `/${TRANSLATIONS_DIR_NAMESPACE}`;

export const eLDAPGroup = {
  DEV_LDAP: 'das-finsuite-application-dev',
  ADMIN_LDAP: 'das-finsuite-application-admins',
  OE_RO_LDAP: 'das-finsuite-application-oe-ro'
};

export const ADMIN_LDAP_GROUPS: string[] = [eLDAPGroup.DEV_LDAP, eLDAPGroup.ADMIN_LDAP];

// AWS RUM default casing convention.
// convention: element_action_name
export enum eRUM_CUSTOM_EVENT {
  USER_LOGGED_IN = 'user_logged_in'
}

export const PHONE_TOOL_URL = 'https://phonetool.amazon.com/users/';
export const TICKETS_TOOL_URL = 'https://t.corp.amazon.com/';
export const TICKETS_FOR_OE_ERROR_URL = 'https://t.corp.amazon.com/create/templates/161712b7-f28d-41b3-b6a2-f4110867978d';

export enum eTicketLinks {
  DAS_FINSUITE_GENERAL_ISSUE = 'https://t.corp.amazon.com/create/templates/b74ae449-480e-4ce3-9ddc-d89af3cf5dfe'
}

export enum eCacheKeys {
  UserAuthDetails = '-userAuthDetails'
}

export enum eLocalStorageKeys {
  IsAdminControlsEnabled = 'isAdminControlsEnabled',
  IsDarkModeEnabled = 'isDarkModeEnabled',
  DFP_HOME_PAGE_WIDGETS_LAYOUT = 'dfp-home-page-widgets-layout-v1',
  APP_SPECIFIC_PAGE_WIDGETS_LAYOUT = 'app-specific-widgets-layout'
}

export enum eUserPreferenceKeys {
  // All Apps
  ALL_APPS_TABLE_WIDTHS = 'All-Apps-Table-Widths-v1',
  ALL_APPS_TABLE_PREFERENCES = 'All-Apps-Table-Preferences-v1',
  // ALL_APPS_TABLE_FILTERING_PROPERTIES = "All-Apps-Table-Filtering-Properties",

  // General User Announcements
  ANNOUNCEMENTS_TABLE_WIDTHS = 'Announcements-Table-Widths',
  ANNOUNCEMENTS_TABLE_PREFERENCES = 'Announcements-Table-Preferences',
  // ANNOUNCEMENTS_TABLE_FILTERING_PROPERTIES = "Announcements-Filtering-Properties",

  // Admin - Applications
  ADMIN_APPLICATIONS_TABLE_WIDTHS = 'Admin-Applications-Table-Widths-v1',
  ADMIN_APPLICATIONS_TABLE_PREFERENCES = 'Admin-Applications-Table-Preferences-v1',
  // ADMIN_APPLICATIONS_TABLE_FILTERING_PROPERTIES = "Admin-Applications-Table-Filtering-Properties",

  // Admin - Announcements
  ADMIN_ANNOUNCEMENTS_TABLE_WIDTHS = 'Admin-Announcements-Table-Widths',
  ADMIN_ANNOUNCEMENTS_TABLE_PREFERENCES = 'Admin-Announcements-Table-Preferences',
  // ADMIN_ANNOUNCEMENTS_TABLE_FILTERING_PROPERTIES = "Admin-Announcements-Table-Filtering-Properties",

  // Admin - Feedback
  ADMIN_FEEDBACK_TABLE_WIDTHS = 'Admin-Feedback-Table-Widths',
  ADMIN_FEEDBACK_TABLE_PREFERENCES = 'Admin-Feedback-Table-Preferences'
  // ADMIN_FEEDBACK_TABLE_FILTERING_PROPERTIES = "Admin-Feedback-Table-Filtering-Properties"
}

// TODO - Yet to implement global flashbar notifications
export enum eFinSuiteMessages {
  NO_INFORMATION_WIDGETS = 'This app page is work-in-progress.  Please check back for updates!',
  NO_INFORMATION_INFO_PANEL = 'This app’s Help/Support panel is work-in-progress. Please check back for updates!',
  ADMIN_CONTROLS_ENABLED = 'Additional functionality for admins will now appear to the right of the page header when available. Any changes made using these controls will be seen by all users.',
  ADMIN_CONTROLS_DISABLED = 'Additional functionality for admins will now be hidden from the user interface.',
  DARK_MODE_ENABLED = 'To switch to Light mode, click on the moon icon and select Light mode.',
  DARK_MODE_DISABLED = 'To switch to Dark mode, click on the moon icon and select Dark mode.'
}

export enum eFinSuiteAdminMessages {
  REQUIRED_FIELD = 'Required field',
  INVALID_ALIAS = 'Invalid alias',
  VALID_URL = 'Enter a valid URL',
  ENTER_VALID_NUMBER = 'Enter a valid number',
  UNIQUE_POSITION_ERROR = 'Position must be unique',
  AT_LEAST_ONE_SELECTION = 'Select at least one option',
  AT_LEAST_ONE_RECORD = 'If this feature is enabled, add at least one record',
  UNIQUE_APP_NAME_ERROR = 'Application name already exists',
  NEXT_BUTTON_VALIDATION_ERROR = 'Complete all the required fields to continue',

  APP_STATUS_ACTIVE_HEADER = ' is now Active',
  APP_STATUS_ACTIVE_MESSAGE = 'Active applications will have their homepage and navigation references restored.',

  APP_STATUS_INACTIVE_HEADER = ' is now Inactive',
  APP_STATUS_INACTIVE_MESSAGE = 'Inactive applications will have their homepage archived and navigation references removed.',
}

export enum eFinSuiteAnnouncementMessages {
  ANNOUNCEMENT_SUCCESSFULLY_ADDED = 'Announcement has been added successfully.',
  ANNOUNCEMENT_CREATION_FAILED = 'An error occurred while submitting your new announcement. Try again or submit a ticket.',

  ANNOUNCEMENT_SUCCESSFULLY_UPDATED = 'Announcement has been updated successfully.',
  ANNOUNCEMENT_UPDATE_FAILED = 'An error occurred while submitting your changes to announcement. Try again or submit a ticket.',

  ANNOUNCEMENT_SUCCESSFULLY_DELETED = 'Announcement has been deleted successfully.',
  ANNOUNCEMENT_DELETION_FAILED = 'An error occurred while deleting announcement. Try again or submit a ticket.'
}

export enum eFinSuiteFeedbackMessages {
  FEEDBACK_SUCCESSFULLY_ADDED = 'Your feedback has successfully been submitted.',
  FEEDBACK_CREATION_FAILED = 'There was a problem submitting your feedback. Try again.',

  FEEDBACK_SUCCESSFULLY_DELETED = 'Feedback has been deleted successfully.',
  FEEDBACK_DELETION_FAILED = 'An error occurred while deleting feedback. Try again or submit a ticket.'
}
