import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import { ADMIN_LDAP_GROUPS } from '../../../constants/AppConstants';
import { useAuth } from '../../context/AuthContextProvider';
import { hasCommonElements } from 'src/utilities/CommonUtilities';

export interface SideNavigation {
  section: string;
  type: string;
  text: string;
  href: string;
  accessibleTo: string[];
}

export const NavigationItems: SideNavigation[] = [
  {
    section: 'Manage',
    type: 'link',
    text: 'Applications',
    href: '/admin/applications',
    accessibleTo: ADMIN_LDAP_GROUPS
  },
  {
    section: 'Manage',
    type: 'link',
    text: 'Announcements',
    href: '/admin/announcements',
    accessibleTo: ADMIN_LDAP_GROUPS
  },
  {
    section: 'Manage',
    type: 'link',
    text: 'Feedback',
    href: '/admin/feedbacks',
    accessibleTo: ADMIN_LDAP_GROUPS
  },
  {
    section: 'Manage',
    type: 'link',
    text: 'Homepage',
    href: '/admin/manage-homepage',
    accessibleTo: ADMIN_LDAP_GROUPS
  },
  {
    section: 'Manage',
    type: 'link',
    text: 'DaS FinTech Team',
    href: '/admin/manage-team-page',
    accessibleTo: ADMIN_LDAP_GROUPS
  }
];

const AdminNewApp: any = [
  {
    type: 'link',
    text: 'New application',
    href: '/admin/manage-applications',
    accessibleTo: ADMIN_LDAP_GROUPS
  }
];

export const AdminSideNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userAuthenticationDetails = useAuth();
  const [sideNavigationItems, setSideNavigationItems] = useState<SideNavigationProps.Item[]>([]);
  const [activeRef, setActiveRef] = useState<string>('');

  const configureSideNavigationItems = () => {
    const userLDAPGroups: string[] = userAuthenticationDetails.userLDAPGroups;
    const accessBasedNavigationItems = NavigationItems.filter((navigation) => hasCommonElements(navigation.accessibleTo, userLDAPGroups));
    const uniqueSections = accessBasedNavigationItems
      .filter((thing, i, arr) => arr.findIndex((t) => t.section === thing.section) === i)
      .map((navigation) => navigation.section);

    const navigationItems: SideNavigationProps.Item[] = uniqueSections.map((sectionName) => {
      return {
        type: 'section',
        text: sectionName,
        items: accessBasedNavigationItems
          .filter((navigationItem) => navigationItem.section === sectionName)
          .map((_navigation) => {
            return {
              type: _navigation.type,
              text: _navigation.text,
              href: _navigation.href
            } as SideNavigationProps.Item;
          })
      };
    });
    setSideNavigationItems(AdminNewApp.concat(navigationItems));
  };

  useEffect(() => {
    configureSideNavigationItems();
  }, []);

  useEffect(() => {
    setActiveRef(pathname);
  }, [pathname]);

  return (
    <SideNavigation
      header={{
        href: '/admin',
        text: 'Admin console'
      }}
      items={sideNavigationItems}
      activeHref={activeRef}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          navigate(event.detail.href);
        }
      }}
    />
  );
};
