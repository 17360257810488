import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth, Cache } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAmplifyAuthConfig, getAppSyncConfig, getRestApiConfig } from 'src/components/context/AuthContextUtils';
import { configureTheLogger } from 'src/logger';
import { eCacheKeys, eLDAPGroup } from '../../constants/AppConstants';
import { DasFinGenieApp } from '../das-finsuite/widgets/chat-bot-widget/das-fin-genie';
import { LoadingSpinner } from '../generic-components/LoadingSpinner';
import { LoadingStatus, UserAuthContext } from './AppContextModels';
import { RumClient } from 'src/services/aws/CloudWatchRumService';

const initialData: UserAuthContext = {
  userAuthDataLoadingStatus: LoadingStatus.Loading
} as UserAuthContext;
const AuthContextDetails = createContext(initialData);
export const useAuth = () => useContext(AuthContextDetails);

// Provider component that wraps your app and makes auth object
export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const userAuthData = useAuthProvider();

  if (userAuthData?.userAuthDataLoadingStatus === LoadingStatus.Loading) {
    return <LoadingSpinner />;
  }

  configureTheLogger(userAuthData);
  RumClient.initialize(userAuthData.Alias);

  return (
    <AuthContextDetails.Provider value={userAuthData}>
      <DasFinGenieApp />
      {children}
    </AuthContextDetails.Provider>
  );
};

const useAuthProvider = () => {
  const [userCognitoAuthData, setUserCognitoAuthData] = useState<UserAuthContext>({
    ...initialData,
    auth_status_message: 'Loading',
    userAuthDataLoadingStatus: LoadingStatus.Loading
  });

  useEffect(() => {
    authenticateAndSetUserDetails();
  }, []);

  const authenticateAndSetUserDetails = () => {
    Auth.configure(getAmplifyAuthConfig());

    const configureAppSync = async () => {
      try {
        const awsAppSync = await getAppSyncConfig();
        Amplify.configure(awsAppSync);

        Amplify.configure(getRestApiConfig());
      } catch (error: any) {
        // logger isn't configured at this point.
        console.error('Unable to fetch Config', error);
      }
    };

    const signInWithAmazonFederate = async () => {
      try {
        await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      } catch (error: any) {
        // logger isn't configured at this point.
        console.error('Unable to sign in with AmazonFederate', error);
        setUserCognitoAuthData({
          ...userCognitoAuthData,
          auth_status_message: 'Unable to sign in with AmazonFederate',
          userAuthDataLoadingStatus: LoadingStatus.Loading
        });
      }
    };

    Auth.currentAuthenticatedUser()
      .then(async () => {
        await configureAppSync();
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      })
      .catch(() => {
        signInWithAmazonFederate();
      });
  };

  /**
   * Retrieves and formats the LDAP groups from the credentials payload.
   * @param credentials The AWS credentials object containing the ID token payload.
   * @returns A properly formatted string array of LDAP groups.
   */
  const formatUserLDAPs = (credentials: CognitoUserSession): string[] => {
    // if user is not part of any ldap, we receive it as "[]"
    const ldapGroups = credentials.getIdToken().payload['custom:LDAP_GROUPS'];
    // Check if ldapGroups is a string and try to parse it as JSON
    if (typeof ldapGroups === 'string') {
      try {
        const parsedLdapGroups = JSON.parse(ldapGroups);
        return Array.isArray(parsedLdapGroups) ? parsedLdapGroups : [];
      } catch (error) {
        console.error('Failed to parse ldapGroups string: ', error);
        return [];
      }
    }

    // If ldapGroups is already an array, return it; otherwise, return an empty array
    return Array.isArray(ldapGroups) ? ldapGroups : [];
  };

  const getSessionDetails = (credentials: CognitoUserSession) => {
    if (Cache.getItem(eCacheKeys.UserAuthDetails)) {
      return Cache.getItem(eCacheKeys.UserAuthDetails) as UserAuthContext;
    }

    const userLDAPs: string[] = formatUserLDAPs(credentials);

    const sessionDetails = {
      Alias: credentials.getIdToken().payload['identities'][0].userId,
      DisplayName: credentials.getIdToken().payload['custom:DISPLAY_NAME'],
      GivenName: credentials.getIdToken().payload['custom:GIVEN_NAME'],
      Email: credentials.getIdToken().payload['custom:EMAIL'],
      userLDAPGroups: userLDAPs,
      isDev: userLDAPs.includes(eLDAPGroup.DEV_LDAP),
      isAdmin: userLDAPs.includes(eLDAPGroup.ADMIN_LDAP),
      isOEReadOnly: userLDAPs.includes(eLDAPGroup.OE_RO_LDAP),
      auth_status_message: 'SSO completed',
      userAuthDataLoadingStatus: LoadingStatus.Completed
    } as UserAuthContext;

    return sessionDetails;
  };

  return userCognitoAuthData;
};
