import { Box, Button, FormField, Input, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateHomePageContentMutation } from 'src/api/app-sync-services';
import { LoadingStatus } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { logger } from 'src/logger';
import { characterCountConstraintMessage } from 'src/utilities/CommonUtilities';
import { updateItemMetadata } from 'src/utilities/ItemMetadata';
import { FinSuiteAppMessages } from '../../AdminMessageConstants';
import { WelcomeContentDetailsEntity, WelcomeContentEntity } from '../../AdminModels';
import { getHomePageContentDefaultState, homepageNavigateBackToFrom } from '../../homepage-manage/HomePageManagementUtils';
import { HomepageEntity } from '../../homepage-manage/HomepageModel';
import { WELCOME_TITLE_MAX_LENGTH, welcomeContentSchema } from './StepsValidations';
import WelcomeLinksContentForm, { WelcomeLinksContentFormMethods } from './WelcomeLinksContentForm';
import { WelcomeContentTable } from './WelcomeWidgetTable';

interface WelcomeContentModalProps {
  showWelcomeContentModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const INITIAL_VALUES: WelcomeContentEntity = {
  welcomeTitle: 'Welcome',
  welcomeContentDetails: []
};

const STATUS_INDICATORS = {
  loading: <StatusIndicator type="loading">Saving changes...</StatusIndicator>,
  success: <StatusIndicator type="success">Changes saved successfully!</StatusIndicator>,
  error: <StatusIndicator type="error">Unable to process request. Please try again.</StatusIndicator>
};

export const WelcomeContentModal: React.FC<WelcomeContentModalProps> = ({ showWelcomeContentModal, onCancel, onConfirm }) => {
  const userAuth = useAuth();
  const appContext = useAppContext();
  const navigate = useNavigate();
  const welcomeContentFormRef = useRef<FormikProps<WelcomeContentEntity>>(null);
  const welcomeContentInlineFormRef = useRef<WelcomeLinksContentFormMethods>(null);

  const [welcomeContentDetails, setWelcomeContentDetails] = useState<WelcomeContentDetailsEntity[]>([]);
  const [selectedWelcomeContentDetails, setSelectedWelcomeContentDetails] = useState<WelcomeContentDetailsEntity[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<keyof typeof STATUS_INDICATORS | null>(null);

  useEffect(() => {
    if (appContext.contextLoadingStatus === LoadingStatus.Completed && showWelcomeContentModal) {
      setSubmitStatus(null);
      setSelectedWelcomeContentDetails([]);

      setWelcomeContentDetails(appContext?.homePageContent?.welcomeContentDetails || []);
      welcomeContentFormRef.current?.setFieldValue('welcomeTitle', appContext?.homePageContent?.welcomeTitle || 'Welcome');
      welcomeContentFormRef.current?.setFieldValue('welcomeContentDetails', appContext?.homePageContent?.welcomeContentDetails || []);
    }
  }, [appContext, showWelcomeContentModal]);

  const updateWelcomeContentDetails = (updatedDetails: WelcomeContentDetailsEntity[]) => {
    setWelcomeContentDetails(updatedDetails);
    welcomeContentFormRef.current?.setFieldValue('welcomeContentDetails', updatedDetails);
  };

  const handleAddNewWelcomeContent = (welcomeContentDetailsEntity: WelcomeContentDetailsEntity) => {
    updateWelcomeContentDetails([...welcomeContentDetails, welcomeContentDetailsEntity]);
  };

  const handleUpdateWelcomeContent = (welcomeContentDetailsEntity: WelcomeContentDetailsEntity) => {
    const updatedRow = welcomeContentDetails.map((obj) => (obj.id === welcomeContentDetailsEntity.id ? welcomeContentDetailsEntity : obj));
    updateWelcomeContentDetails(updatedRow);
  };

  const handleEditWelcomeContent = () => {
    if (welcomeContentInlineFormRef.current && selectedWelcomeContentDetails.length > 0) {
      welcomeContentInlineFormRef.current.editSelectedRow(selectedWelcomeContentDetails[0]);
      setSelectedWelcomeContentDetails([]);
    }
  };

  const deleteSelected = () => {
    const finalRow = welcomeContentDetails.filter((obj) => !selectedWelcomeContentDetails.includes(obj));
    updateWelcomeContentDetails(finalRow);
    setSelectedWelcomeContentDetails([]);
  };

  const handleSubmit = async (values: WelcomeContentEntity) => {
    setIsSubmitting(true);
    setSubmitStatus('loading');

    try {
      const updatedListGeneralContent = values;
      let homePageContentMutation: HomepageEntity = {} as HomepageEntity;

      if (appContext.homePageContent.id) {
        homePageContentMutation = {
          ...appContext.homePageContent,
          welcomeTitle: updatedListGeneralContent.welcomeTitle,
          welcomeContentDetails: updatedListGeneralContent.welcomeContentDetails,
          itemMetadata: updateItemMetadata(userAuth.Alias, appContext.homePageContent.itemMetadata)
        };
      } else {
        homePageContentMutation = {
          ...getHomePageContentDefaultState(userAuth.Alias),
          welcomeTitle: updatedListGeneralContent.welcomeTitle,
          welcomeContentDetails: updatedListGeneralContent.welcomeContentDetails
        };
      }

      await updateHomePageContentMutation(homePageContentMutation)
        .then((response: any) => {
          appContext.setHomePageContent(homePageContentMutation);
          logger.info(`Successfully updated Homepage content.`, { info: response });
          homepageNavigateBackToFrom('success', FinSuiteAppMessages.SUCCESSFULLY_UPDATES_HOME_PAGE_CONTENT, '/', navigate);
          setSubmitStatus('success');
          setTimeout(() => {
            setSubmitStatus(null);
            setSelectedWelcomeContentDetails([]);
            onConfirm();
          }, 100);
        })
        .catch(() => {
          logger.error(`Unable to updated Homepage content.`);
          setSubmitStatus('error');
        });
    } catch (error: any) {
      logger.error('Error updating content:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFooter = () => (
    <Box>
      <Box float="left">{submitStatus && STATUS_INDICATORS[submitStatus]}</Box>
      <Box float="right" textAlign="center">
        <SpaceBetween direction="horizontal" size="xs" alignItems="center">
          {submitStatus === 'success' ? (
            <Button variant="primary" onClick={onConfirm}>
              Close
            </Button>
          ) : (
            <>
              <Button variant="link" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={isSubmitting || submitStatus === 'loading'}
                onClick={() => welcomeContentFormRef.current && handleSubmit(welcomeContentFormRef.current.values)}
              >
                {submitStatus === 'loading' ? 'Saving...' : 'Save'}
              </Button>
            </>
          )}
        </SpaceBetween>
      </Box>
    </Box>
  );

  return (
    <Formik
      innerRef={welcomeContentFormRef}
      initialValues={INITIAL_VALUES}
      validationSchema={welcomeContentSchema()}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldTouched, setFieldValue }) => (
        <Form onSubmit={(e) => e.preventDefault()}>
          <Modal
            onDismiss={(event: { detail: { reason: string } }) => {
              if (event.detail.reason === 'closeButton') {
                // Perform cancel action only if it is through closeButton
                onCancel();
              }
            }}
            visible={showWelcomeContentModal}
            size="large"
            footer={renderFooter()}
            header="Edit Widget Details"
          >
            <div className="welcome-modal-content">
              <SpaceBetween size="s" direction="vertical">
                <Box padding={{ bottom: 'm' }}>
                  <FormField
                    constraintText={characterCountConstraintMessage(WELCOME_TITLE_MAX_LENGTH, values.welcomeTitle?.length || 0)}
                    errorText={touched.welcomeTitle && errors.welcomeTitle}
                    stretch={true}
                    label="Widget Title"
                  >
                    <Input
                      placeholder="Enter custom title"
                      onChange={({ detail }) => setFieldValue('welcomeTitle', detail.value)}
                      onBlur={() => setFieldTouched('welcomeTitle', true)}
                      value={values?.welcomeTitle || ''}
                    />
                  </FormField>
                </Box>

                <Box variant="awsui-key-label">Add link</Box>

                <WelcomeLinksContentForm
                  ref={welcomeContentInlineFormRef}
                  showWelcomeContentModal={showWelcomeContentModal}
                  hasWelcomeContent={true}
                  addNewWelcomeContent={handleAddNewWelcomeContent}
                  updateRow={handleUpdateWelcomeContent}
                />

                <div className="horizontal-bar" />

                <WelcomeContentTable
                  editWelcomeContent={handleEditWelcomeContent}
                  selectedWelcomeContentDetails={selectedWelcomeContentDetails}
                  deleteSelected={deleteSelected}
                  welcomeContentDetails={welcomeContentDetails}
                  setSelectedWelcomeContentDetails={setSelectedWelcomeContentDetails}
                />
              </SpaceBetween>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
