import DOMPurify from 'isomorphic-dompurify';

// Configure DOMPurify
DOMPurify.setConfig({
  ADD_ATTR: ['target'],
  ADD_TAGS: ['a']
});

// Custom hook to force target="_blank" and add rel="noopener noreferrer"
const addBlankTargetToLinks = (node: Element) => {
  if (node.nodeName === 'A' && node instanceof HTMLAnchorElement) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
};

// Add the custom hook with the correct type
DOMPurify.addHook('afterSanitizeAttributes', addBlankTargetToLinks as unknown as DOMPurify.Hook);

export const sanitizeHtml = (html: string): string => {
  return DOMPurify.sanitize(html, {
    ADD_ATTR: ['target'],
    CUSTOM_ELEMENT_HANDLING: {
      tagNameCheck: /^a$/,
      attributeNameCheck: /^target$/,
      allowCustomizedBuiltInElements: true
    }
  });
};
