import { Alert, Box, Button, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';

export type EventContentType =
  | ''
  | 'DELETE_NON_PROD'
  | 'DELETE_WELCOME_LINKS'
  | 'DELETE_TUTORIAL_LINK'
  | 'UPDATE_APPLICATION'
  | 'DELETE_APPLICATION'
  | 'CANCEL_APPLICATION'
  | 'DELETE_REPORT_ISSUES'
  | 'DELETE_ADDITIONAL_RESOURCES'
  | 'DELETE_ANNOUNCEMENT'
  | 'DELETE_FEEDBACK';
export interface ShowModalInfo {
  showModal: boolean;
  eventHeader: string;
  eventContentType: EventContentType;
  messageInfo?: any;
}

export interface ConfirmationModalProps {
  showModalInfo: ShowModalInfo;
  onCancel: () => void;
  onConfirm: (eventDetails: any) => void;
}
export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ showModalInfo, onCancel, onConfirm }) => {
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);

  return (
    <>
      <Modal
        onDismiss={onCancel}
        visible={showModalInfo.showModal}
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onCancel} formAction="none">
                Cancel
              </Button>
              <Button variant="primary" formAction="none" onClick={() => onConfirm(showModalInfo)} disabled={disableConfirmButton}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={showModalInfo.eventHeader}
      >
        <div>
          {(() => {
            switch (showModalInfo.eventContentType) {
              case 'DELETE_NON_PROD':
                return <DeleteNonProdLinks setDisableConfirmButton={setDisableConfirmButton}></DeleteNonProdLinks>;
              case 'DELETE_TUTORIAL_LINK':
                return <DeleteTutorialLinks setDisableConfirmButton={setDisableConfirmButton}></DeleteTutorialLinks>;
              case 'DELETE_WELCOME_LINKS':
                return <DeleteWelcomeLinks setDisableConfirmButton={setDisableConfirmButton}></DeleteWelcomeLinks>;
              case 'DELETE_ANNOUNCEMENT':
                return <DeleteAnnouncement setDisableConfirmButton={setDisableConfirmButton}></DeleteAnnouncement>;
              case 'DELETE_FEEDBACK':
                return <DeleteFeedback setDisableConfirmButton={setDisableConfirmButton}></DeleteFeedback>;
              case 'DELETE_APPLICATION':
                return (
                  <DeleteApplication messageInfo={showModalInfo.messageInfo} setDisableConfirmButton={setDisableConfirmButton}></DeleteApplication>
                );
              case 'CANCEL_APPLICATION':
                return (
                  <CancelApplication messageInfo={showModalInfo.messageInfo} setDisableConfirmButton={setDisableConfirmButton}></CancelApplication>
                );
              default:
                return <SimpleConfirmation setDisableConfirmButton={setDisableConfirmButton}></SimpleConfirmation>;
            }
          })()}
        </div>
      </Modal>
    </>
  );
};

const SimpleConfirmation = ({ setDisableConfirmButton }: any) => {
  useEffect(() => {
    setDisableConfirmButton(false);
  }, []);
  return (
    <SpaceBetween size="xs" direction="vertical">
      <p>{`You can’t undo this action.`}</p>
      <Alert statusIconAriaLabel="Info">{`Proceeding with this action will delete all the references.`}</Alert>
    </SpaceBetween>
  );
};

const DeleteNonProdLinks = ({ setDisableConfirmButton }: any) => {
  useEffect(() => {
    setDisableConfirmButton(false);
  }, []);
  return (
    <SpaceBetween size="xs" direction="vertical">
      <p>{`Permanently delete non-production links? You can’t undo this action.`}</p>
      <Alert statusIconAriaLabel="Info">
        {`Proceeding with this action will delete the non-production link(s) and remove any reference from the application homepage.`}
      </Alert>
    </SpaceBetween>
  );
};

const DeleteWelcomeLinks = ({ setDisableConfirmButton }: any) => {
  useEffect(() => {
    setDisableConfirmButton(false);
  }, []);
  return (
    <SpaceBetween size="xs" direction="vertical">
      <p>{`Permanently delete welcome links? You can’t undo this action.`}</p>
      <Alert statusIconAriaLabel="Info">
        {`Proceeding with this action will delete the welcome links and remove any reference from the welcome widget on the application homepage.`}
      </Alert>
    </SpaceBetween>
  );
};

const DeleteAnnouncement = ({ setDisableConfirmButton }: any) => {
  useEffect(() => {
    setDisableConfirmButton(false);
  }, []);
  return (
    <SpaceBetween size="xs" direction="vertical">
      <Alert statusIconAriaLabel="Info">
        {`Are you sure you want to delete the selected announcement? Proceeding with this action will permanently delete this content.`}
      </Alert>
    </SpaceBetween>
  );
};

const DeleteFeedback = ({ setDisableConfirmButton }: any) => {
  useEffect(() => {
    setDisableConfirmButton(false);
  }, []);
  return (
    <SpaceBetween size="xs" direction="vertical">
      <Alert statusIconAriaLabel="Info">
        {`Are you sure you want to delete the selected Feedback(s)? Proceeding with this action will permanently delete this content.`}
      </Alert>
    </SpaceBetween>
  );
};

const DeleteTutorialLinks = ({ setDisableConfirmButton }: any) => {
  useEffect(() => {
    setDisableConfirmButton(false);
  }, []);
  return (
    <SpaceBetween size="xs" direction="vertical">
      <p>{`Permanently delete tutorial links? You can’t undo this action.`}</p>
      <Alert statusIconAriaLabel="Info">
        {`Proceeding with this action will delete the tutorial(s) and remove any reference from the tutorial widget and tutorial page from this application.`}
      </Alert>
    </SpaceBetween>
  );
};

const DeleteApplication = ({ messageInfo, setDisableConfirmButton }: any) => {
  const [value, setValue] = useState('');

  const updateValue = (newValue: string) => {
    setValue(newValue);
    newValue === 'Confirm' ? setDisableConfirmButton(false) : setDisableConfirmButton(true);
  };

  return (
    <SpaceBetween size="xs" direction="vertical">
      <p>{`Permanently delete this application: ${messageInfo.applicationName}?  You cannot undo this action.`}</p>
      <Alert statusIconAriaLabel="Warning" type="warning">
        {`Proceeding with this action will delete the application with all its content from the portal and all users.`}
      </Alert>
      <p>{`To avoid accidental deletions, we ask you to provide additional confirmation by typing “Confirm” below:`}</p>
      <Input value={value} onChange={({ detail }) => updateValue(detail.value)} placeholder="Type 'Confirm' here"></Input>
    </SpaceBetween>
  );
};

const CancelApplication = ({ messageInfo, setDisableConfirmButton }: any) => {
  useEffect(() => {
    setDisableConfirmButton(false);
  }, []);

  return (
    <SpaceBetween size="xs" direction="vertical">
      <Alert statusIconAriaLabel="Warning" type="warning">
        {`Are you sure that you want to clear the current page? Any changes you made will not be saved.`}
      </Alert>
    </SpaceBetween>
  );
};
