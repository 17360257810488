import { Button, FormField, Input } from '@amzn/awsui-components-react';
import React, { Ref, forwardRef, useState } from 'react';
import FormFieldPopover from 'src/components/generic-components/FormFieldPopover';
import { characterCountConstraintMessage } from 'src/utilities/CommonUtilities';
import { v4 as uuidv4 } from 'uuid';
import { TutorialContentDetailsEntity } from '../../AdminModels';
import {
  MAX_TUTORIAL_LINKS,
  TUTORIAL_CATEGORY_MAX_LENGTH,
  TUTORIAL_DISPLAY_TITLE_MAX_LENGTH,
  tutorialContentDetailsSchema
} from './StepsValidations';

interface TutorialContentFormProps {
  hasTutorialContent: boolean;
  addNewTutorialContent: (addNewTutorialContent: TutorialContentDetailsEntity) => void;
  updateTutorialContent: (addNewTutorialContent: TutorialContentDetailsEntity) => void;
  tutorialContentDetails: TutorialContentDetailsEntity[];
}

export interface TutorialContentFormMethods {
  editTutorialContentChanged: (addNewTutorialContent: TutorialContentDetailsEntity) => void;
}

const TutorialContentForm = forwardRef<TutorialContentFormMethods, TutorialContentFormProps>(
  ({ hasTutorialContent, addNewTutorialContent, updateTutorialContent, tutorialContentDetails }: TutorialContentFormProps, ref: Ref<any>) => {
    const [newTutorialContentIsValid, setNewTutorialLinkContentIsValid] = useState(false);
    const [newTutorialLinkContent, setNewTutorialLinkContent] = useState<TutorialContentDetailsEntity>({
      id: uuidv4(),
      position: null,
      category: null,
      displayTitle: '',
      link: ''
    });
    const [newTutorialLinkContentErrors, setNewTutorialLinkContentErrors] = useState({
      position: '',
      displayTitle: '',
      category: '',
      link: ''
    });
    const [actionType, setActionType] = useState<'add' | 'edit'>('add');

    const editTutorialContentChanged = async (editTutorialContent: TutorialContentDetailsEntity) => {
      if (editTutorialContent) {
        setActionType('edit');
        setNewTutorialLinkContent({
          id: editTutorialContent.id,
          position: editTutorialContent.position,
          category: editTutorialContent.category,
          displayTitle: editTutorialContent.displayTitle,
          link: editTutorialContent.link
        });
        await isValidNewTutorialData();
      } else {
        setActionType('add');
        setNewTutorialLinkContent({
          id: uuidv4(),
          position: null,
          category: null,
          displayTitle: '',
          link: ''
        });
      }
    };

    React.useImperativeHandle(ref, () => ({
      editTutorialContentChanged
    }));

    const addTutorialContent = () => {
      if (tutorialContentDetails.length < MAX_TUTORIAL_LINKS) {
        const newRow: TutorialContentDetailsEntity = {
          id: uuidv4(),
          position: newTutorialLinkContent.position ? +newTutorialLinkContent.position : null,
          category: newTutorialLinkContent.category,
          displayTitle: newTutorialLinkContent.displayTitle,
          link: newTutorialLinkContent.link
        };
        clearForm();
        addNewTutorialContent(newRow);
      }
    };

    const isValidNewTutorialData = async () => {
      try {
        await tutorialContentDetailsSchema.validate(newTutorialLinkContent, { abortEarly: false });
        setNewTutorialLinkContentIsValid(true);
      } catch (error: any) {
        setNewTutorialLinkContentIsValid(false);
      }
    };

    const clearForm = () => {
      setNewTutorialLinkContent({ id: null, position: null, category: null, displayTitle: '', link: '' });
      setNewTutorialLinkContentErrors({ position: '', displayTitle: '', category: '', link: '' });
      setNewTutorialLinkContentIsValid(false);
    };

    const cancelEdit = () => {
      clearForm();
      setActionType('add');
    };

    const updateChanges = () => {
      updateTutorialContent(newTutorialLinkContent);
      clearForm();
      setActionType('add');
    };

    return (
      <>
        <div ref={ref} className="inline-table-horizontal-form">
          <FormField
            className="width-130px"
            label="Position (1-8)"
            errorText={newTutorialLinkContentErrors?.position}
            stretch={true}
            info={
              <FormFieldPopover
                header="Position"
                messages={[
                  'Enter numbers 1 to 8 to specify links to be displayed on the tutorials widget.',
                  'Leave the field blank for all other links.'
                ]}
              />
            }
          >
            <Input
              placeholder="Input number"
              disabled={!hasTutorialContent || tutorialContentDetails.length >= MAX_TUTORIAL_LINKS}
              onChange={({ detail }) => {
                setNewTutorialLinkContent({
                  ...newTutorialLinkContent,
                  position: isNaN(+detail.value) ? null : +detail.value
                });
              }}
              value={newTutorialLinkContent.position == null ? '' : newTutorialLinkContent.position.toString()}
              onBlur={async () => {
                tutorialContentDetailsSchema
                  .validateAt('position', { position: newTutorialLinkContent.position })
                  .then(() => {
                    setNewTutorialLinkContentErrors({
                      ...newTutorialLinkContentErrors,
                      position: ''
                    });
                  })
                  .catch((error) => {
                    setNewTutorialLinkContentErrors({
                      ...newTutorialLinkContentErrors,
                      position: error.message
                    });
                  })
                  .finally(() => {
                    isValidNewTutorialData();
                  });
              }}
            />
          </FormField>

          <FormField
            label="Category"
            errorText={newTutorialLinkContentErrors?.category}
            stretch={true}
            constraintText={characterCountConstraintMessage(TUTORIAL_CATEGORY_MAX_LENGTH, newTutorialLinkContent?.category?.length || 0)}
            info={
              <FormFieldPopover
                header={'Category'}
                messages={[
                  'An optional field to enter a category name to group tutorial links.',
                  'These categories will be displayed on the application’s tutorials page for users to filter by.'
                ]}
              />
            }
          >
            <Input
              placeholder="Enter Category"
              disabled={!hasTutorialContent || tutorialContentDetails.length >= MAX_TUTORIAL_LINKS}
              onChange={({ detail }) => {
                setNewTutorialLinkContent({
                  ...newTutorialLinkContent,
                  category: detail.value
                });
              }}
              value={newTutorialLinkContent.category || ''}
              onBlur={async () => {
                tutorialContentDetailsSchema
                  .validateAt('category', { category: newTutorialLinkContent.category })
                  .then(() => {
                    setNewTutorialLinkContentErrors({
                      ...newTutorialLinkContentErrors,
                      category: ''
                    });
                  })
                  .catch((error) => {
                    setNewTutorialLinkContentErrors({
                      ...newTutorialLinkContentErrors,
                      category: error.message
                    });
                  })
                  .finally(() => {
                    isValidNewTutorialData();
                  });
              }}
            />
          </FormField>

          <FormField
            label="Display title"
            errorText={newTutorialLinkContentErrors?.displayTitle}
            stretch={true}
            constraintText={characterCountConstraintMessage(TUTORIAL_DISPLAY_TITLE_MAX_LENGTH, newTutorialLinkContent?.displayTitle?.length || 0)}
            info={<FormFieldPopover header={'Display title'} messages={['Name the link you want displayed.']} />}
          >
            <Input
              placeholder="Enter display title"
              disabled={!hasTutorialContent || tutorialContentDetails.length >= MAX_TUTORIAL_LINKS}
              onChange={({ detail }) => {
                setNewTutorialLinkContent({ ...newTutorialLinkContent, displayTitle: detail.value });
              }}
              value={newTutorialLinkContent.displayTitle || ''}
              onBlur={async () => {
                tutorialContentDetailsSchema
                  .validateAt('displayTitle', { displayTitle: newTutorialLinkContent.displayTitle })
                  .then(() => {
                    setNewTutorialLinkContentErrors({
                      ...newTutorialLinkContentErrors,
                      displayTitle: ''
                    });
                  })
                  .catch((error) => {
                    setNewTutorialLinkContentErrors({
                      ...newTutorialLinkContentErrors,
                      displayTitle: error.message
                    });
                  })
                  .finally(() => {
                    isValidNewTutorialData();
                  });
              }}
            />
          </FormField>

          <FormField
            className="flex-grow-field"
            label="Link"
            errorText={newTutorialLinkContentErrors?.link}
            stretch={true}
            info={<FormFieldPopover header={'Link'} messages={['Enter the URL of the tutorial link']} />}
          >
            <Input
              placeholder="Paste URL"
              disabled={!hasTutorialContent || tutorialContentDetails.length >= MAX_TUTORIAL_LINKS}
              onChange={({ detail }) => {
                setNewTutorialLinkContent({ ...newTutorialLinkContent, link: detail.value });
              }}
              value={newTutorialLinkContent.link || ''}
              onBlur={async () => {
                tutorialContentDetailsSchema
                  .validateAt('link', { link: newTutorialLinkContent.link })
                  .then(() => {
                    setNewTutorialLinkContentErrors({
                      ...newTutorialLinkContentErrors,
                      link: ''
                    });
                  })
                  .catch((error) => {
                    setNewTutorialLinkContentErrors({
                      ...newTutorialLinkContentErrors,
                      link: error.message
                    });
                  })
                  .finally(() => {
                    isValidNewTutorialData();
                  });
              }}
            />
          </FormField>

          {actionType === 'add' && (
            <Button className="add-new-button" formAction="none" disabled={!newTutorialContentIsValid} onClick={addTutorialContent}>
              {`Add`}
            </Button>
          )}
          {actionType === 'edit' && (
            <>
              <Button className="add-new-button" formAction="none" onClick={cancelEdit}>
                {`Cancel`}
              </Button>
              <Button className="add-new-button" variant="primary" formAction="none" disabled={!newTutorialContentIsValid} onClick={updateChanges}>
                {`Save`}
              </Button>
            </>
          )}
        </div>
      </>
    );
  }
);

export default TutorialContentForm;
