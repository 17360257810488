import moment, { DurationInputArg1, DurationInputArg2 } from 'moment-timezone';
import { FINTECH_MONTH_COUNT, FilterDateFormat_MMM_YYYY } from 'src/components/fintech-ops/FinTechOpsConstants';
import { logger } from 'src/logger';
// Returns current UTC time in below format
// example: Jan 22nd 2023, 02:15:35 PM UTC
export const getCurrentUTCTimeZone = () => moment.utc().format('MMM Do YYYY, hh:mm:ss A z');

export const getCurrentUserTimeZone = () => moment().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');

// Returns current UTC time in ISO format
export const getCurrentUTCTimeZoneInISO = () => moment.utc().toISOString();

export const convertUTCtoUserTimeZone = (utcTimeStamp: string) => {
  // Detect user's local timezone
  const userTimezone = moment.tz.guess();

  // Convert UTC to user's local timestamp
  const localTimestamp = moment.utc(utcTimeStamp).tz(userTimezone).format();

  return localTimestamp;
};

export const convertUTCtoUserTimeZoneMoment = (utcTimeStamp: string) => {
  // Detect user's local timezone
  const userTimezone = moment.tz.guess();

  // Convert UTC to user's local timestamp
  return moment.utc(utcTimeStamp).tz(userTimezone);
};

export const getDateFromDateTime = (value: string) => moment(value).format('YYYY-MM-DD');

// Returns like July 16, 2023
export const getReadableDateFromDateTime = (value: string) => moment(value).format('ll');

export const getReadableDateTimeFormat = (inputValue?: string) => {
  if (inputValue) {
    const inputValueInMoment = moment(inputValue?.toString());
    const localTime = inputValue ? inputValueInMoment.tz(moment.tz.guess()) : moment.tz(moment.tz.guess());
    return localTime.format('MMM Do YYYY, hh:mm:ss A z');
  } else {
    return '';
  }
};

export const dateTimeComparatorForTable = (dateTime1: string, dateTime2: string) => {
  const date1 = moment(dateTime1);
  const date2 = moment(dateTime2);
  return date1.isBefore(date2) ? -1 : 1;
};

export const dateTimeComparator = (dateTime1: string, dateTime2: string) => {
  const date1 = moment(dateTime1);
  const date2 = moment(dateTime2);
  return date1.isBefore(date2) ? 1 : -1;
};

export const dateTimeComparatorMMMYYYYFormat = (dateTime1: string, dateTime2: string) => {
  const date1 = moment(dateTime1, FilterDateFormat_MMM_YYYY);
  const date2 = moment(dateTime2, FilterDateFormat_MMM_YYYY);
  return date1.isBefore(date2) ? 1 : -1;
};

export const sortedDateArrayMMMYYYYFormat = (array: any[]) => {
  return array?.sort((a, b) => dateTimeComparatorMMMYYYYFormat(a, b));
};

export const getUserTimeZoneInMomentObject = (inputValue?: string) => {
  const inputValueInMoment = moment(inputValue?.toString());
  const localTime = inputValue ? inputValueInMoment.tz(moment.tz.guess()) : moment.tz(moment.tz.guess());
  return localTime;
};

export const getCurrentUserLocalTimeReadableFormat = (inputValue?: string) => {
  if (!inputValue) {
    return '';
  }

  const inputValueInMoment = moment(inputValue?.toString());
  const localTime = inputValue ? inputValueInMoment.tz(moment.tz.guess()) : moment.tz(moment.tz.guess());
  return localTime.format('MMM Do YYYY, hh:mm:ss A z');
};

export const getMonth = (value: string) => moment(value).format('MMM');

export const getDay = (value: string) => moment(value).format('DD');

export const epochToUserTimeZone = (input: string): string => {
  const formattedTime = moment(parseInt(input).valueOf() * 1000).local();
  return getCurrentUserLocalTimeReadableFormat(formattedTime.toISOString());
};

export const getCurrentTime = () => moment();

export const getTimeDifference = (input: moment.Moment) => {
  return moment().diff(input, 'millisecond');
};

export const getPreviousPeriod = (subtraction: DurationInputArg1, unit: DurationInputArg2) => {
  return moment().subtract(subtraction, unit);
};

export const getMomentTime = (input: string) => {
  return moment(input);
};

// export const getFormattedTime = (input: string, format: string) => {
//   return moment(input).format(format);
// };

export const getBackAgeInPeriod = (startTime: moment.MomentInput, unit: DurationInputArg2, endTime?: moment.MomentInput) => {
  if (endTime) {
    return moment(endTime).diff(moment(startTime), unit);
  } else {
    return moment().diff(moment(startTime), unit);
  }
};

export const getRemainingTimeDetails = (futureDate: string) => {
  const currentDate = moment();
  const duration = moment.duration(moment(futureDate).diff(currentDate));
  let message = '';
  switch (true) {
    case duration.asDays() >= 1:
      message = `${Math.floor(duration.asDays())} day(s) left`;
      break;
    case duration.asHours() >= 1:
      message = `${Math.floor(duration.asHours())} hour(s) left`;
      break;
    case duration.asMinutes() >= 1:
      message = `${Math.floor(duration.asMinutes())} minute(s) left`;
      break;
    case duration.asSeconds() >= 0:
      message = `${Math.floor(duration.asSeconds())} second(s) left`;
      break;
  }
  return message;
};

export const getTimeAgoDetails = (pastDate: string) => {
  const currentDate = moment();
  const duration = moment.duration(currentDate.diff(moment(pastDate)));
  let message = '';

  if (duration.asDays() >= 1) {
    message = `${Math.floor(duration.asDays())} day(s) ago`;
  } else if (duration.asHours() >= 1) {
    message = `${Math.floor(duration.asHours())} hour(s) ago`;
  } else if (duration.asMinutes() >= 1) {
    message = `${Math.floor(duration.asMinutes())} minute(s) ago`;
  } else {
    message = `${Math.floor(duration.asSeconds())} second(s) ago`;
  }

  return message;
};

export const getMinutesAgoDetails = (minutes: number, isSeconds = false) => {
  let duration = moment.duration();
  if (isSeconds) {
    duration = moment.duration(minutes, 'seconds');
  } else {
    duration = moment.duration(minutes, 'minutes');
  }
  let message = '';

  if (duration.asDays() >= 1) {
    const days = Math.floor(duration.asDays());
    message = `${days} day${days !== 1 ? 's' : ''}`;
  } else if (duration.asHours() >= 1) {
    const hours = Math.floor(duration.asHours());
    message = `${hours} hour${hours !== 1 ? 's' : ''}`;
  } else if (duration.asMinutes() >= 1) {
    const minutes = Math.floor(duration.asMinutes());
    message = `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  } else {
    if (isSeconds) {
      const seconds = Math.floor(duration.asSeconds());
      message = `${seconds} second${seconds !== 1 ? 's' : ''}`;
    } else {
      message = 'Just a moment ago';
    }
  }
  return message;
};

export const getFormattedMonthYearFromUTC = (input: string, format: string) => {
  return moment.utc(input).format(format);
};

export const getDiffInTime = (time1: moment.MomentInput, time2: moment.MomentInput, unit: DurationInputArg2) => {
  return moment(time2).diff(moment(time1), unit);
};

// Check if the input string (convert it to moment) is current Month
export const isCurrentYearAndMonth = (input: string): boolean => {
  const inputDateTime = moment(input);
  return `${inputDateTime.month() - inputDateTime.year()}` === `${moment().month() - moment().year()}`;
};

export function getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps(): moment.Moment {
  const currentDate = moment();
  const lastSixMonths = currentDate.clone().subtract(FINTECH_MONTH_COUNT - 1, 'month'); // .subtract(1, 'year').add(1, 'month');
  const firstDayOfMonth = lastSixMonths.startOf('month');
  return firstDayOfMonth;
}

// using any[] for metricsParsedData array since schema is diff for each metrics
export const getCurrentAndPreviousMonth = (metricsParsedData: any[], column: string) => {
  //initializing maxDate to default value to handle empty metricsParsedData
  try {
    let maxDate = moment('9999-12-31');
    if (metricsParsedData.length > 0) {
      //maxdate in the filtered data
      maxDate = moment(metricsParsedData.reduce((a, b) => (a[column] > b[column] ? a : b))[column]);
    }
    const currentMonth = maxDate.startOf('month').format('YYYY-MM-DD');
    const previousMonth = maxDate.subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    return [currentMonth, previousMonth];
  } catch (error: any) {
    logger.error('error while getCurrentAndPreviousMonth', error);
    throw new Error('Unable to retrieve current and previous month');
  }
};

export const getCurrentUserLocalTimeFormatted = () => {
  const localTime = moment.tz(moment.tz.guess());
  return localTime.format('YYYY-MM-DD-HH-mm-ss');
};
