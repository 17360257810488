import { DynamoDBClient, DynamoDBClientConfig, ScanCommand, ScanCommandInput } from '@aws-sdk/client-dynamodb';
import { unmarshall } from '@aws-sdk/util-dynamodb';
import { ENVIRONMENT_VARIABLES } from 'src/constants/EnvironmentConstants';
import { CognitoService } from 'src/services/aws/CognitoService';

export class DynamoDBService {
  private static async getClientConfig(): Promise<DynamoDBClientConfig> {
    return {
      region: ENVIRONMENT_VARIABLES.env.Region,
      credentials: await CognitoService.getAWSServiceCredentials()
    };
  }

  private static async getClient(): Promise<DynamoDBClient> {
    const config = await this.getClientConfig();
    return new DynamoDBClient(config);
  }

  private static async scanTable(params: ScanCommandInput, result: any[] = []): Promise<any[]> {
    const client = await this.getClient();
    const { Items, LastEvaluatedKey } = await client.send(new ScanCommand(params));
    const unmarshalledItems = Items?.map((i) => unmarshall(i)) ?? [];
    const finalResult = result.concat(unmarshalledItems);

    if (LastEvaluatedKey) {
      params.ExclusiveStartKey = LastEvaluatedKey;
      return this.scanTable(params, finalResult);
    }

    return finalResult;
  }

  public static async getAllItems(tableName: string, limit = 4000): Promise<any[]> {
    const scanInput: ScanCommandInput = {
      TableName: tableName,
      Limit: limit
    };

    return this.scanTable(scanInput);
  }
}
