/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createApplication = /* GraphQL */ `mutation CreateApplication($input: ApplicationInput) {
  createApplication(input: $input) {
    id
    applicationName
    applicationLink
    applicationOwnerId
    applicationIntroductionVideoUrl
    shortApplicationDescription
    fullApplicationDescription
    glAccounts {
      id
      labelType
      label
      
    }
    toolUI {
      id
      labelType
      label
      
    }
    hasNonProductionEnvironments
    nonProductionEnvironments {
      id
      accessLevel {
        id
        labelType
        label
        
      }
      displayText
      link
      
    }
    timeGranularity {
      id
      labelType
      label
      
    }
    keyDimension {
      id
      labelType
      label
      
    }
    process {
      id
      labelType
      label
      
    }
    scenario {
      id
      labelType
      label
      
    }
    lastEnhanced {
      id
      labelType
      label
      
    }
    hasWelcomeContent
    welcomeTitle
    welcomeContentDetails {
      id
      displayText
      link
      description
      pointOfContact
      
    }
    hasAnnouncements
    hasTutorials
    tutorialContentDetails {
      id
      category
      position
      displayTitle
      link
      
    }
    hasFeedback
    sidePanelDetails {
      keyContacts {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      officeHours {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      supportLinks {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      customSidePanelWidgets {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      
    }
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedMutation<APITypes.CreateApplicationMutationVariables, APITypes.CreateApplicationMutation>;
export const updateApplication = /* GraphQL */ `mutation UpdateApplication($input: ApplicationInput) {
  updateApplication(input: $input) {
    id
    applicationName
    applicationLink
    applicationOwnerId
    applicationIntroductionVideoUrl
    shortApplicationDescription
    fullApplicationDescription
    glAccounts {
      id
      labelType
      label
      
    }
    toolUI {
      id
      labelType
      label
      
    }
    hasNonProductionEnvironments
    nonProductionEnvironments {
      id
      accessLevel {
        id
        labelType
        label
        
      }
      displayText
      link
      
    }
    timeGranularity {
      id
      labelType
      label
      
    }
    keyDimension {
      id
      labelType
      label
      
    }
    process {
      id
      labelType
      label
      
    }
    scenario {
      id
      labelType
      label
      
    }
    lastEnhanced {
      id
      labelType
      label
      
    }
    hasWelcomeContent
    welcomeTitle
    welcomeContentDetails {
      id
      displayText
      link
      description
      pointOfContact
      
    }
    hasAnnouncements
    hasTutorials
    tutorialContentDetails {
      id
      category
      position
      displayTitle
      link
      
    }
    hasFeedback
    sidePanelDetails {
      keyContacts {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      officeHours {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      supportLinks {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      customSidePanelWidgets {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      
    }
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedMutation<APITypes.UpdateApplicationMutationVariables, APITypes.UpdateApplicationMutation>;
export const deleteApplication = /* GraphQL */ `mutation DeleteApplication($input: [ApplicationInput]) {
  deleteApplication(input: $input)
}
` as GeneratedMutation<APITypes.DeleteApplicationMutationVariables, APITypes.DeleteApplicationMutation>;
export const createAnnouncement = /* GraphQL */ `mutation CreateAnnouncement($input: AnnouncementInput) {
  createAnnouncement(input: $input) {
    id
    title
    applicationName {
      id
      labelType
      label
      
    }
    announcementCategory {
      id
      labelType
      label
      
    }
    announcement
    announcementDate
    announcementLink
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedMutation<APITypes.CreateAnnouncementMutationVariables, APITypes.CreateAnnouncementMutation>;
export const updateAnnouncement = /* GraphQL */ `mutation UpdateAnnouncement($input: AnnouncementInput) {
  updateAnnouncement(input: $input) {
    id
    title
    applicationName {
      id
      labelType
      label
      
    }
    announcementCategory {
      id
      labelType
      label
      
    }
    announcement
    announcementDate
    announcementLink
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedMutation<APITypes.UpdateAnnouncementMutationVariables, APITypes.UpdateAnnouncementMutation>;
export const createFeedback = /* GraphQL */ `mutation CreateFeedback($input: FeedbackInput) {
  createFeedback(input: $input) {
    id
    applicationName {
      id
      labelType
      label
      
    }
    rating
    feedback
    feedbackType
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedMutation<APITypes.CreateFeedbackMutationVariables, APITypes.CreateFeedbackMutation>;
export const updateFeedback = /* GraphQL */ `mutation UpdateFeedback($input: FeedbackInput) {
  updateFeedback(input: $input) {
    id
    applicationName {
      id
      labelType
      label
      
    }
    rating
    feedback
    feedbackType
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedMutation<APITypes.UpdateFeedbackMutationVariables, APITypes.UpdateFeedbackMutation>;
export const createOrUpdateApplicationFavorites = /* GraphQL */ `mutation CreateOrUpdateApplicationFavorites($input: FavoriteInput) {
  createOrUpdateApplicationFavorites(input: $input) {
    id
    userAlias
    applicationId
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateApplicationFavoritesMutationVariables, APITypes.CreateOrUpdateApplicationFavoritesMutation>;
export const createOrUpdateLabels = /* GraphQL */ `mutation CreateOrUpdateLabels($input: [LabelInput]) {
  createOrUpdateLabels(input: $input) {
    id
    labelType
    label
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateLabelsMutationVariables, APITypes.CreateOrUpdateLabelsMutation>;
export const createGeneralContent = /* GraphQL */ `mutation CreateGeneralContent($input: GeneralContentInput) {
  createGeneralContent(input: $input) {
    id
    name
    content
    
  }
}
` as GeneratedMutation<APITypes.CreateGeneralContentMutationVariables, APITypes.CreateGeneralContentMutation>;
export const updateGeneralContent = /* GraphQL */ `mutation UpdateGeneralContent($input: [GeneralContentInput]) {
  updateGeneralContent(input: $input) {
    id
    name
    content
    
  }
}
` as GeneratedMutation<APITypes.UpdateGeneralContentMutationVariables, APITypes.UpdateGeneralContentMutation>;
export const createOrUpdateMapping = /* GraphQL */ `mutation CreateOrUpdateMapping($input: [MappingInput], $table_name: String) {
  createOrUpdateMapping(input: $input, table_name: $table_name) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateMappingMutationVariables, APITypes.CreateOrUpdateMappingMutation>;
export const updateHomePageContent = /* GraphQL */ `mutation UpdateHomePageContent($input: HomePageContentInput) {
  updateHomePageContent(input: $input) {
    id
    applicationIntroductionVideoUrl
    fullApplicationDescription
    hasWelcomeContent
    welcomeTitle
    welcomeContentDetails {
      id
      displayText
      link
      description
      pointOfContact
      
    }
    hasAnnouncements
    hasTutorials
    tutorialContentDetails {
      id
      category
      position
      displayTitle
      link
      
    }
    hasFeedback
    sidePanelDetails {
      keyContacts {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      officeHours {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      supportLinks {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      customSidePanelWidgets {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      
    }
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedMutation<APITypes.UpdateHomePageContentMutationVariables, APITypes.UpdateHomePageContentMutation>;
export const updateTeamPageContent = /* GraphQL */ `mutation UpdateTeamPageContent($input: TeamPageContentInput) {
  updateTeamPageContent(input: $input) {
    id
    enableTeamInfoTabs
    teams {
      id
      teamName
      teamIntroductionVideoUrl
      teamOverView
      teamWelcomeDetails {
        id
        displayText
        link
        description
        pointOfContact
        
      }
      
    }
    sidePanelDetails {
      keyContacts {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      officeHours {
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        
      }
      supportLinks {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      customSidePanelWidgets {
        id
        isEnabled
        title
        isExpandedByDefault
        contentType
        richTextContent
        tableContent {
          id
          position
          displayName
          link
          
        }
        
      }
      
    }
    itemMetadata {
      isActive
      createdBy
      createdTime
      updatedBy
      updatedTime
      
    }
    
  }
}
` as GeneratedMutation<APITypes.UpdateTeamPageContentMutationVariables, APITypes.UpdateTeamPageContentMutation>;
