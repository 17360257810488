import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { Auth } from 'aws-amplify';
import { ENVIRONMENT_VARIABLES } from 'src/constants/EnvironmentConstants';

export class CognitoService {
  private static async getToken(): Promise<string> {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  }

  private static getCognitoDomain(): string {
    return `cognito-idp.${ENVIRONMENT_VARIABLES.env.Region}.amazonaws.com/${ENVIRONMENT_VARIABLES.env.CognitoUserPoolId}`;
  }

  public static async getAWSServiceCredentials() {
    const COGNITO_LOGIN: { [key: string]: string } = {};
    COGNITO_LOGIN[this.getCognitoDomain()] = await this.getToken();
    return fromCognitoIdentityPool({
      clientConfig: { region: ENVIRONMENT_VARIABLES.env.Region },
      identityPoolId: ENVIRONMENT_VARIABLES.env.CognitoIdentityPoolId,
      logins: COGNITO_LOGIN
    });
  }
}
