import { Box, Container, Grid } from '@amzn/awsui-components-react';
import React from 'react';
import { WelcomeContentDetailsEntity } from '../admin-components/AdminModels';
import { TeamEntityResponse } from '../admin-components/teams-manage/TeamManagementModel';
import { WelcomeWidgetContent } from '../widgets/welcome-widget/WelcomeWidgetContent';

interface TeamTabContentProps {
  team: TeamEntityResponse;
}

const getVideoUrl = (url: string): string => {
  if (url.includes('broadcast.amazon.com/videos/')) {
    return url.replace('/videos/', '/embed/');
  }
  return url;
};

export const TeamTabContent: React.FC<TeamTabContentProps> = ({ team }) => {
  const videoURL = getVideoUrl(team.teamIntroductionVideoUrl);
  const teamsWelcomeContent: WelcomeContentDetailsEntity[] = team.teamWelcomeDetails;

  return (
    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]} className="team-tab-grid">
      <div className="video-container-wrapper">
        <div className="video-wrapper">
          <iframe src={videoURL} title={`${team.teamName} Introduction`} allowFullScreen />
        </div>
        <Container className="team-overview-container">
          <Box variant="p">{team.teamOverView}</Box>
        </Container>
      </div>
      <Container className="welcome-content-wrapper">
        <WelcomeWidgetContent welcomeContentDetails={teamsWelcomeContent} />
      </Container>
    </Grid>
  );
};
