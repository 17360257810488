import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Application } from 'src/components/context/AppContextModels';

interface AppSpecificSideNavigationProps {
  appSpecific: Application | undefined;
}

const BASE_NAVIGATION_ITEMS: SideNavigationProps.Item[] = [
  { type: 'link', text: 'Announcements', href: '/announcements' },
  { type: 'link', text: 'Tutorials', href: '/tutorials' }
];

const SUPPORT_LINKS_DIVIDER: SideNavigationProps.Item = {
  type: 'divider'
};

export const AppSpecificSideNavigation: React.FC<AppSpecificSideNavigationProps> = ({ appSpecific }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeRef, setActiveRef] = useState<string>('');

  useEffect(() => {
    setActiveRef(pathname);
  }, [pathname]);

  const sideNavigationItems = useMemo(() => {
    if (!appSpecific) return BASE_NAVIGATION_ITEMS;

    const updatedItems = [...BASE_NAVIGATION_ITEMS];
    const supportLinks = appSpecific.sidePanelDetails?.supportLinks;

    if (supportLinks?.isEnabled && supportLinks.tableContent && supportLinks.tableContent?.length > 0) {
      updatedItems.push(SUPPORT_LINKS_DIVIDER);
      updatedItems.push(
        ...supportLinks.tableContent
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .map(
            (link): SideNavigationProps.Item => ({
              type: 'link',
              text: link.displayName,
              href: link.link,
              external: true
            })
          )
      );
    }

    return updatedItems;
  }, [appSpecific]);

  const handleNavigation = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      navigate(event.detail.href, {
        state: {
          from: `/all-apps/${appSpecific?.id}`,
          redirectFromHomePage: false,
          fromAppName: appSpecific?.applicationName,
          fromAppId: appSpecific?.id
        }
      });
    }
  };

  return (
    <SideNavigation
      header={{
        href: '/',
        text: appSpecific?.applicationName || ''
      }}
      items={sideNavigationItems}
      activeHref={activeRef}
      onFollow={handleNavigation}
    />
  );
};
