import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import ApplicationDetails from './Step1';
import LinksAndTags from './Step2';
import ConfigureHomepage from './Step3';
import HelpPanelConfiguration from './Step4';
import { AdminBaseBreadcrumbs } from '../../AdminHomePageConfig';

export const AdminNewAppWizardSteps: any[] = [
  {
    title: 'Application details',
    description: '',
    stateKey: 'applicationDetails',
    StepContent: ApplicationDetails,
    isOptional: false
  },
  {
    title: 'Links and tags',
    description: '',
    stateKey: 'linksAndTags',
    StepContent: LinksAndTags,
    isOptional: true
  },
  {
    title: 'App widgets',
    description: '',
    stateKey: 'homePageConfigurations',
    StepContent: ConfigureHomepage,
    isOptional: true
  },
  {
    title: 'Side panel details',
    description: '',
    stateKey: 'helpPanelConfiguration',
    StepContent: HelpPanelConfiguration,
    isOptional: true
  }
];

export const getNewAppBreadcrumbItems = (appName: string): BreadcrumbGroupProps.Item[] => {
  return [...AdminBaseBreadcrumbs, { text: 'Applications', href: '/admin/applications' }, { text: appName, href: '/admin/manage-application' }];
};
