import React, { useEffect, useRef } from 'react';
import { TutorialsFilter } from 'src/components/context/AppContextModels';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Box from '@amzn/awsui-components-react/polaris/box';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';

interface TutorialVideoModalProps {
  showTutorialModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  link: string | null;
  displayTitle: string | null;
  category: string | null;
}
const TutorialVideoModal: React.FC<TutorialVideoModalProps> = ({ showTutorialModal, onCancel, onConfirm, displayTitle, link, category }) => {
  const [swap, setSwap] = React.useState(false);

  const onClickOfClose = () => {
    setSwap(true);
    setTimeout(() => {
      setSwap(false);
      onCancel();
    }, 100);
  };

  return (
    <Modal
      onDismiss={({ detail }) => {
        onClickOfClose();
      }}
      visible={showTutorialModal}
      size="max"
      footer={
        <Box float="right" textAlign="center">
          <SpaceBetween direction="horizontal" size="xs" alignItems="center">
            <Button variant="primary" onClick={() => onClickOfClose()}>
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={displayTitle || ''}
    >
      <Container
        disableContentPaddings
        disableHeaderPaddings
        fitHeight
        media={{
          width: '1280',
          height: '820',
          content: (
            <>
              <Container className="video-container" disableContentPaddings disableHeaderPaddings>
                {swap && <LoadingSpinner />}
                {!swap && (
                  <iframe
                    className="video-frame"
                    sandbox="allow-scripts allow-popups allow-presentation allow-same-origin"
                    src={link || ''}
                    loading="lazy"
                    width="100%"
                    allowFullScreen
                  ></iframe>
                )}
              </Container>
            </>
          )
        }}
      ></Container>
    </Modal>
  );
};

export default TutorialVideoModal;
