import { Auth } from 'aws-amplify';
import { ENVIRONMENT_VARIABLES } from 'src/constants/EnvironmentConstants';
import { getSecretValue } from 'src/services/aws/SecretManagerService';
import { BackendGraphQLApiConfig, SecretKeyArnConfig, SecretManagerKeys } from '../../constants/ApiConfigConstants';

/**
 * Returns the Amplify authentication configuration.
 */
export const getAmplifyAuthConfig = () => {
  const { Region, CognitoUserPoolId, CognitoUserPoolWebClientId, CognitoIdentityPoolId, AwsAccountId, CognitoDomain, ApplicationDomain } =
    ENVIRONMENT_VARIABLES.env;

  return {
    Auth: {
      region: Region,
      userPoolId: CognitoUserPoolId,
      userPoolWebClientId: CognitoUserPoolWebClientId,
      identityPoolId: CognitoIdentityPoolId,
      awsAccountId: AwsAccountId,
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: CognitoDomain,
        scope: ['openid'],
        redirectSignIn: ApplicationDomain,
        redirectSignOut: ApplicationDomain,
        responseType: 'code'
      }
    }
  };
};

/**
 * Helper function to get API key from Secrets Manager
 * For dev and alpha environments, retrieves API key from Secrets Manager
 * For other environments (which use Cognito auth), returns empty string
 */
const getApiKey = async (stage: string, secretArn: string | undefined, region: string): Promise<string> => {
  if (stage !== 'dev' && stage !== 'alpha') return '';

  const apiSecrets = secretArn && (await getSecretValue(secretArn, region))?.SecretString;
  if (!apiSecrets) return '';

  const parsedSecrets = JSON.parse(apiSecrets);
  return parsedSecrets[SecretManagerKeys.API_KEY] || '';
};

/**
 * Retrieves AppSync configuration from AWS Secrets Manager.
 */
export const getAppSyncConfig = async (): Promise<any> => {
  console.debug(`Current Stage: ${ENVIRONMENT_VARIABLES.env.Stage}`);
  const { AuthenticationType, Stage, Region } = ENVIRONMENT_VARIABLES.env;
  const secretArn = SecretKeyArnConfig.find((config) => config.environment === Stage)?.secretARN;

  try {
    const graphQlAPIKey = await getApiKey(Stage, secretArn, Region);
    const graphQlApiEndpoint = BackendGraphQLApiConfig.find((config) => config.environment === Stage)?.finsuite_api;

    return {
      aws_appsync_region: Region,
      aws_appsync_authenticationType: AuthenticationType,
      aws_appsync_graphqlEndpoint: graphQlApiEndpoint,
      aws_appsync_apiKey: graphQlAPIKey
    };
  } catch (error) {
    console.error('Unable to retrieve API Key', error);
    throw error;
  }
};

/**
 * Retrieves Rest API configuration.
 *us-east-1 is the only supported region for PAPI.
 */
export const getRestApiConfig = () => {
  const { Stage } = ENVIRONMENT_VARIABLES.env;
  const getApiEndpoint = (environment: string) => BackendGraphQLApiConfig.find((config) => config.environment === environment)?.risk_remediator_api;

  const apiEndpoint = getApiEndpoint(Stage);

  return {
    endpoints: [
      {
        name: 'PEOPLE_API',
        endpoint: apiEndpoint,
        region: 'us-east-1'
      },
      {
        name: 'RISK_REMEDIATOR_API',
        endpoint: apiEndpoint,
        custom_header: async () => ({
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        })
      }
    ]
  };
};
