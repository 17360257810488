import { CognitoService } from 'src/services';
import { generateRandomString } from './lex-connections';
import { ENVIRONMENT_VARIABLES } from 'src/constants/EnvironmentConstants';

export const API_LEX_CONFIG: any[] = [
  {
    environment: 'dev',
    botID: 'U127TKCCJB',
    botAlias: '1LTN1N58HG'
  },
  {
    environment: 'alpha',
    botID: 'U127TKCCJB',
    botAlias: '1LTN1N58HG'
  },
  {
    environment: 'beta',
    botID: 'OTK7UZTJMM',
    botAlias: '9CZEPFLF8C'
  }
];

export const lex_v2_service = 'models-v2-lex.us-west-2.amazonaws.com';
export const region = 'us-west-2';
export const contentType = 'Text';
export const lexLocale = 'en_US';
export const botName = API_LEX_CONFIG.find((c) => c.environment === ENVIRONMENT_VARIABLES.env.Stage)?.botID;
export const botAlias = API_LEX_CONFIG.find((c) => c.environment === ENVIRONMENT_VARIABLES.env.Stage)?.botAlias;
export const credentials = CognitoService.getAWSServiceCredentials();
export const sessionId = (): string => {
  if (ENVIRONMENT_VARIABLES.env.Stage == 'alpha' || ENVIRONMENT_VARIABLES.env.Stage == 'dev') {
    return '850882930' + generateRandomString();
  } else {
    return '186446126' + generateRandomString();
  }
};

export const ticketLink = `https://tiny.amazon.com/chrh13xj`;
export const ticketLink2 = new URL(ticketLink);

export const errorMsg = `Sorry we are experiencing some problems, could you try your question again. If problems persist please launch a ticket`;
export const incorrectResponseMsg = `Sorry to hear that. Please a raise a ticket here and tell us how we can improve`;
export const inputPlaceHolderMsg = 'What is the revenue of Echo May 2024 MEC Actuals?';
export const goodResponseMsg = 'Glad to hear, thank you for your feedback!';
export const errorFromBackLex = `Failed to retrieve data. Please try again or launch a ticket and tell us what happened <a href=\"https://t.corp.amazon.com/create/templates/b62a7760-859e-4932-ba65-fe6d9b9e7c68\">Ticketing</a>`;
export const properErrorFromLex = `Failed to retrieve data. Please try again after sometime or create a ticket to tell us what happened`;
