import { useEffect, useRef, useState } from 'react';
import { useContainerQuery } from '@amzn/awsui-component-toolkit';
import { load, remove, save } from 'src/utilities/LocalStorage';
import { StoredWidgetPlacement } from '../context/AppContextModels';

const defaultDFPHomepageLayout: ReadonlyArray<StoredWidgetPlacement> = [{ id: 'welcomeWidget' }, { id: 'announcementWidget' }];

export function useDFPHomePageItemsLayout(storageKey: string) {
  const [width, ref] = useContainerQuery((entry) => entry.contentBoxWidth);
  const [layout, setLayout] = useState<ReadonlyArray<StoredWidgetPlacement> | null>(() => load(storageKey) ?? null);
  const itemsChanged = useRef(layout !== null);

  useEffect(() => {
    if (itemsChanged.current || !width) {
      return;
    }
    setLayout(defaultDFPHomepageLayout);
  }, [width]);

  function handleLayoutChange(layout: ReadonlyArray<StoredWidgetPlacement>) {
    itemsChanged.current = true;
    save(storageKey, layout);
    setLayout(layout);
  }

  function resetLayout() {
    itemsChanged.current = false;
    remove(storageKey);
    setLayout(defaultDFPHomepageLayout);
  }

  return [ref, layout ?? [], handleLayoutChange, resetLayout] as const;
}
