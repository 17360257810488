import * as Yup from 'yup';
import { infoPanelPageConfigurationSchema } from '../homepage-manage/HomePageManagementSchema';
import { welcomeContentDetailsSchemaForApplications } from '../applications/admin-manage-application/StepsValidations';

// Validation messages
export const validationMessages = {
  required: 'This field is required',
  url: 'Please enter a valid URL',
  maxLength: (max: number) => `Must be ${max} characters or less`,
  minLength: (min: number) => `Must be at least ${min} characters`,
  boolean: 'Must be a boolean value',
  array: 'Must be an array',
  atLeastOneSelection: 'Select at least one option',
  number: 'Must be a number',
  alphanumericDash: 'Only alphanumeric characters, spaces, and dashes are allowed'
};

// TeamEntity Schema
export const TeamEntitySchema = Yup.object().shape({
  id: Yup.string().required(validationMessages.required),
  teamName: Yup.string()
    .required(validationMessages.required)
    .matches(/^[a-zA-Z0-9- ]+$/, validationMessages.alphanumericDash),
  teamIntroductionVideoUrl: Yup.string().required(validationMessages.required).url(validationMessages.url),
  teamOverView: Yup.string().required(validationMessages.required).max(600, validationMessages.maxLength(600)),
  teamWelcomeDetails: Yup.array(welcomeContentDetailsSchemaForApplications)
});

// TeamPageDetailsAndWidgets Schema
export const TeamPageDetailsAndWidgetsSchema = Yup.object().shape({
  enableTeamInfoTabs: Yup.boolean().required(validationMessages.boolean),
  teams: Yup.array().of(TeamEntitySchema).required(validationMessages.array)
});

// TeamEntity Schema
export const TeamNamesSchema = Yup.object().shape({
  id: Yup.string().required(validationMessages.required),
  teamName: Yup.string()
    .required(validationMessages.required)
    .matches(/^[a-zA-Z0-9- ]+$/, validationMessages.alphanumericDash)
});

export const TeamManagementSchema = Yup.array().min(1, validationMessages.atLeastOneSelection).of(TeamNamesSchema).required(validationMessages.array);

// TeamPageWizard Schema
export const TeamPageWizardSchema = Yup.object().shape({
  id: Yup.string().required(validationMessages.required),
  teamManagement: TeamManagementSchema,
  teamPageDetailsAndWidgets: TeamPageDetailsAndWidgetsSchema,
  infoPanelPageConfiguration: infoPanelPageConfigurationSchema,
  itemMetadata: Yup.object() // No specific schema for ItemMetadata
});
