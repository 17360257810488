import { LicenseManager } from 'ag-grid-enterprise';
import { logger } from 'src/logger';
import { SSMService } from 'src/services/aws/SSMService';

export class AgGridLicenseService {
  private static readonly AgGridLicenseKeyParameterStoreKey = '/das-finsuite/fe/ag-grid-license';

  public static async configureLicense(): Promise<string> {
    const licenseKey = await SSMService.retrieveParameter(this.AgGridLicenseKeyParameterStoreKey);
    if (licenseKey) {
      LicenseManager.setLicenseKey(licenseKey);
      logger.info('Ag-Grid License configured successfully!', { logType: 'configStatus' });
      return 'setLicenseKey_Success';
    } else {
      logger.error('Ag-Grid License setup failed!', { logType: 'configStatus' });
      throw new Error('setLicenseKey_Failed');
    }
  }
}
