import {
  AnnouncementEntity,
  AnnouncementFlatTableEntity,
  Application,
  ApplicationDropdowns,
  ApplicationFlatTableEntity,
  FavoriteApplications,
  LabelInput
} from 'src/components/context/AppContextModels';
import {
  getApplicationDropdownsInSorted,
  parseAllAnnouncements,
  parseAllApplications,
  sortAndFilterAnnouncements
} from 'src/components/context/AppContextUtils';
import { getHomePageContentDefaultState } from 'src/components/das-finsuite/admin-components/homepage-manage/HomePageManagementUtils';
import { HomepageEntity } from 'src/components/das-finsuite/admin-components/homepage-manage/HomepageModel';
import { TeamPageResponse } from 'src/components/das-finsuite/admin-components/teams-manage/TeamManagementModel';
import { getTeamContentDefaultState } from 'src/components/das-finsuite/admin-components/teams-manage/TeamManagementUtils';
import * as mutations from 'src/graphql/mutations';
import * as queries from 'src/graphql/queries';
import { fetchGraphQLRequest } from './graphql-api';

interface AllAppsWithParsed {
  allApplications: Application[];
  applicationFlatTableEntity: ApplicationFlatTableEntity[];
}

const sortApplications = (apps: Application[]): Application[] =>
  apps.sort((a, b) => (a.applicationName ?? '').localeCompare(b.applicationName ?? ''));

export const fetchAllApplications = async (teamContent: TeamPageResponse): Promise<AllAppsWithParsed> => {
  const { listApplications } = await fetchGraphQLRequest<{ listApplications: Application[] }>({
    query: queries.listApplications,
    variables: {},
    requestName: 'listApplications',
    requestType: 'query'
  });

  return {
    allApplications: sortApplications(listApplications),
    applicationFlatTableEntity: parseAllApplications(listApplications, teamContent)
  };
};

export const fetchApplicationDropdowns = async (teamContent: TeamPageResponse): Promise<ApplicationDropdowns> => {
  const { listLabels } = await fetchGraphQLRequest<{ listLabels: LabelInput[] }>({
    query: queries.listLabels,
    variables: {},
    requestName: 'listLabels',
    requestType: 'query'
  });
  return getApplicationDropdownsInSorted(listLabels, teamContent);
};

export const fetchFavoriteApplications = async (userAlias: string): Promise<string[]> => {
  const { listApplicationFavorites } = await fetchGraphQLRequest<{ listApplicationFavorites: FavoriteApplications[] }>({
    query: queries.listApplicationFavorites,
    variables: { userAlias },
    requestName: 'listApplicationFavorites',
    requestType: 'query'
  });
  return listApplicationFavorites.find((favorite) => favorite.userAlias === userAlias)?.applicationId || [];
};

export const fetchAnnouncements = async (
  listOfApplications: Application[]
): Promise<{
  announcementList: AnnouncementEntity[];
  announcementsFlatTableEntity: AnnouncementFlatTableEntity[];
}> => {
  const { listAnnouncements } = await fetchGraphQLRequest<{ listAnnouncements: AnnouncementEntity[] }>({
    query: queries.listAnnouncements,
    variables: {},
    requestName: 'listAnnouncements',
    requestType: 'query'
  });
  return {
    announcementList: sortAndFilterAnnouncements(listOfApplications, listAnnouncements),
    announcementsFlatTableEntity: parseAllAnnouncements(listOfApplications, listAnnouncements)
  };
};
export const fetchHomePageContent = async (alias: string): Promise<HomepageEntity> => {
  const data = await fetchGraphQLRequest<{ listHomePageContent: HomepageEntity }>({
    query: queries.listHomePageContent,
    variables: {},
    requestName: 'listHomePageContent',
    requestType: 'query'
  });
  return data.listHomePageContent?.id ? data.listHomePageContent : getHomePageContentDefaultState(alias);
};

export const fetchTeamPageContent = async (alias: string): Promise<TeamPageResponse> => {
  const data = await fetchGraphQLRequest<{ listTeamPageContent: any }>({
    query: queries.listTeamPageContent,
    variables: {},
    requestName: 'listTeamPageContent',
    requestType: 'query'
  });

  return data.listTeamPageContent?.id ? data.listTeamPageContent : getTeamContentDefaultState(alias);
};

export const updateHomePageContentMutation = async (homepageEntity: HomepageEntity): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.updateHomePageContent,
    variables: { input: homepageEntity },
    requestName: 'updateHomePageContent',
    requestType: 'mutation'
  });
};

export const updateTeamPageContentMutation = async (teamPageContent: TeamPageResponse): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.updateTeamPageContent,
    variables: { input: teamPageContent },
    requestName: 'updateTeamPageContent',
    requestType: 'mutation'
  });
};
