import { Button, Popover, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

const CONSTANTS = {
  PHONE_TOOL_URL: 'https://phonetool.amazon.com/users/',
  USER_IMG_BASE_URL: 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=',
  SLACK_LINK: `https://my.slack.com/app_redirect?channel=@`,
  OUTLOOK_MAIL_URL: 'mailto:',
  AMAZON_EMAIL_DOMAIN: '@amazon.com'
} as const;

// Types
type Variant = 'small' | 'medium';

interface CircularAvatarProps {
  alias: string;
  size?: number;
  verticalPosition?: string;
}

interface ContactDisplayProps {
  userAlias: string;
  variant?: Variant;
  width?: string;
  imgSize?: number;
}

const CircularAvatar: React.FC<CircularAvatarProps> = React.memo(({ alias, verticalPosition = '30%' }) => (
  <div className="circular-avatar">
    <img src={`${CONSTANTS.USER_IMG_BASE_URL}${alias}`} alt={`User: ${alias}`} style={{ objectPosition: `center ${verticalPosition}` }} />
  </div>
));

CircularAvatar.displayName = 'CircularAvatar';

const ContactActionButtons: React.FC<{ alias: string }> = ({ alias }) => {
  const openOutlookEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    const email = `${alias}${CONSTANTS.AMAZON_EMAIL_DOMAIN}`;
    const outlookUrl = `${CONSTANTS.OUTLOOK_MAIL_URL}${email}`;
    window.open(outlookUrl, '_blank');
  };

  return (
    <SpaceBetween size="m" direction="horizontal">
      <Button
        ariaLabel="Phone Tool Link (opens new tab)"
        href={`${CONSTANTS.PHONE_TOOL_URL}${alias}`}
        iconAlign="right"
        iconName="anchor-link"
        target="_blank"
      />
      <Button ariaLabel="Outlook (opens new email)" onClick={(e: any) => openOutlookEmail(e)} iconAlign="right" iconName="envelope" target="_blank" />
      <Button ariaLabel="Slack Link (opens new tab)" href={`${CONSTANTS.SLACK_LINK}${alias}`} iconAlign="right" iconName="contact" target="_blank" />
    </SpaceBetween>
  );
};

const ContactNameTag: React.FC<{ alias: string }> = ({ alias }) => (
  <div className="contact-name-tag">
    <CircularAvatar alias={alias} />
    <Button variant="inline-link">{alias}</Button>
  </div>
);

export const ContactList: React.FC<ContactDisplayProps> = ({ userAlias, variant = 'small' }) => {
  const userAliases = userAlias.split(',').map((user) => user.trim());

  return (
    <div className="contact-list-container">
      <div className="contact-list-wrapper">
        {userAliases.map((alias, index) => (
          <Popover
            key={index}
            dismissButton={false}
            position="bottom"
            size="medium"
            triggerType="custom"
            content={<ContactActionButtons alias={alias} />}
          >
            <ContactNameTag alias={alias} />
          </Popover>
        ))}
      </div>
    </div>
  );
};
