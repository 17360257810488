import {
  AppLayout,
  Box,
  Button,
  Container,
  ContentLayout,
  ExpandableSection,
  Grid,
  Header,
  Select,
  SelectProps,
  SpaceBetween
} from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRedirectInfo, TutorialsFilter } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { DFPHomePageSideNavigation } from 'src/components/dfp-home-page/DFPHomePageSideNavigation';
import { CategoryCheckbox } from 'src/components/generic-components/CategoryCustomCheckboxFilter';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { EmptyState } from 'src/components/generic-components/empty-state';
import { logger } from 'src/logger';
import { UserActionsEventTypes } from '../das-finsuite-constants';
import { getTutorialsBreadcrumbItems } from './TutorialsConfig';
import { VideoThumbnail } from 'src/components/generic-components/VideoThumbnail';

const Tutorials: React.FC = () => {
  const appLayout = useRef<any>();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const { parsedApplications } = useAppContext();
  const [tutorialsEntity, setTutorialsEntity] = useState<TutorialsFilter[]>([]);
  const [tutorialContentsGroupedByApps, setTutorialContentsGroupedByApps] = React.useState<Record<string, TutorialsFilter[]>>({});
  const [appSpecificNavigationState, setAppSpecificNavigationState] = useState<AppRedirectInfo>();

  useEffect(() => {
    const _tutorialsEntity: TutorialsFilter[] = parsedApplications
      .filter((application) => application.isActive)
      .flatMap((application) => {
        return application.tutorialContentDetails?.map((tutorialContent) => {
          return {
            ...tutorialContent,
            applicationName: application.applicationName,
            updatedTime: application.updatedTime
          } as TutorialsFilter;
        });
      });
    setTutorialsEntity(_tutorialsEntity);
    updateGroupedData(_tutorialsEntity);
  }, [parsedApplications]);

  // Filters
  const [selectedApplication, setSelectedApplication] = useState<SelectProps.Option | null>(null);
  const [tutorialCategories, setTutorialCategories] = useState<SelectProps.Options>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [noCategoryMessage, setNoCategoryMessage] = useState<string>();

  // Based on the redirected from, to this page, we will set Breadcrumb
  useEffect(() => {
    setAppSpecificNavigationState({
      from: state?.from,
      redirectFromHomePage: state?.redirectFromHomePage,
      fromAppName: state?.fromAppName,
      fromAppId: state?.fromAppId
    });
    state?.fromAppName && setSelectedApplication({ label: state?.fromAppName, value: state?.fromAppName });
  }, [pathname, state]);

  const handleCategoryChange = (value: string, checked: boolean) => {
    setSelectedCategories((prevSelected) => {
      if (checked) {
        return [...prevSelected, value];
      } else {
        return prevSelected.filter((category) => category !== value);
      }
    });
    logger.info(`DaS FinSuite -> User Action: Tutorials -> Tutorials Filter Category Change`, {
      event_type: UserActionsEventTypes.TUTORIALS_FILTER_BY_CATEGORY,
      value: value
    });
  };

  const clearAllFilters = () => {
    setSelectedApplication(null);
    setSelectedCategories([]);
    updateGroupedData(tutorialsEntity);
  };

  // Populate Category filter, based on Application selection
  useEffect(() => {
    if (selectedApplication) {
      const uniqueCategories: any = Array.from(
        new Set(
          parsedApplications
            .filter((application) => application.isActive)
            .filter((application) => application.applicationName === selectedApplication.label)
            .flatMap((application) => application.tutorialContentDetails?.map((detail) => detail.category))
        )
      )
        .filter((category) => category !== null) // Remove null values
        .map((category) => ({ label: category as string, value: category as string }));

      setTutorialCategories(uniqueCategories);
      setNoCategoryMessage(uniqueCategories?.length === 0 ? 'No categories found' : '');
    } else {
      setTutorialCategories([]);
      setNoCategoryMessage('Please select an application');
    }
  }, [selectedApplication]);

  // Based on Application & Category filter selection, populate query
  useEffect(() => {
    if (selectedApplication) {
      const filteredTutorialsByApplication = tutorialsEntity.filter((tutorial) => tutorial.applicationName === selectedApplication.label);

      if (selectedCategories.length === 0) {
        updateGroupedData(filteredTutorialsByApplication);
        return;
      } else {
        const filteredTutorialsByApplicationAndCategory = filteredTutorialsByApplication.filter((tutorial) =>
          selectedCategories.includes(tutorial.category || '')
        );
        updateGroupedData(filteredTutorialsByApplicationAndCategory);
        return;
      }
    }
  }, [selectedApplication, selectedCategories]);

  // Grouping tutorials based on App
  const updateGroupedData = (dataToBeGrouped: TutorialsFilter[]) => {
    const groupedData = dataToBeGrouped.reduce((accumulator: { [key: string]: any[] }, item) => {
      const { applicationName, ...rest } = item;
      if (!accumulator[applicationName]) {
        accumulator[applicationName] = [];
      }
      accumulator[applicationName].push(rest);
      return accumulator;
    }, {});
    setTutorialContentsGroupedByApps(groupedData);
  };

  return (
    <>
      <AppLayout
        ref={appLayout}
        headerSelector="#h"
        contentType="cards"
        toolsHide={true}
        navigation={<DFPHomePageSideNavigation />}
        breadcrumbs={
          <>
            {(state?.redirectFromHomePage as boolean) && <DFPBreadcrumbs items={getTutorialsBreadcrumbItems()} />}
            {(!state?.redirectFromHomePage as boolean) && (
              <Button
                className="custom-breadcrumb-button"
                variant="link"
                iconAlign="left"
                iconName="angle-left"
                onClick={() => navigate(appSpecificNavigationState?.from || '')}
              >
                {`Back to ${state?.fromAppName || ''}`}
              </Button>
            )}
          </>
        }
        content={
          <ContentLayout disableOverlap header={<Header variant="h1">{'Tutorials'}</Header>}>
            <Box margin={{ top: 'l', bottom: 'l' }}>
              <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                <Container>
                  <SpaceBetween size="m" direction="vertical">
                    <Button formAction="none" variant="link" onClick={clearAllFilters}>
                      Clear all filters
                    </Button>

                    <ExpandableSection headerText={'Filter by application'} defaultExpanded>
                      <Box padding={{ left: 'm', right: 'm' }} margin={{ right: 'm' }}>
                        <Select
                          placeholder="Select Application"
                          expandToViewport
                          options={parsedApplications
                            .filter((application) => application.isActive)
                            ?.sort((a, b) => a.applicationName.localeCompare(b.applicationName))
                            ?.map((application) => {
                              return {
                                value: application.applicationName,
                                label: application.applicationName
                              } as { label: string; value: string };
                            })}
                          selectedOption={selectedApplication}
                          filteringType="auto"
                          onChange={({ detail }) => {
                            setSelectedApplication(detail.selectedOption);
                            logger.info(`DaS FinSuite -> User Action: Tutorials -> Application Filter Change`, {
                              event_type: UserActionsEventTypes.TUTORIALS_FILTER_BY_APP,
                              value: detail.selectedOption.label
                            });
                          }}
                        ></Select>
                      </Box>
                    </ExpandableSection>

                    <ExpandableSection headerText={'Filter by category'} defaultExpanded>
                      <Box padding={{ left: 'm' }}>
                        {tutorialCategories.length === 0 && <Box color="text-body-secondary">{noCategoryMessage}</Box>}
                        {tutorialCategories.length !== 0 &&
                          tutorialCategories?.map((category) => (
                            <CategoryCheckbox
                              key={category.label}
                              category={category}
                              onChange={handleCategoryChange}
                              checked={selectedCategories.includes(category.label || '')}
                            />
                          ))}
                      </Box>
                    </ExpandableSection>
                  </SpaceBetween>
                </Container>

                <div>
                  {tutorialsEntity.length === 0 && <EmptyState title="No data" description="Please select/change filter." verticalCenter={true} />}
                  {Object.entries(tutorialContentsGroupedByApps).length === 0 && (
                    <EmptyState title="No tutorials found" description="" verticalCenter={true} />
                  )}
                  {Object.keys(tutorialContentsGroupedByApps).length !== 0 &&
                    Object.entries(tutorialContentsGroupedByApps).map(([appName, apps], index) => (
                      <SpaceBetween size="l" direction="vertical" key={index}>
                        <Header headingTagOverride="h4" className="margin-top-10px" counter={`(${apps.length})`}>
                          {appName}
                        </Header>
                        <SpaceBetween size="m" direction="horizontal">
                          {apps.map((app, _index) => (
                            <>
                              <VideoThumbnail
                                key={_index}
                                link={app.link}
                                displayTitle={app.displayTitle}
                                category={app.category}
                                showPlayIcon={false}
                              />
                            </>
                          ))}
                        </SpaceBetween>
                      </SpaceBetween>
                    ))}
                </div>
              </Grid>
            </Box>
          </ContentLayout>
        }
      />
    </>
  );
};

export default Tutorials;
