import { SecretsManagerClient, GetSecretValueCommand, SecretsManagerClientConfig, GetSecretValueResponse } from '@aws-sdk/client-secrets-manager';
import { CognitoService } from './CognitoService';

/**
 * Retrieves a secret from AWS Secrets Manager
 * @param secretArn - The ARN of the secret to retrieve
 * @param region - AWS region
 * @returns Promise<GetSecretValueResponse | undefined>
 */
export const getSecretValue = async (secretArn: string, region: string): Promise<GetSecretValueResponse | undefined> => {
  try {
    if (!secretArn) {
      throw new Error('Secret ARN is required');
    }

    const clientConfig: SecretsManagerClientConfig = {
      region,
      credentials: await CognitoService.getAWSServiceCredentials()
    };

    const secretsManagerClient = new SecretsManagerClient(clientConfig);
    const command = new GetSecretValueCommand({ SecretId: secretArn });

    return await secretsManagerClient.send(command);
  } catch (error) {
    console.error('Error retrieving secret:', error);
    throw error;
  }
};
