import { Container, FormField, Header, Input, SpaceBetween, Tabs, Textarea } from '@amzn/awsui-components-react';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { WelcomeContentDetailsEntity } from '../../AdminModels';
import WelcomeLinksContentForm, { WelcomeLinksContentFormMethods } from '../../applications/admin-manage-application/WelcomeLinksContentForm';
import { WelcomeContentTable } from '../../applications/admin-manage-application/WelcomeWidgetTable';
import { TeamEntityResponse, TeamPageWizard } from '../TeamManagementModel';

interface TeamPageStep2Props {
  formik: FormikProps<TeamPageWizard>;
}

const TeamContent: React.FC<{ team: TeamEntityResponse; index: number; formik: FormikProps<TeamPageWizard> }> = ({ team, index, formik }) => {
  const [welcomeContentDetails, setWelcomeContentDetails] = useState<WelcomeContentDetailsEntity[]>(team.teamWelcomeDetails ?? []);
  const [selectedWelcomeContentDetails, setSelectedWelcomeContentDetails] = useState<WelcomeContentDetailsEntity[]>([]);
  const welcomeContentFormRef = React.useRef<WelcomeLinksContentFormMethods>(null);

  const getFieldError = useCallback(
    (fieldName: string): string | undefined => {
      const paths = [`teamPageDetailsAndWidgets.teams[${index}].${fieldName}`];

      for (const path of paths) {
        const touchedField = formik.getFieldMeta(path).touched;
        const errorField = formik.getFieldMeta(path).error;
        if (touchedField && errorField) {
          return errorField;
        }
      }

      return undefined;
    },
    [formik, index]
  );

  const handleChange = (field: string, value: string) => {
    formik.setFieldValue(`teamPageDetailsAndWidgets.teams[${index}].${field}`, value);
  };

  const handleBlur = (field: string) => {
    formik.setFieldTouched(`teamPageDetailsAndWidgets.teams[${index}].${field}`, true);
    formik.validateField(`teamPageDetailsAndWidgets.teams[${index}].${field}`);
  };

  const handleWelcomeContentUpdate = (updatedContent: WelcomeContentDetailsEntity[]) => {
    setWelcomeContentDetails(updatedContent);
    formik.setFieldValue(`teamPageDetailsAndWidgets.teams[${index}].teamWelcomeDetails`, updatedContent);
  };

  const addNewWelcomeContent = (newContent: WelcomeContentDetailsEntity) => {
    handleWelcomeContentUpdate([...welcomeContentDetails, newContent]);
  };

  const removeSelectedWelcomeContent = () => {
    const updatedContent = welcomeContentDetails.filter((obj) => !selectedWelcomeContentDetails.includes(obj));
    handleWelcomeContentUpdate(updatedContent);
    setSelectedWelcomeContentDetails([]);
  };

  const updateWelcomeContent = (welcomeContent: WelcomeContentDetailsEntity) => {
    const updatedContent = welcomeContentDetails.map((obj) => (obj.id === welcomeContent.id ? welcomeContent : obj));
    handleWelcomeContentUpdate(updatedContent);
  };

  const editWelcomeContent = () => {
    if (welcomeContentFormRef.current && selectedWelcomeContentDetails[0]) {
      welcomeContentFormRef.current.editSelectedRow(selectedWelcomeContentDetails[0]);
      setSelectedWelcomeContentDetails([]);
    }
  };

  return (
    <SpaceBetween size="l" direction="vertical">
      <FormField label="Introductory video URL" errorText={getFieldError('teamIntroductionVideoUrl')}>
        <Input
          placeholder="https://broadcast.amazon.com/videos/1186778"
          value={team.teamIntroductionVideoUrl}
          onChange={({ detail }) => handleChange('teamIntroductionVideoUrl', detail.value)}
          onBlur={() => handleBlur('teamIntroductionVideoUrl')}
        />
      </FormField>

      <FormField label="Team overview" errorText={getFieldError('teamOverView')}>
        <Textarea
          value={team.teamOverView}
          onChange={({ detail }) => handleChange('teamOverView', detail.value)}
          onBlur={() => handleBlur('teamOverView')}
        />
      </FormField>

      <div className="horizontal-bar" />

      <Header variant="h3" description="Provide users with key quick links (e.g., documentation, wikis) to help introduce your team's resources">
        Add a link
      </Header>

      <WelcomeLinksContentForm
        ref={welcomeContentFormRef}
        showWelcomeContentModal={false}
        hasWelcomeContent={true}
        addNewWelcomeContent={addNewWelcomeContent}
        updateRow={updateWelcomeContent}
      />

      <WelcomeContentTable
        tableDescriptionForErrors={<></>}
        editWelcomeContent={editWelcomeContent}
        selectedWelcomeContentDetails={selectedWelcomeContentDetails}
        deleteSelected={removeSelectedWelcomeContent}
        welcomeContentDetails={welcomeContentDetails}
        setSelectedWelcomeContentDetails={setSelectedWelcomeContentDetails}
      />
    </SpaceBetween>
  );
};

export const TeamPageStep2: React.FC<TeamPageStep2Props> = ({ formik }) => {
  const renderHeader = () => (
    <Header
      variant="h2"
      description="Show tutorials or training videos for this app (8 links are showcased on the widget itself and the app's tutorial page will display all links)"
    >
      Team info tabs
    </Header>
  );

  const renderTeamContent = (team: TeamEntityResponse, index: number) => {
    return {
      id: team.id,
      label: team.teamName,
      content: <TeamContent team={team} index={index} formik={formik} />
    };
  };

  return (
    <SpaceBetween size="m" direction="vertical">
      <Container variant="stacked" header={renderHeader()}>
        <Tabs
          variant="default"
          key={formik.values.teamPageDetailsAndWidgets.teams.length}
          tabs={formik.values.teamPageDetailsAndWidgets.teams.map(renderTeamContent)}
        />
      </Container>
    </SpaceBetween>
  );
};
