import { useMemo, useState } from 'react';
import { eUserPreferenceKeys } from 'src/constants/AppConstants';
import { load, save } from 'src/utilities/LocalStorage';

/**
 * Interface representing a column with an ID and dynamic properties
 */
interface Column {
  id: string;
  [key: string]: any;
}

/**
 * Custom hook to manage and persist column widths in local storage
 * @param storageKey - Key used to store/retrieve values from local storage
 * @param columnDefinitions - Array of column definitions containing column properties
 * @returns Tuple containing memoized column definitions with widths and width change handler
 */
export function useColumnWidths(storageKey: eUserPreferenceKeys, columnDefinitions: any[]) {
  const [widths, saveWidths] = useLocalStorage<Column[]>(storageKey);

  const handleWidthChange = (event: { detail: { widths: number[] } }) => {
    saveWidths(mapWithColumnDefinitionIds(columnDefinitions, 'width', event.detail.widths));
  };

  const memoDefinitions = useMemo(() => addToColumnDefinitions(columnDefinitions, 'width', widths), [widths, columnDefinitions]);

  return [memoDefinitions, handleWidthChange] as const;
}

/**
 * Custom hook to persist and retrieve values from local storage
 * @param key - Storage key to store/retrieve values
 * @param defaultValue - Optional default value if nothing exists in storage
 * @returns Tuple containing stored value and setter function
 */
export function useLocalStorage<T>(key: string, defaultValue?: T) {
  const [value, setValue] = useState<T>(() => load(key) ?? defaultValue);

  const handleValueChange = (newValue: T): void => {
    setValue(newValue);
    save(key, newValue);
  };

  return [value, handleValueChange] as const;
}

/**
 * Merges column properties from stored columns into column definitions
 * @param columnDefinitions - Array of column definitions
 * @param propertyName - Name of the property to merge
 * @param columns - Array of stored column data
 * @returns Array of column definitions with merged properties
 */
export const addToColumnDefinitions = (columnDefinitions: any[], propertyName: string, columns: Column[] | null): any[] =>
  columnDefinitions.map((colDef) => {
    const column = columns?.find((col) => col.id === colDef.id);
    return {
      ...colDef,
      [propertyName]: column?.[propertyName] || colDef[propertyName]
    };
  });

/**
 * Maps column IDs with their corresponding property values
 * @param columnDefinitions - Array of column definitions
 * @param propertyName - Name of the property to map
 * @param items - Array of values to map to columns
 * @returns Array of columns with mapped property values
 */
export const mapWithColumnDefinitionIds = (columnDefinitions: any[], propertyName: string, items: number[]): Column[] =>
  columnDefinitions.map(({ id }, index) => ({
    id,
    [propertyName]: items[index]
  }));
