import { FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateTeamPageContentMutation } from 'src/api/app-sync-services';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { eFinSuiteAdminMessages } from 'src/constants/AppConstants';
import { logger } from 'src/logger';
import { infoPanelPageConfigurationSchema } from '../../homepage-manage/HomePageManagementSchema';
import { closeModal, homepageNavigateBackToFrom } from '../../homepage-manage/HomePageManagementUtils';
import { TeamPageWizard } from '../TeamManagementModel';
import { teamPageContentWizardToAPI } from '../TeamManagementUtils';
import { TeamManagementSchema, TeamPageDetailsAndWidgetsSchema } from '../TeamPageWizardSchema';
import { FinSuiteAppMessages } from '../../AdminMessageConstants';

export const useTeamPageWizard = () => {
  const navigate = useNavigate();
  const { teamPageContent, setTeamPageContent } = useAppContext();
  const teamPageFormRef = useRef<FormikProps<TeamPageWizard>>(null);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });
  const [stepError, setStepError] = useState('');

  const setActiveStepIndexAndCloseTools = (index: any) => {
    setActiveStepIndex(index);
  };

  const onCancel = () => {
    closeModal(setShowModalInfo);
  };

  const onConfirm = async (showModalInfo: ShowModalInfo) => {
    showModalInfo.eventContentType === 'CANCEL_APPLICATION' && homepageNavigateBackToFrom('cancel', '', '/admin', navigate);
  };

  const seTeamsAsTouched = () => {
    teamPageFormRef.current?.setFieldTouched('teamManagement', true);
  };

  const setTeamPageDetailsAndWidgetsAreValid = () => {
    teamPageFormRef.current?.setFieldTouched('teamPageDetailsAndWidgets', true);
  };

  const setInfoPanelDetailsAsTouched = () => {
    teamPageFormRef.current?.setTouched({
      infoPanelPageConfiguration: {
        sidePanelDetails: {
          keyContacts: {
            isEnabled: true,
            title: true,
            isExpandedByDefault: true,
            contentType: true,
            richTextContent: true
          },
          officeHours: {
            isEnabled: true,
            title: true,
            isExpandedByDefault: true,
            contentType: true,
            richTextContent: true
          },
          customSidePanelWidgets: true
        }
      }
    });
  };

  const onNavigate = async (evt: any, activeStepIndex: number) => {
    const requestedStepIndex = evt.detail.requestedStepIndex;
    const directionReason = evt.detail.reason;

    const teamsAreValid = await TeamManagementSchema.isValid(teamPageFormRef.current?.values?.teamManagement);
    const teamPageDetailsAreValid = await TeamPageDetailsAndWidgetsSchema.isValid(teamPageFormRef.current?.values?.teamPageDetailsAndWidgets);
    const infoPanelDetailsAreValid = await infoPanelPageConfigurationSchema.isValid(teamPageFormRef.current?.values?.infoPanelPageConfiguration);

    if (activeStepIndex === 0) {
      if (teamsAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        seTeamsAsTouched();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }

    if (activeStepIndex === 1) {
      if (teamPageDetailsAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        setTeamPageDetailsAndWidgetsAreValid();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }

    if (directionReason === 'previous' && requestedStepIndex === 1) {
      if (infoPanelDetailsAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        setInfoPanelDetailsAsTouched();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }
  };

  const onWizardCancel = () => {
    setShowModalInfo({
      showModal: true,
      eventHeader: 'Cancel',
      eventContentType: 'CANCEL_APPLICATION',
      messageInfo: {
        applicationName: 'Homepage content'
      }
    });
  };

  const onSubmit = async () => {
    // Last Step validation happens while Submit
    const infoPanelDetailsPageAreValid = await infoPanelPageConfigurationSchema.isValid(teamPageFormRef.current?.values?.infoPanelPageConfiguration);
    if (!infoPanelDetailsPageAreValid) {
      setInfoPanelDetailsAsTouched();
      setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      return;
    }

    teamPageFormRef.current
      ?.validateForm()
      .then(async () => {
        if (teamPageFormRef.current?.values && teamPageFormRef.current?.isValid) {
          setStepError('');

          const teamPageContent = teamPageContentWizardToAPI(teamPageFormRef.current?.values);

          updateTeamPageContentMutation(teamPageContent)
            .then(async (response: any) => {
              logger.info(`Successfully updated Team Page content.`, { info: response });

              setTeamPageContent(teamPageContent);
              homepageNavigateBackToFrom('success', FinSuiteAppMessages.SUCCESSFULLY_UPDATES_TEAM_PAGE_CONTENT, '/admin', navigate);
            })
            .catch(() => {
              logger.error(`Unable to updated Team Page content.`);
              setStepError(FinSuiteAppMessages.UNSUCCESSFULLY_TEAM_PAGE_CONTENT);
            });
        }
      })
      .catch((error: any) => {
        console.error('Error validating form:', error);
      });
  };

  return {
    activeStepIndex,
    setActiveStepIndexAndCloseTools,
    stepError,
    onNavigate,
    onWizardCancel,
    teamPageFormRef,
    showModalInfo,
    onCancel,
    onConfirm,
    onSubmit
  };
};
