import { Button, FormField, Input, SpaceBetween } from '@amzn/awsui-components-react';
import { Form, Formik, FormikProps } from 'formik';
import React, { forwardRef, useEffect } from 'react';
import FormFieldPopover from 'src/components/generic-components/FormFieldPopover';
import CustomQuillEditor from 'src/components/generic-components/quill-editor';
import { SIMPLE_TEXT_CONFIG } from 'src/components/generic-components/quill-editor/quill-config';
import { characterCountConstraintMessage } from 'src/utilities/CommonUtilities';
import { v4 as uuidv4 } from 'uuid';
import { WelcomeContentDetailsEntity } from '../../AdminModels';
import { WELCOME_LINK_DISPLAY_NAME_MAX_LENGTH, welcomeContentDetailsSchemaForApplications } from './StepsValidations';

interface WelcomeLinksContentFormProps {
  hasWelcomeContent: boolean;
  showWelcomeContentModal: boolean;
  addNewWelcomeContent: (welcomeContent: WelcomeContentDetailsEntity) => void;
  updateRow: (welcomeContent: WelcomeContentDetailsEntity) => void;
}

export interface WelcomeLinksContentFormMethods {
  editSelectedRow: (editWelcomeContent: WelcomeContentDetailsEntity) => void;
}

const WelcomeLinksContentForm = forwardRef<WelcomeLinksContentFormMethods, WelcomeLinksContentFormProps>(
  ({ hasWelcomeContent, addNewWelcomeContent, showWelcomeContentModal, updateRow }, ref) => {
    const [actionType, setActionType] = React.useState<'add' | 'edit'>('add');
    const WelcomeContentFormRef = React.useRef<FormikProps<WelcomeContentDetailsEntity>>(null);

    const getInitialValues = (): WelcomeContentDetailsEntity => ({
      id: uuidv4(),
      displayText: null,
      link: null,
      description: null,
      pointOfContact: null
    });

    useEffect(() => {
      if (showWelcomeContentModal) {
        WelcomeContentFormRef.current?.resetForm({
          values: getInitialValues()
        });
      }
    }, [showWelcomeContentModal]);

    const handleSubmit = (values: WelcomeContentDetailsEntity) => {
      if (!WelcomeContentFormRef?.current?.isValid) return;

      if (actionType === 'add') {
        addNewWelcomeContent(values);
      } else {
        updateRow(values);
      }

      setTimeout(() => {
        WelcomeContentFormRef.current?.resetForm({
          values: getInitialValues()
        });
        setActionType('add');
      }, 100);
    };

    const editSelectedRow = (editRow: WelcomeContentDetailsEntity) => {
      if (editRow) {
        WelcomeContentFormRef.current?.setValues(editRow);
        setActionType('edit');
      } else {
        WelcomeContentFormRef.current?.resetForm({
          values: getInitialValues()
        });
        setActionType('add');
      }
    };

    React.useImperativeHandle(ref, () => ({
      editSelectedRow
    }));

    return (
      <>
        <Formik
          innerRef={WelcomeContentFormRef}
          initialValues={getInitialValues()}
          validationSchema={welcomeContentDetailsSchemaForApplications}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldTouched, isValid, dirty, submitForm }) => (
            <Form onSubmit={(e) => e.preventDefault()}>
              <SpaceBetween size="m" direction="vertical">
                <div className="inline-table-horizontal-form">
                  <FormField
                    className="welcome-links-display-name-field"
                    label="Display name"
                    errorText={touched.displayText && errors.displayText}
                    stretch={true}
                    constraintText={characterCountConstraintMessage(
                      WELCOME_LINK_DISPLAY_NAME_MAX_LENGTH,
                      values.displayText ? values.displayText.length : 0
                    )}
                    info={<FormFieldPopover header={'Display name'} messages={['Name the link you want displayed']} />}
                  >
                    <Input
                      value={values.displayText || ''}
                      placeholder="Enter display name"
                      disabled={!hasWelcomeContent}
                      onChange={({ detail }) => handleChange({ target: { name: 'displayText', value: detail.value } })}
                      onBlur={async () => setFieldTouched('displayText', true, true)}
                    />
                  </FormField>

                  <FormField
                    className="welcome-links-link-field"
                    label={
                      <>
                        Link <i> - optional</i>
                      </>
                    }
                    constraintText={`Include http:// or https:// in the URL`}
                    errorText={touched.link && errors.link}
                    stretch={true}
                    info={<FormFieldPopover header={'Link'} messages={['Enter the URL of the link you want to add']} />}
                  >
                    <Input
                      value={values.link || ''}
                      placeholder="Paste URL"
                      disabled={!hasWelcomeContent}
                      onChange={({ detail }) => handleChange({ target: { name: 'link', value: detail.value } })}
                      onBlur={async () => setFieldTouched('link', true, true)}
                    />
                  </FormField>

                  <FormField
                    className="flex-grow-field"
                    label={
                      <>
                        Point of contact<i> - optional</i>
                      </>
                    }
                    constraintText={`Max 2 contacts with comma separated`}
                    errorText={touched.pointOfContact && errors.pointOfContact}
                    stretch={true}
                  >
                    <Input
                      value={values.pointOfContact || ''}
                      placeholder="Alias1, Alias2"
                      disabled={!hasWelcomeContent}
                      onChange={({ detail }) =>
                        handleChange({ target: { name: 'pointOfContact', value: detail.value ? detail.value.toLowerCase() : null } })
                      }
                      onBlur={async () => setFieldTouched('pointOfContact', true, true)}
                    />
                  </FormField>
                </div>

                <CustomQuillEditor
                  className="welcome-links-description"
                  label="Description"
                  name="description"
                  isOptional={true}
                  value={values.description || ''}
                  onChange={(name, value) => handleChange({ target: { name, value: value } })}
                  onBlur={() => setFieldTouched('description', true, true)}
                  errorText={touched.description && errors.description ? errors.description : undefined}
                  quillConfig={SIMPLE_TEXT_CONFIG}
                />

                <SpaceBetween size="m" direction="horizontal">
                  <Button formAction="none" onClick={() => submitForm()} disabled={!isValid}>
                    Add
                  </Button>
                </SpaceBetween>
              </SpaceBetween>
            </Form>
          )}
        </Formik>
      </>
    );
  }
);

export default WelcomeLinksContentForm;
