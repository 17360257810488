import { Alert, Box, Checkbox, Container, Header, SpaceBetween, Toggle } from '@amzn/awsui-components-react';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { TableContentWidget } from 'src/components/context/AppContextModels';
import { ConfirmationModal, ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import CustomQuillEditor from 'src/components/generic-components/quill-editor';
import { SIMPLE_TEXT_CONFIG } from 'src/components/generic-components/quill-editor/quill-config';
import { ApplicationWizard } from '../../AdminModels';
import { SupportLinksContentForm, SupportLinksContentFormMethods } from './SupportLinksContentForm';
import { SupportLinksContentTable } from './SupportLinksContentTable';
import { HomePageWizard } from '../../homepage-manage/HomepageModel';

interface HelpPanelConfigurationProps {
  formik: FormikProps<ApplicationWizard | HomePageWizard>;
}

const HelpPanelConfiguration: React.FC<HelpPanelConfigurationProps> = ({ formik }) => {
  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  // report issues
  const reportFormRef = useRef<SupportLinksContentFormMethods>(null);
  const [reportIssues, setReportIssues] = useState<TableContentWidget[]>(
    formik.values?.infoPanelPageConfiguration?.sidePanelDetails?.supportLinks?.tableContent
      ? formik.values?.infoPanelPageConfiguration?.sidePanelDetails?.supportLinks?.tableContent
      : []
  );
  const [selectedReportIssues, setSelectedReportIssues] = useState<TableContentWidget[]>([]);

  const addNewReportIssues = (addNewReportIssues: TableContentWidget) => {
    const newRow: TableContentWidget[] = [addNewReportIssues];
    const finalRows = newRow.concat(reportIssues);
    setReportIssues(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.sidePanelDetails.supportLinks.tableContent', finalRows);
  };

  const editReportIssue = () => {
    if (reportFormRef.current) {
      reportFormRef.current.editSelectedRow(selectedReportIssues[0]);
      setSelectedReportIssues([]);
    }
  };

  const removeSelectedReportIssues = () => {
    const finalRows = reportIssues.filter((obj) => !selectedReportIssues.includes(obj));
    setReportIssues(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.sidePanelDetails.supportLinks.tableContent', finalRows);
    setSelectedReportIssues([]);
  };

  const updateReportIssues = (reportIssue: TableContentWidget) => {
    const finalRows = reportIssues.map((obj) => {
      if (obj.id === reportIssue.id) {
        return reportIssue;
      }
      return obj;
    });
    setReportIssues(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.sidePanelDetails.supportLinks.tableContent', finalRows);
  };

  const clearReportIssues = () => {
    setSelectedReportIssues([]);
  };

  // Confirmation modal
  const onConfirm = (showModalInfo: ShowModalInfo) => {
    switch (showModalInfo.eventContentType) {
      case 'DELETE_REPORT_ISSUES':
        removeSelectedReportIssues();
        closeModal();
        break;
      default:
        break;
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: ''
    });
  };

  return (
    <>
      <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />

      {/* <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}> */}
      <div>
        <Box margin={{ bottom: 'l' }}>
          <Alert statusIconAriaLabel="Info">The detailed app description and video URL can be updated in Step 1</Alert>
        </Box>

        {/* Contacts - Finance    */}
        <Box margin={{ bottom: 'l' }}>
          <Container
            header={
              <Header
                variant="h2"
                description={`Finance & FinTech team key contacts and optional descriptions to differentiate each contact (e.g., by module supported)`}
                actions={
                  <>
                    <Checkbox
                      onChange={({ detail }) => {
                        formik.setFieldValue('infoPanelPageConfiguration.sidePanelDetails.keyContacts.isEnabled', detail.checked);
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('infoPanelPageConfiguration.sidePanelDetails.keyContacts.isEnabled', true);
                      }}
                      checked={formik.values?.infoPanelPageConfiguration?.sidePanelDetails?.keyContacts?.isEnabled || false}
                    >
                      Enable
                    </Checkbox>
                  </>
                }
              >
                {`${
                  formik.values?.infoPanelPageConfiguration?.sidePanelDetails?.keyContacts?.title
                    ? formik.values?.infoPanelPageConfiguration?.sidePanelDetails?.keyContacts?.title
                    : 'Key Contacts'
                }`}
              </Header>
            }
          >
            {/* Key Contacts  */}
            {formik.values.infoPanelPageConfiguration?.sidePanelDetails?.keyContacts?.isEnabled && (
              <SpaceBetween size="m" direction="vertical">
                <Toggle
                  onChange={({ detail }) =>
                    formik.setFieldValue('infoPanelPageConfiguration.sidePanelDetails.keyContacts.isExpandedByDefault', detail.checked)
                  }
                  checked={formik.values.infoPanelPageConfiguration?.sidePanelDetails?.keyContacts?.isExpandedByDefault}
                >
                  Display auto-expanded
                </Toggle>

                <CustomQuillEditor
                  name=""
                  errorText={
                    formik.touched.infoPanelPageConfiguration?.sidePanelDetails?.keyContacts?.richTextContent &&
                    formik.errors.infoPanelPageConfiguration?.sidePanelDetails?.keyContacts?.richTextContent
                      ? formik.errors.infoPanelPageConfiguration?.sidePanelDetails?.keyContacts?.richTextContent
                      : undefined
                  }
                  isOptional={!formik.values.infoPanelPageConfiguration?.sidePanelDetails?.keyContacts?.isEnabled}
                  value={formik.values?.infoPanelPageConfiguration?.sidePanelDetails?.keyContacts?.richTextContent || ''}
                  onChange={(name, value) => formik.setFieldValue('infoPanelPageConfiguration.sidePanelDetails.keyContacts.richTextContent', value)}
                  onBlur={() => formik.setFieldTouched('infoPanelPageConfiguration.sidePanelDetails.keyContacts.richTextContent', true, true)}
                  quillConfig={SIMPLE_TEXT_CONFIG}
                />
              </SpaceBetween>
            )}
          </Container>
        </Box>

        {/* Office Hours */}
        <Box margin={{ bottom: 'l' }}>
          <Container
            header={
              <Header
                variant="h2"
                description={`Recurring meetings or one-time event for users to learn, ask questions, or provide feedback about the app`}
                actions={
                  <>
                    <Checkbox
                      onChange={({ detail }) => {
                        formik.setFieldValue('infoPanelPageConfiguration.sidePanelDetails.officeHours.isEnabled', detail.checked);
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('infoPanelPageConfiguration.sidePanelDetails.officeHours.isEnabled', true);
                      }}
                      checked={formik.values?.infoPanelPageConfiguration.sidePanelDetails.officeHours.isEnabled || false}
                    >
                      Enable
                    </Checkbox>
                  </>
                }
              >
                Office Hours
              </Header>
            }
          >
            {formik.values.infoPanelPageConfiguration?.sidePanelDetails.officeHours.isEnabled && (
              <SpaceBetween size="m" direction="vertical">
                <Toggle
                  onChange={({ detail }) =>
                    formik.setFieldValue('infoPanelPageConfiguration.sidePanelDetails.officeHours.isExpandedByDefault', detail.checked)
                  }
                  checked={formik.values.infoPanelPageConfiguration?.sidePanelDetails?.officeHours?.isExpandedByDefault}
                >
                  Display auto-expanded
                </Toggle>

                <CustomQuillEditor
                  label=""
                  name=""
                  errorText={
                    formik.touched.infoPanelPageConfiguration?.sidePanelDetails?.officeHours?.richTextContent &&
                    formik.errors.infoPanelPageConfiguration?.sidePanelDetails?.officeHours?.richTextContent
                      ? formik.errors.infoPanelPageConfiguration?.sidePanelDetails?.officeHours?.richTextContent
                      : undefined
                  }
                  isOptional={!formik.values.infoPanelPageConfiguration?.sidePanelDetails?.officeHours?.isEnabled}
                  value={formik.values?.infoPanelPageConfiguration?.sidePanelDetails?.officeHours?.richTextContent || ''}
                  onChange={(name, value) => formik.setFieldValue('infoPanelPageConfiguration.sidePanelDetails.officeHours.richTextContent', value)}
                  onBlur={() => formik.setFieldTouched('infoPanelPageConfiguration.sidePanelDetails.officeHours.richTextContent', true, true)}
                  quillConfig={SIMPLE_TEXT_CONFIG}
                />
              </SpaceBetween>
            )}
          </Container>
        </Box>

        {/* Support Links  */}
        <Box margin={{ bottom: 'l' }}>
          <Container
            header={
              <Header
                variant="h2"
                description={`Support ticket links for reporting bugs or requesting additional assistance will appear in the left-side navigation, below the menu items.`}
                actions={
                  <>
                    <Checkbox
                      onChange={({ detail }) => {
                        formik.setFieldValue('infoPanelPageConfiguration.sidePanelDetails.supportLinks.isEnabled', detail.checked);
                        clearReportIssues();
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('infoPanelPageConfiguration.sidePanelDetails.supportLinks.isEnabled', true);
                      }}
                      checked={formik.values?.infoPanelPageConfiguration?.sidePanelDetails?.supportLinks?.isEnabled || false}
                    >
                      Enable
                    </Checkbox>
                  </>
                }
              >
                Support Links
              </Header>
            }
          >
            {formik.values.infoPanelPageConfiguration?.sidePanelDetails?.supportLinks?.isEnabled && (
              <SpaceBetween size="m" direction="vertical">
                <SupportLinksContentForm
                  ref={reportFormRef}
                  hasEntities={formik.values.infoPanelPageConfiguration?.sidePanelDetails?.supportLinks?.isEnabled || false}
                  addNewEntity={addNewReportIssues}
                  updateEntity={updateReportIssues}
                ></SupportLinksContentForm>

                <SupportLinksContentTable
                  formik={formik}
                  editReportIssue={editReportIssue}
                  selectedReportIssues={selectedReportIssues}
                  setShowModalInfo={setShowModalInfo}
                  reportIssues={reportIssues}
                  setSelectedReportIssues={setSelectedReportIssues}
                />
              </SpaceBetween>
            )}
          </Container>
        </Box>
      </div>
    </>
  );
};

export default HelpPanelConfiguration;
