import Board, { BoardProps } from '@amzn/awsui-board-components/board';
import BoardItem from '@amzn/awsui-board-components/board-item';
import { AppLayout, AppLayoutProps, ButtonDropdown, ContentLayout, Flashbar, FlashbarProps, Tabs } from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingStatus } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { DFPHomePageSideNavigation } from 'src/components/dfp-home-page/DFPHomePageSideNavigation';
import EmptyBoard from 'src/components/generic-components/empty-board/EmptyBoard';
import { appLayoutAriaLabels } from 'src/i18n-strings';
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';
import { TeamPageResponse } from '../admin-components/teams-manage/TeamManagementModel';
import { TeamHeader, TeamTools } from './TeamPageHelpers';
import { TeamTabContent } from './TeamTabContent';

export const DFPTeamsHomePageV2: React.FC = () => {
  const appLayoutRef = useRef<AppLayoutProps.Ref>(null);
  const { contextLoadingStatus, teamPageContent } = useAppContext();
  const [flashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(undefined);

  const [items, setItems] = useState<BoardProps.Item<TeamPageResponse>[]>([]);

  useEffect(() => {
    if (contextLoadingStatus === LoadingStatus.Completed && teamPageContent) {
      setItems([
        {
          id: 'teams-board-item',
          data: teamPageContent,
          rowSpan: 5,
          columnSpan: 3,
          definition: { defaultColumnSpan: 3, defaultRowSpan: 5, minRowSpan: 5, minColumnSpan: 3 }
        }
      ]);
      if (teamPageContent.teams.length > 0) {
        setSelectedTeamId(teamPageContent.teams[0].id);
      }
    }
  }, [contextLoadingStatus, teamPageContent]);

  const handleToolsChange = ({ detail: { open } }: { detail: { open: boolean } }) => {
    setToolsOpen(open);
  };

  const handleItemsChange = (event: CustomEvent<BoardProps.ItemsChangeDetail<TeamPageResponse>>) => {
    setItems([...event.detail.items]);
  };

  const renderBoardItem = (item: BoardProps.Item<TeamPageResponse>) => {
    const { isAdmin } = useAuth();
    const navigate = useNavigate();

    const selectedTeam = item.data.teams.find((team) => team.id === selectedTeamId);
    const [actions, setActions] = useState<
      Array<{
        id: string;
        placeOfOrigin: string;
        text: string;
        onClick: () => void;
      }>
    >([]);

    useEffect(() => {
      if (isAdmin) {
        setActions([
          {
            id: 'dfs_team_page_edit',
            placeOfOrigin: 'dfs_team',
            text: 'Edit in console',
            onClick: () => navigate('/admin/manage-team-page')
          }
        ]);
      }
    }, [item.id, isAdmin, navigate]);

    const handleActionClick = (actionId: string) => {
      const action = actions.find((a) => a.text === actionId);
      if (action) {
        action.onClick();
      }
    };

    const dropdownItems = [
      ...actions.map((action) => ({
        id: action.text,
        text: action.text
      }))
    ];

    return (
      <BoardItem
        header={
          <Tabs
            disableContentPaddings
            tabs={item.data.teams.map((team) => ({
              label: team.teamName,
              id: team.id
            }))}
            activeTabId={selectedTeamId || undefined}
            onChange={({ detail }) => setSelectedTeamId(detail.activeTabId)}
          />
        }
        i18nStrings={boardItemI18nStrings}
        settings={
          isAdmin && (
            <ButtonDropdown
              items={dropdownItems}
              ariaLabel="Widget settings"
              variant="icon"
              onItemClick={(event) => handleActionClick(event.detail.id)}
            />
          )
        }
      >
        {selectedTeam && <TeamTabContent team={selectedTeam} />}
      </BoardItem>
    );
  };
  return (
    <AppLayout
      ref={appLayoutRef}
      headerSelector="#h"
      contentType="dashboard"
      ariaLabels={appLayoutAriaLabels}
      navigation={<DFPHomePageSideNavigation />}
      stickyNotifications
      notifications={<Flashbar items={flashbarItems} />}
      tools={<TeamTools sidePanelDetails={teamPageContent.sidePanelDetails} />}
      toolsOpen={toolsOpen}
      onToolsChange={handleToolsChange}
      maxContentWidth={Number.MAX_VALUE}
      disableContentPaddings
      content={
        <div className="app-layout-body">
          <ContentLayout defaultPadding header={<TeamHeader onInfoClick={() => setToolsOpen(true)} />}>
            <Board<TeamPageResponse>
              empty={<EmptyBoard />}
              renderItem={renderBoardItem}
              onItemsChange={handleItemsChange}
              items={items}
              i18nStrings={boardI18nStrings}
            />
          </ContentLayout>
        </div>
      }
    />
  );
};
