import { Board, BoardProps } from '@amzn/awsui-board-components';
import { Box, ContentLayout, Flashbar, FlashbarProps, SplitPanel } from '@amzn/awsui-components-react';
import AppLayout, { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useEffect, useRef, useState } from 'react';
import { eLocalStorageKeys } from 'src/constants/AppConstants';
import { appLayoutAriaLabels, splitPanelI18nStrings } from 'src/i18n-strings';
import { boardI18nStrings } from 'src/i18n-strings/board-I18nStrings';
import { AnnouncementEntity, AppNavigationState, LoadingStatus, StoredWidgetPlacement } from '../context/AppContextModels';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContextProvider';
import { WelcomeContentEntity } from '../das-finsuite/admin-components/AdminModels';
import { WelcomeContentModal } from '../das-finsuite/admin-components/applications/admin-manage-application/WelcomeContentModal';
import { exportLayout } from '../das-finsuite/configurable-dashboard/Utils/ConfigurableDashboardUtils';
import { ResetButton } from '../das-finsuite/configurable-dashboard/components/ResetButton';
import { ConfigurableWidget } from '../das-finsuite/configurable-dashboard/components/configurable-widget';
import Palette from '../das-finsuite/configurable-dashboard/components/palette';
import { AnnouncementWidget } from '../das-finsuite/widgets/announcements-widget/AnnouncementWidget';
import { WidgetConfig, WidgetDataType } from '../das-finsuite/widgets/interface';
import { WelcomeWidget } from '../das-finsuite/widgets/welcome-widget/WelcomeWidget';
import { DFPBreadcrumbs } from '../generic-components/DFPBreadcrumb';
import { EmptyState } from '../generic-components/empty-state';
import { DFPHomePageSideNavigation } from './DFPHomePageSideNavigation';
import { useDFPHomePageItemsLayout } from './UseDFPHomePageItemsLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { InfoPanelDetailsHelpPanel } from '../das-finsuite/admin-components/applications/admin-manage-application/InfoPanelDetails';

export const DFPHomeBreadcrumbs = [
  {
    text: 'DaS FinSuite',
    href: '/'
  }
];
const splitPanelMaxSize = 360;

export const DFPHomePage = () => {
  const { isAdmin } = useAuth();
  const hasAdminPrivileges = isAdmin;

  const navigate = useNavigate();
  const location = useLocation();
  const navigationStateMessage = location.state as AppNavigationState;

  const [toolsOpen, setToolsOpen] = useState(false);
  const appLayoutRef = useRef<AppLayoutProps.Ref>(null);
  const appContext = useAppContext();
  const allActiveAnnouncements: AnnouncementEntity[] = appContext.announcements.filter((item) => item.itemMetadata.isActive);
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [showWelcomeContentModal, setShowWelcomeContentModal] = useState(false);

  const [ref, layout, setLayout, resetLayout] = useDFPHomePageItemsLayout(eLocalStorageKeys.DFP_HOME_PAGE_WIDGETS_LAYOUT);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [splitPanelSize, setSplitPanelSize] = useState(360);

  const latestFiveActiveAnnouncements = allActiveAnnouncements.slice(0, 5);

  const [dfpHomePageWelcomeContent, setDFPHomePageWelcomeContent] = useState<WelcomeContentEntity>();

  // Handle navigation state message and clear state after displaying
  useEffect(() => {
    const handleNavigationMessage = () => {
      if (!navigationStateMessage?.message) return;

      setFlashbarItems([
        {
          content: navigationStateMessage.message.content,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          id: '1',
          onDismiss: () => setFlashbarItems([]),
          type: navigationStateMessage.message.type
        }
      ]);

      window.history.replaceState({}, document.title);
    };

    handleNavigationMessage();
  }, [navigationStateMessage]);

  // Handle context loading error state
  useEffect(() => {
    const handleContextError = () => {
      if (appContext.contextLoadingStatus !== LoadingStatus.Failed) return;

      setFlashbarItems([
        {
          id: 'context_loading_error',
          header: 'Error loading context',
          type: 'error',
          content: appContext.contextLoadingError,
          dismissible: false
        }
      ]);
    };

    handleContextError();
  }, [appContext.contextLoadingStatus]);

  useEffect(() => {
    if (appContext.contextLoadingStatus === LoadingStatus.Completed) {
      setDFPHomePageWelcomeContent({
        welcomeTitle: appContext.homePageContent.welcomeTitle,
        welcomeContentDetails: appContext.homePageContent.welcomeContentDetails
      });
    }
  }, [appContext, appContext.contextLoadingStatus]);

  const welcomeWidget = WelcomeWidget({
    welcomeTitle: dfpHomePageWelcomeContent?.welcomeTitle || 'Welcome',
    description: '',
    welcomeContentDetails: dfpHomePageWelcomeContent?.welcomeContentDetails || []
  });
  const announcementWidget = AnnouncementWidget('Announcements', '', latestFiveActiveAnnouncements, {
    from: '/',
    redirectFromHomePage: true
  });

  const allWidgetsOfDFPHomePage: Record<string, WidgetConfig> = {
    welcomeWidget,
    announcementWidget
  };

  const getDFPBoardWidgets = (layout: ReadonlyArray<StoredWidgetPlacement>) => {
    return layout.map((position) => {
      const widget = allWidgetsOfDFPHomePage[position.id];
      return {
        ...position,
        ...widget,
        columnSpan: position.columnSpan ?? widget.definition?.defaultColumnSpan ?? 1,
        rowSpan: position.rowSpan ?? widget.definition?.defaultRowSpan ?? 2
      };
    });
  };

  const getDFPPaletteWidgets = (layout: ReadonlyArray<StoredWidgetPlacement>) => {
    return Object.entries(allWidgetsOfDFPHomePage)
      .filter(([id]) => !layout.find((position) => position.id === id))
      .map(([id, widget]) => ({ id, ...widget }));
  };

  const getWidgetActions = (item: BoardProps.Item<WidgetDataType>) => {
    const isWelcomeWidget = item.id === 'welcomeWidget';
    const canEditWidget = isWelcomeWidget && hasAdminPrivileges;

    if (!canEditWidget) {
      return [];
    }

    return [
      {
        id: 'dfs_home_welcome_widget_quick_edit',
        placeOfOrigin: 'dfs_home',
        text: 'Quick Edit',
        onClick: () => setShowWelcomeContentModal(true)
      },
      {
        id: 'dfs_home_welcome_widget_edit',
        placeOfOrigin: 'dfs_home',
        text: 'Edit in console',
        onClick: () => navigate(`/admin/manage-homepage`)
      }
    ];
  };

  return (
    <>
      <AppLayout
        ref={appLayoutRef}
        headerSelector="#h"
        contentType="dashboard"
        ariaLabels={appLayoutAriaLabels}
        breadcrumbs={<DFPBreadcrumbs items={[]} />}
        tools={
          <InfoPanelDetailsHelpPanel
            headerName="Welcome"
            sidePanelDetails={appContext?.homePageContent.sidePanelDetails}
            appDetailedDescription={appContext?.homePageContent?.fullApplicationDescription || ''}
            applicationIntroductionVideoUrl={appContext?.homePageContent?.applicationIntroductionVideoUrl}
          />
        }
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        navigation={<DFPHomePageSideNavigation />}
        stickyNotifications
        notifications={<Flashbar items={flashbarItems} />}
        splitPanel={
          <SplitPanel header="Add widgets" closeBehavior="hide" hidePreferencesButton={true} i18nStrings={splitPanelI18nStrings}>
            <Palette items={getDFPPaletteWidgets(layout)} />
          </SplitPanel>
        }
        splitPanelPreferences={{ position: 'side' }}
        onSplitPanelPreferencesChange={() => {}}
        splitPanelOpen={splitPanelOpen}
        onSplitPanelToggle={({ detail }) => setSplitPanelOpen(detail.open)}
        splitPanelSize={splitPanelSize}
        onSplitPanelResize={(event) => setSplitPanelSize(Math.min(event.detail.size, splitPanelMaxSize))}
        maxContentWidth={Number.MAX_VALUE}
        content={
          <div className="app-layout-body">
            <ContentLayout
              header={
                <>
                  <Header
                    actions={
                      <SpaceBetween size="l" direction="horizontal">
                        <ResetButton onReset={resetLayout}>Reset to default layout</ResetButton>
                      </SpaceBetween>
                    }
                  >
                    <Box variant="h1">{'DaS FinSuite'}</Box>
                  </Header>
                </>
              }
            >
              <div ref={ref}>
                <Board
                  empty={
                    <EmptyState
                      title="No home page contents"
                      description="There are no widgets on this page."
                      verticalCenter={true}
                      action={
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button onClick={resetLayout}>Reset to default layout</Button>
                          <Button iconName="add-plus" onClick={() => setSplitPanelOpen(true)}>
                            Add widget
                          </Button>
                        </SpaceBetween>
                      }
                    />
                  }
                  i18nStrings={boardI18nStrings}
                  items={getDFPBoardWidgets(layout)}
                  onItemsChange={({ detail: { items } }) => {
                    setLayout(exportLayout(items));
                  }}
                  renderItem={(item, actions) => {
                    const Wrapper = item.data.provider ?? React.Fragment;
                    return (
                      <Wrapper>
                        <ConfigurableWidget config={item.data} onRemove={actions.removeItem} actions={getWidgetActions(item)} />
                      </Wrapper>
                    );
                  }}
                />
              </div>
            </ContentLayout>
            <WelcomeContentModal
              showWelcomeContentModal={showWelcomeContentModal}
              onCancel={() => setShowWelcomeContentModal(false)}
              onConfirm={() => setShowWelcomeContentModal(false)}
            />
          </div>
        }
      />
    </>
  );
};
