import { AppLayout, Box, Button, Container, ContentLayout, Flashbar, FlashbarProps, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appLayoutAriaLabels } from 'src/i18n-strings';
import { DFPBreadcrumbs } from '../../generic-components/DFPBreadcrumb';
import { FeedbackContainer } from '../widgets/feedback-widget/FeedbackWidget';
import { CardConfiguration, adminCards } from './AdminHomePageConfig';
import { AdminSideNavigation } from './AdminSideNavigation';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { AppNavigationState } from 'src/components/context/AppContextModels';

const AdminConsoleHeader = () => (
  <Header
    description="The Admin console allows users with admin permissions to create and maintain info presented for each app in DaS FinSuite"
    variant="h1"
  >
    {'Admin console'}
  </Header>
);

const AdminConsoleContainer = ({ container, onNavigate }: { container: CardConfiguration; onNavigate: (path: string) => void }) => (
  <Container
    className="admin-console-container-item"
    header={
      <Header
        variant="h3"
        actions={
          <SpaceBetween size="m" direction="horizontal">
            {container.Actions.map((action, index) => (
              <Button key={index} onClick={() => onNavigate(action.path)} iconName={action.icon}>
                {action.buttonText}
              </Button>
            ))}
          </SpaceBetween>
        }
      >
        {container.Header}
      </Header>
    }
  >
    <Box variant="p" margin={'xs'}>
      {container.Content}
    </Box>
  </Container>
);

const FeedbackSection = ({ displayFlashMessage }: { displayFlashMessage: (header: string, content: string, type: FlashbarProps.Type) => void }) => (
  <Box margin={{ top: 'l' }}>
    <Grid>
      <Container
        className="admin-console-feedback-container"
        header={
          <Header variant="h3" description="Help us improve DaS FinSuite by rating your experience and sending your comments/request!">
            Feedback
          </Header>
        }
      >
        <FeedbackContainer id={''} appName={''} displayFlashMessage={displayFlashMessage} feedbackType={'Admin Console'} />
      </Container>
    </Grid>
  </Box>
);

export const AdminHomePage: React.FC = () => {
  const userAuthData = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationStateMessage = location.state as AppNavigationState;

  // Add useEffect to handle navigation state message and clear state after displaying
  useEffect(() => {
    if (navigationStateMessage?.message) {
      displayFlashMessage(navigationStateMessage.message.header, navigationStateMessage.message.content, navigationStateMessage.message.type);
      // Clear the state after displaying the message
      window.history.replaceState({}, document.title);
    }
  }, [navigationStateMessage]);

  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const displayFlashMessage = (header: string, content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        header: header,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  return (
    <AppLayout
      headerSelector="#h"
      contentType="cards"
      ariaLabels={appLayoutAriaLabels}
      toolsHide={true}
      navigation={<AdminSideNavigation />}
      stickyNotifications
      breadcrumbs={<DFPBreadcrumbs items={[]} />}
      notifications={<Flashbar items={flashbarItems} />}
      content={
        <div className="app-layout-body">
          <ContentLayout header={<AdminConsoleHeader />}>
            <Grid>
              {adminCards.map((container, index) => (
                <AdminConsoleContainer key={index} container={container} onNavigate={navigate} />
              ))}
            </Grid>
          </ContentLayout>
          <FeedbackSection displayFlashMessage={displayFlashMessage} />
        </div>
      }
    />
  );
};
