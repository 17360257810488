import { AppLayout, BreadcrumbGroupProps, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { appLayoutAriaLabels } from 'src/i18n-strings';
import { AdminSideNavigation } from '../AdminSideNavigation';
import { AnnouncementTools } from './AdminAnnouncementTools';
import { AdminAnnouncementTable } from './AdminAnnouncementTable';
import { getBreadcrumbItems } from './AdminAnnouncementsConfig';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { fetchAnnouncements } from 'src/api/app-sync-services';

export type AnnouncementsContextType = {
  refreshAnnouncements: () => void;
  displayFlashMessage: (content: string, flashBarType: FlashbarProps.Type) => void;
  announcementStatus: boolean;
  setAnnouncementStatus: (feedbacksStatus: boolean) => void;
};

export const AnnouncementsContext = createContext<AnnouncementsContextType>({
  refreshAnnouncements: () => {},
  displayFlashMessage: () => {},
  announcementStatus: false,
  setAnnouncementStatus: (announcementStatus: boolean) => {}
});
export const useAnnouncements = () => useContext(AnnouncementsContext);

export const AdminAnnouncements: React.FC = () => {
  const appLayout = useRef<any>();
  const { id: announcementId } = useParams();
  const { listOfApplications, setAnnouncements, setAnnouncementsFlatEntity } = useAppContext();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [announcementStatus, setAnnouncementStatus] = useState(false);

  useEffect(() => {
    refreshAnnouncements();
  }, []);

  const refreshAnnouncements = async () => {
    setAnnouncementStatus(true);
    const announcementsResponse = await fetchAnnouncements(listOfApplications);
    setAnnouncements(announcementsResponse.announcementList);
    setAnnouncementsFlatEntity(announcementsResponse.announcementsFlatTableEntity);
    setAnnouncementStatus(false);
  };

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  return (
    <>
      <AppLayout
        ref={appLayout}
        headerSelector="#h"
        ariaLabels={appLayoutAriaLabels}
        tools={<AnnouncementTools />}
        breadcrumbs={<DFPBreadcrumbs items={getBreadcrumbItems(announcementId)} />}
        toolsHide={true}
        navigation={<AdminSideNavigation />}
        stickyNotifications
        notifications={<Flashbar items={flashbarItems} />}
        contentType="table"
        content={
          <div className="app-layout-body">
            <AnnouncementsContext.Provider
              value={{
                announcementStatus,
                setAnnouncementStatus,
                refreshAnnouncements,
                displayFlashMessage
              }}
            >
              {!announcementId && <AdminAnnouncementTable />}
              {announcementId && <Outlet />}
            </AnnouncementsContext.Provider>
          </div>
        }
      />
    </>
  );
};
