import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

const EmptyBoard: React.FC = () => {
  return (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
      <SpaceBetween size="m">
        <Box variant="strong" color="inherit">
          No items
        </Box>
        <Button iconName="add-plus">Add an item</Button>
      </SpaceBetween>
    </Box>
  );
};

export default EmptyBoard;
