import { API } from 'aws-amplify';
import moment from 'moment-timezone';
import { logApiEvent } from 'src/logger';
import { getTimeDifference } from 'src/utilities/DateTimeUtilities';

// Utility to format error messages
export const formatApiErrors = (errors: any[]): string => {
  return 'API responded with errors: ' + errors.map((error) => error.message).join(', ');
};

interface GraphQLRequestOptions {
  query: any;
  variables: any;
  requestName: string;
  requestType: 'query' | 'mutation';
}

export const fetchGraphQLRequest = async <T>({ query, variables, requestName, requestType }: GraphQLRequestOptions): Promise<T> => {
  const initiateTimer = moment();
  let response: any;
  let elapsed: number;

  try {
    response = await API.graphql({
      query: query,
      variables: variables
    });
    elapsed = getTimeDifference(initiateTimer);

    if (response.errors && response.errors.length > 0) {
      const errorMessage = formatApiErrors(response.errors);
      logApiEvent(
        {
          requestType: requestType,
          requestName: requestName,
          requestStatus: 'API_FAILED',
          responseTime: elapsed
        },
        response.errors
      );
      throw new Error(errorMessage);
    }

    logApiEvent({
      requestType: requestType,
      requestName: requestName,
      requestStatus: 'API_SUCCESS',
      responseTime: elapsed
    });

    return response.data;
  } catch (error: any) {
    elapsed = getTimeDifference(initiateTimer);
    const errorMessage = error.errors ? formatApiErrors(error.errors) : 'API Request Failed: Unexpected error occurred';
    logApiEvent(
      {
        requestType: requestType,
        requestName: requestName,
        requestStatus: 'API_FAILED',
        responseTime: elapsed
      },
      { errorMessage }
    );

    throw new Error(errorMessage);
  }
};
