import { EnvironmentConfig, S3Config } from 'src/components/context/AppContextModels';

// Environment variables
export const ENVIRONMENT_VARIABLES = Object.freeze<{ env: EnvironmentConfig }>({
  env: {
    Region: 'us-west-2',
    Stage: 'alpha',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'd2h7ruy4u8cty0.cloudfront.net',
    CognitoUserPoolId: 'us-west-2_D6VvUWsrZ',
    CognitoIdentityPoolId: 'us-west-2:f6037eb4-644d-429c-ad8f-f440416a0f6c',
    AuthenticationType: 'API_KEY',
    CognitoUserPoolWebClientId: '5sng7dqb0j8bpojejroh1aovjh',
    CognitoDomain: 'das-finsuite-application-alpha-v1.auth.us-west-2.amazoncognito.com',
    ApplicationDomain: 'https://alpha.fintech.device.finance.amazon.dev',
    AwsAccountId: '850882930567'
  }
});

// S3 configuration constants
export const S3_ENV_CONSTANTS = Object.freeze<{ ENVIRONMENT_VARIABLES: S3Config }>({
  ENVIRONMENT_VARIABLES: {
    Bucket: ENVIRONMENT_VARIABLES.env.Stage === 'dev' ? 'op-excellence-alpha' : `op-excellence-${ENVIRONMENT_VARIABLES.env.Stage}`,
    Prefix: 'oe-rpt-tables-data-export/'
  }
});
