import { Box, Header } from '@amzn/awsui-components-react';
import React from 'react';
import { WelcomeContentDetailsEntity } from '../../admin-components/AdminModels';
import { WidgetConfig } from '../interface';
import { WelcomeWidgetContent } from './WelcomeWidgetContent';

const DefaultWelcomeWidgetDefinition = { defaultRowSpan: 4, defaultColumnSpan: 2 };

interface WelcomeWidgetProps {
  welcomeTitle: string;
  description: string;
  welcomeContentDetails: WelcomeContentDetailsEntity[];
}

interface WelcomeHeaderProps {
  title: string;
  description: string;
}

const WelcomeHeader: React.FC<WelcomeHeaderProps> = ({ title, description }) => {
  return (
    <Header description={description}>
      <Box variant="h2">{title}</Box>
    </Header>
  );
};

export const WelcomeWidget = ({ welcomeTitle, description, welcomeContentDetails }: WelcomeWidgetProps): WidgetConfig => {
  return {
    definition: DefaultWelcomeWidgetDefinition,
    data: {
      icon: 'list',
      title: welcomeTitle,
      description: description,
      header: <WelcomeHeader title={welcomeTitle} description={description} />,
      content: <WelcomeWidgetContent welcomeContentDetails={welcomeContentDetails} />
    }
  };
};
