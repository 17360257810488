import { Icon } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import TutorialVideoModal from '../das-finsuite/widgets/tutorials-widget/TutorialVideoModal';
import { logger } from 'src/logger';
import { UserActionsEventTypes } from '../das-finsuite/das-finsuite-constants';

interface VideoThumbnailProps {
  link: string | null | undefined;
  displayTitle: string | null;
  category: string | null;
  showPlayIcon?: boolean;
}

export const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ link, displayTitle, category, showPlayIcon = false }) => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);

  if (!link) return null;

  let videoURL: string = link;

  if (videoURL.includes('broadcast.amazon.com/videos/')) {
    videoURL = videoURL.replace('/videos/', '/embed/');
  }

  const onThumbnailClick = () => {
    logger.info(`DaS FinSuite -> User Action: Tutorials -> Video Click`, {
      event_type: UserActionsEventTypes.TUTORIALS_VIDEO_CLICKED,
      value: displayTitle
    });
    setShowTutorialModal(true);
  };

  const onCancelVideo = () => {
    setShowTutorialModal(false);
  };

  return (
    <>
      <div onClick={onThumbnailClick} style={{ cursor: 'pointer', position: 'relative' }}>
        <iframe src={videoURL} width="100%" height="150" title="Application Introduction Video" style={{ pointerEvents: 'none' }} />
        {showPlayIcon && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.5)'
            }}
          >
            <Icon name="play" size="large" variant="inverted" />
          </div>
        )}
      </div>
      <TutorialVideoModal
        showTutorialModal={showTutorialModal}
        onCancel={onCancelVideo}
        onConfirm={() => setShowTutorialModal(false)}
        link={link || ''}
        displayTitle={displayTitle}
        category={category}
      />
    </>
  );
};
